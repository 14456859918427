import React, { useState } from 'react';
import { List, ListItem, ListItemText, Collapse, useMediaQuery, useTheme, Grid } from '@mui/material';
import animation from './Animation.json';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Treeview = () => {
  const [childSearchText, setChildSearchText] = useState('');
  const [expandedParent, setExpandedParent] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let currentColor = '#fdfdfc';

  const getParentTagNames = () => {
    return Object.keys(animation).map((key) => animation[key].tag_name);
  };

  const getChildTagsByParent = (parentTag) => {
    const parentEntry = animation[parentTag];
    return parentEntry && parentEntry.children ? Object.values(parentEntry.children) : [];
  };

  const handleParentClick = (parentTag) => {
    if (expandedParent === parentTag) {
      setExpandedParent(null);
    } else {
      setExpandedParent(parentTag);
    }
    setChildSearchText('');
  };

  const handleParentHover = (parentTag) => {
    setHoveredItem(parentTag);
  };

  const handleParentLeave = () => {
    setHoveredItem(null);
  };

  const renderChildTags = () => {
    if (!expandedParent) {
      return null;
    }
    const childTags = getChildTagsByParent(expandedParent);
    const filteredChildTags = childTags.filter((childTag) =>
      childTag.tag_name.toLowerCase().includes(childSearchText.toLowerCase())
    );

    return (
      <Collapse in={true}>
        <List>
          {filteredChildTags.map((childTag) => {
            currentColor = currentColor === '#fdfdfc' ? '#f5f5f4' : '#fdfdfc';
            return (
              <ListItem
                key={childTag.part_id}
                sx={{
                  paddingLeft: '5%',
                  cursor: 'pointer',
                  backgroundColor: currentColor,
                  display: 'flex',
                  alignItems: 'center',
                }}
                onMouseEnter={() => handleParentHover(childTag.part_id)}
                onMouseLeave={handleParentLeave}
              >
                <ListItemText primary={childTag.tag_name} />
                {hoveredItem === childTag.part_id && (
                  <div className="child-icons" style={{ marginLeft: 'auto' }}>
                    <PlayArrowIcon />
                    <svg
                        xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 448 512">
                        <path
                          d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z"
                        />
                      </svg>
                    <FileCopyIcon />
                  </div>
                )}
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    );
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <Grid container className="Grid-container">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <List sx={{ marginTop: '20px' }}>
            {Object.keys(animation).map((tag, index) => {
              currentColor = currentColor === '#fdfdfc' ? '#f5f5f4' : '#fdfdfc';
              return (
                <div key={tag}>
                  <ListItem
                    button
                    onClick={() => handleParentClick(tag)}
                    onMouseEnter={() => handleParentHover(tag)}
                    onMouseLeave={handleParentLeave}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: currentColor,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowRightIcon
                      sx={{
                        color: 'red',
                        transform: `rotate(${expandedParent === tag ? 90 : 0}deg)`,
                      }}
                    />
                    <ListItemText primary={animation[tag].tag_name} />
                    {hoveredItem === tag && (
                      <div className="child-icons" style={{ marginLeft: '40%' }}>
                        <PlayArrowIcon />
                        <svg
                        xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 448 512">
                        <path
                          d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z"
                        />
                      </svg>
                        <FileCopyIcon />
                      </div>
                    )}
                  </ListItem>
                  {expandedParent === tag && renderChildTags()}
                </div>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default Treeview;
