import React, { useState} from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PublicIcon from '@mui/icons-material/Public';
import Typography from '@mui/material/Typography';
import ModalLanguage from '../pages/Home/Language'

const CustomListItem = () => {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);

  //Change Language Modal
  const handleLanguageOpenModal = () => {
    setLanguageModalOpen(true);
  };

  const handleLanguageCloseModal = () => {
    setLanguageModalOpen(false);
  };
  return (
    <>
      <div>
        <ListItem onClick={handleLanguageOpenModal} button sx={{
            '&:hover': {
              '& .MuiSvgIcon-root': {
                color: '#cc0033', // Change the icon color on hover
              },
              '& .footerLanguage': {
                color: '#cc0033', // Change the text color on hover
              },
            },
          }}>
          <ListItemIcon sx={{
          minWidth:'25px',
          '&.MuiTabs-scrollButtons': { padding: '10px'}}}>
            <PublicIcon />
          </ListItemIcon>
          <ListItemText primary={
            <Typography className='footerLanguage' variant="body2" sx={{ fontSize: '0.875rem', '&:hover': {color: '#cc0033'} }}>
            US English
          </Typography>
          } />
        </ListItem>
      </div>

      {/* for Language Modal */}  
      <ModalLanguage
        open={isLanguageModalOpen}
        onClose={handleLanguageCloseModal}
        title="Change Language"
        content="This is the content of the Modal."
      />
  </>
  );
};

export default CustomListItem;
