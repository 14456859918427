import React from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import Countrycodeintl from '../../Components/Countrycodeintl';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../Components/Button';

const useStyles = {
  dialogTitle: {
    background: 'rgb(228, 228, 228)',
    color: '#000',
    borderRadius:'4px 4px 0px 0px'
  }
};

const OneTimePin = ({ open, onClose, title, onConfirm }) => {

  const handleConfirm = () => {
    onClose();
  };


  return (
    <Dialog open={open} onClose={onClose} fullWidth className='OneTimePinDialog'>
      <DialogTitle className="custom-dialog-title" sx={useStyles.dialogTitle}>
        <b>{title}</b>
        <IconButton><CloseIcon onClick={onClose} sx={useStyles.closeButton} /></IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
        <Grid container spacing={2} className='OneTimePinGrid'>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12}>
              <Countrycodeintl label="Enter" name='mobileNumber' required sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }} />
            </Grid>
        </Grid>
        </form>
      </DialogContent>
      <DialogActions className='custom-dialog-footer'>
          <CustomButton label="Submit" onClick={handleConfirm} />
          <CustomButton variant="outlined" label="Cancel" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default OneTimePin;
