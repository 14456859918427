import React, { useState, lazy, Suspense, useEffect } from 'react';
import { Container, Box, Grid,  Typography,  Tabs, Tab, useMediaQuery} from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import BrakeSystem from '../../Assets/Images/Brakesystem_new.png';
import view_all from '../../Assets/Images/default/all.png';
import view_left from '../../Assets/Images/default/Left.png';
import Spinner from '../../Assets/Images/default/Spinners.gif';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {theme} from '../../Components/themeforview';

import Treeview from './TabComponents/Treeview';
import Favorite from './TabComponents/Favourite';
import History from './TabComponents/History';
import NewAnimation from './TabComponents/NewAnimation';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HistoryIcon from '@mui/icons-material/History';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import Tooltip from '@mui/material/Tooltip';
import ListIcon from '@mui/icons-material/List';
import '../RTL.css';
const LazyCarousel = lazy(() => import('./TabComponents/Carousel'));

const Dashboard = () => {

  const [carouselLoaded, setCarouselLoaded] = useState(false);
  const [loading, setLoading] = useState(false);  

    useEffect(() => {
        // Preload the Carousel component
        import('./TabComponents/Carousel').then(() => {
            setCarouselLoaded(true);
        });
    }, []);

  const [isChecked, setIsChecked] = useState(false);

    const customSwitchStyle = {
      backgroundColor: isChecked ? '#1976d2' : '#ce0034',
      borderColor: isChecked ? '#1976d2' : '#ce0034',
    };

    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const label = { inputProps: { 'aria-label': 'Switch demo' } };


    let listContent;
    if(loading) {
        listContent = (
            <div className="list-msg">
              <div>
                <img src={Spinner} alt="Loading" className="spinner" />
              </div>
            </div>
          );
    }else{
        listContent = false;
    }
    
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelowTablet = useMediaQuery(theme.breakpoints.between("sm","tb"));
  const isTablet = useMediaQuery(theme.breakpoints.between("tb","md"));
  const isAboveTablet = useMediaQuery(theme.breakpoints.between("md","lg"));
  const notSmallScreen = useMediaQuery(theme.breakpoints.up("md"));


  



  const customPaging = (i) => (
    <a>
        {i === 0 && <img src={BrakeSystem} alt="image1" title="Left View" />}
        {i === 1 && <img src={BrakeSystem} alt="image2" title="Top View" />}
        {i === 2 && <img src={BrakeSystem} alt="image3" title="Front View" />}
    </a>
  );
  
  const arrowStyles = {
    color: 'black',
    fontSize: '30px',
    
};

var settings = {
    infinite: false,
    loop: false,
    speed: 600,
    slidesToShow: isMobile ? 2 : isTablet ? 4 : isAboveTablet ? 5 : isBelowTablet ? 3 : 7,
    slidesToScroll: isMobile ? 2 : isTablet ? 2 : isAboveTablet ? 2 : isBelowTablet ? 2 :7,
    prevArrow: <ChevronLeftIcon sx={{ borderRadius:'50%'}} style={arrowStyles} />,
    nextArrow: <ChevronRightIcon sx={{ borderRadius:'50%'}} style={arrowStyles} />,
    dots: true,
};



const [anchorEl, setAnchorEl] = useState(null);

const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
};

const handlePopoverClose = () => {
    setAnchorEl(null);
};

const open = Boolean(anchorEl);

    return (
        <main className='mainContent'>
        <Container sx={{ maxWidth:'100vw', width:'100%!important', paddingLeft:isAboveTablet ? '10%!important':'5%'}}>
        <Grid container sx={{maxWidth:'100vw!important',justifyContent :'right',  zIndex:'4',position:isMobile? 'fixed':'sticky', right:isMobile ? '0px!important' : '0px', top:'51px', }} >
            <Tabs className='tabs' sx={{ maxWidth:'100vw!important',backgroundColor: 'white', marginRight: notSmallScreen ? '50px': '0px' }} value={selectedTab} onChange={handleTabChange} >
                <Tab className= {isMobile ? 'tabicons' : ''}
                    icon={<img className='viewTab' style={{ fontSize:'5px', backgroundColor: selectedTab === 0 ? '#ce0034' : 'rgb(113, 107, 107)' }} src={view_all} alt="View All" />}/>
                <Tab className= {isMobile ? 'tabicons' : ''}
                    icon={<img className='viewTab' style={{ fontSize:'55px',backgroundColor: selectedTab === 1 ? '#ce0034' : 'rgb(113, 107, 107)' }} src={view_left} alt="View Left" />}/>
                <Tab className= {isMobile ? 'tabicons' : ''}
                    icon={<Tooltip title="Tree View" arrow><ListIcon style={{ fontSize:'45px', color: selectedTab === 2 ? '#ce0034' : 'rgb(113, 107, 107)' }} alt="Tree View" />
                   </Tooltip>}/>
                <Tab className= {isMobile ? 'tabicons' : ''} icon={<Tooltip title="Favourite" arrow>
                    <FavoriteIcon style={{ fontSize: '35px',color: selectedTab === 3 ? '#ce0034' : 'rgb(113, 107, 107)'}}  alt="Tree View"/>
                   </Tooltip>}/>
                 <Tab className= {isMobile ? 'tabicons' : ''} icon={<Tooltip title="History" arrow>
                      <HistoryIcon style={{ fontSize:'35px', color: selectedTab === 4 ? '#ce0034' : 'rgb(113, 107, 107)'}} alt="Tree View" />
                    </Tooltip>}/>
                 <Tab className= {isMobile ? 'tabicons' : ''} icon={<Tooltip title="New" arrow>
                      <VideoCallIcon style={{ fontSize:'35px', color: selectedTab === 5 ? '#ce0034' : 'rgb(113, 107, 107)'}} alt="Tree View" />
                    </Tooltip>}/>
            </Tabs>
        </Grid>
        <Grid justifyContent="center" sx={{marginTop:'50px'}}>
            <Box sx={{ mt: 1, justifyContent:'center', fontWeight: 'bold' }}>
              {selectedTab === 0 && <Typography variant="h5" gutterBottom>
            <Grid justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{maxWidth:'90%!important',marginLeft:'5%!important', marginTop:'20px!important'}}>
                <Grid container justifyContent={!notSmallScreen ? "center":"start"}>
                    <Box sx={{ mt: 1,fontWeight: 'bold' }}>
                        <Typography variant="h5" gutterBottom>UNDER CAR</Typography>
                    </Box>
                </Grid>
                <Slider {...settings} >
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px', alignItems:'center!important'}}>  
                        <img src='https://cdn.vehiclevisuals.com/superSystemIcons/Brakesystem_new.png' alt="BrakeSystem" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/Steering_RHD_new.png' alt="Steering System" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>

                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/Suspension_new.png' alt="Suspension System" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/PressureRelatedTyreWear_new.png' alt="Tire" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/exhaust_new.png' alt="Exhaust System" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='	https://cdn.vehiclevisuals.com/superSystemIcons/Driveline_new.png' alt="Driveline" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/Fuel-Maintenance_new.png' alt="FluidMaintenance" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                </Slider>
              </Grid>
              <Grid justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{maxWidth:'90%!important',marginLeft:'5%!important', marginTop:'20px!important'}}>
                <Grid container justifyContent={!notSmallScreen ? "center":"start"}>
                    <Box sx={{ mt: 1,fontWeight: 'bold' }}>
                        <Typography variant="h5" gutterBottom>ENGINE</Typography>
                    </Box>
                </Grid>
                <Slider {...settings} >
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                      <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                      <img src='	https://cdn.vehiclevisuals.com/superSystemIcons/Engine_new.png' alt="Engine" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/Engine-Management_new.png' alt="Engine Management" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>

                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='	https://cdn.vehiclevisuals.com/superSystemIcons/cooling_system_new.png' alt="Cooling System" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/Ignition_System_new.png' alt="Ignition System" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/DieselInjection_new.png' alt="Fuel System" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='	https://cdn.vehiclevisuals.com/superSystemIcons/diesel_injection_new.png' alt="Diesel System" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/ScheduledMaintenance_new.png' alt="Scheduled Maintenance" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                </Slider>
              </Grid>
              <Grid justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{maxWidth:'90%!important',marginLeft:'5%!important', marginTop:'20px!important'}}>
                <Grid container justifyContent={!notSmallScreen ? "center":"start"}>
                    <Box sx={{ mt: 1,fontWeight: 'bold' }}>
                        <Typography variant="h5" gutterBottom>OTHER</Typography>
                    </Box>
                </Grid>
                <Slider {...settings} >
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/hvac_new.png' alt="HVAC Maintenance" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/electrical_new.png' alt="Electical Maintenance" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>

                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/HybridEV_new.png' alt="Hybrid EV" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/auto-trans_new.png' alt="Auto Transmission" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/check_138_new.png' alt="Car Checks" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/Body_RHD_new.png' alt="Car Body" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                    <div>
                    <Box className='partIcons' sx={{ display:'flex',height:'170px', paddingLeft:isMobile || isTablet ? '':'10px!important', paddingRight:isMobile || isTablet ? '':'10px!important', width:'100%', overflow:isMobile || isTablet ? 'hidden' : '', justifyContent:'center'}}>

                    <div style={{width:isMobile || isTablet ? '135px': isAboveTablet ? '140px' : '150px', height:isMobile || isTablet ? '135px':isAboveTablet ? '140px' :'150px',}}>  
                    <img src='https://cdn.vehiclevisuals.com/superSystemIcons/ADAS_final_tran_new.png' alt="ADASSystem" style={{ width: isMobile || isTablet ? '125px':'130px', height:isMobile || isTablet ? '125px':'130px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b'}} />
                      </div>
                    </Box>

                    </div>
                </Slider>
              </Grid>
              
            {/* {selectedTab === 0 && <Typography variant="h5" gutterBottom>
                <Grid container justifyContent="start">
                    <Box sx={{ mt: 1,fontWeight: 'bold' }}>
                        <Typography variant="h5" gutterBottom>UNDER CAR</Typography>
                    </Box>
                </Grid>
                <Grid>
                    <Box className='partIcons' sx={{ mt: 5 ,display:'flex', paddingLeft:isMobile ? '5vw' : '0px',paddingBottom:'15px', height:'160px', width:isMobile ? '90%':'100%', overflow:isMobile ? 'hidden' : ''}}>
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div>
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div>
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div>   
                    </Box>
                </Grid>
                <Grid container justifyContent="start">
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="h5" gutterBottom>ENGINE</Typography>
                    </Box>
                </Grid>

                <Grid>
                    <Box className='partIcons' sx={{ mt: 5 ,display:'flex', paddingLeft:isMobile ? '5vw' : '0px',paddingBottom:'15px', height:'160px', width:isMobile ? '90%':'100%', overflow:isMobile ? 'hidden' : ''}}>
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div>
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div>
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div>   
                    </Box>
                </Grid>
                <Grid container justifyContent="start">
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="h5" gutterBottom>OTHERS</Typography>
                    </Box>
                </Grid>
                <Grid>
                    <Box className='partIcons' sx={{ mt: 5 ,display:'flex', paddingLeft:isMobile ? '5vw' : '0px',paddingBottom:'15px', height:'160px', width:isMobile ? '90%':'100%', overflow:isMobile ? 'hidden' : ''}}>
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div>
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div>
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div> 
                      <div style={{width:'170px', height:'160px'}}>  
                        <img src={BrakeSystem} alt="BrakeSystem" style={{ width: '140px', height:'140px', maxHeight:'140px', maxWidth: '140px', backgroundColor: '#716b6b',marginRight: '50px' }} />
                      </div>   
                    </Box>
                </Grid>
            </Typography>}
            {selectedTab === 1 && <Typography variant="h5" gutterBottom>
                      <Suspense fallback={loading ? <div>Loading...</div> : null}>
                          {loading ? listContent : <LazyCarousel />}
                      </Suspense>
                  </Typography>}
            {selectedTab === 2 && <Typography variant="h5" gutterBottom><Treeview/></Typography>}
            {selectedTab === 3 && <Typography variant="h5" gutterBottom><Favorite/></Typography>}
            {selectedTab === 4 && <Typography variant="h5" gutterBottom><History/></Typography>}
            {selectedTab === 5 && <Typography variant="h5" gutterBottom><NewAnimation/></Typography>}
            {<Myvideo/>}  */}
            </Typography>}
            {selectedTab === 1 && <Typography variant="h5" gutterBottom>
                      <Suspense fallback={loading ? <div>Loading...</div> : null}>
                          {loading ? listContent : <LazyCarousel />}
                      </Suspense>
                  </Typography>}
            {selectedTab === 2 && <Typography variant="h5" gutterBottom><Treeview/></Typography>}
            {selectedTab === 3 && <Typography variant="h5" gutterBottom><Favorite/></Typography>}
            {selectedTab === 4 && <Typography variant="h5" gutterBottom><History/></Typography>}
            {selectedTab === 5 && <Typography variant="h5" gutterBottom><NewAnimation/></Typography>}
            </Box>
        </Grid>
        </Container>
        </main>
    );
}; 

export default Dashboard;
