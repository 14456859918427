import React, { useState, useEffect } from 'react';
import { AppBar, Grid, Typography, Tabs, Tab, Box, Container, useMediaQuery, Modal, Drawer, MenuItem, createTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Dialog, DialogTitle, DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import './Header.css';
import MenuIcon from '@mui/icons-material/Menu';
import { InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AccountCircle } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ModalSubscribe from '../../pages/Home/Subscribe';
import ModalSignUpChoose from '../../pages/Home/SignupChoose';
import login_logo from '../../Assets/Images/logo_home.png';
import './Header.css';
import CustomButton from '../../Components/Button';
import '../RTL.css';

const styles = {
  headerIconAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerIconAlignMob: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}


const Header = () => {

  const navigate = useNavigate();

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024, // Increase the value for tablet width
        lg: 1280,
        xl: 1920,
        lg2: 1620,
      },
    },
  });



  const countries = [
    { code: 'US', name: 'United States', flag: 'us.png', countryCode: '+1' },
    { code: 'CA', name: 'Canada', flag: 'ca.png', countryCode: '+1' },
    { code: 'UK', name: 'United Kingdom', flag: 'uk.png', countryCode: '+44' },
    // Add more countries as needed
  ];
  const [countryCode, setCountryCode] = React.useState(countries[0].code);
  const [mobileNumber, setMobileNumber] = React.useState('');


  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeModal, setActiveModal] = useState(null);
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isbetweenThanmdlg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isbetweenThanmdlg2 = useMediaQuery(theme.breakpoints.between('lg', 'lg2'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    parseInt(localStorage.getItem('selectedTab')) || 0);
  const [isFreeTrailOpen, setFreeTrailModalOpen] = useState(false);
  const [isFreeTrailOpenPopup, setFreeTrailModalOpenpopup] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  //subscribe
  const [isSubscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const [isSignUpChooseModalOpen, setSignUpChooseModalOpen] = useState(false);

  //for previous selected tab
  const [prevSelectedTab, setPrevSelectedTab] = useState(0);
  const [mainModalSelectedTab, setMainModalSelectedTab] = useState(0);
  const [isRtl, setIsRtl] = useState(false);


  const handleLoginModalOpen = () => {
    setPrevSelectedTab(selectedTab); // Store the current selected tab
    setActiveModal("login");
    setLoginModalOpen(true);
    setForgotPasswordModalOpen(false);
    setMainModalSelectedTab(selectedTab); // Store the selected tab for the main modal
  };

  const handleLoginModalClose = () => {
    setActiveModal(null);
    setLoginModalOpen(false);
    setForgotPasswordModalOpen(false);
    setSelectedTab(prevSelectedTab); // Restore the previously selected tab
  };

  const handleCreateAccountModalOpen = () => {
    setActiveModal("createAccount");
    setCreateAccountModalOpen(true);
    setLoginModalOpen(false);
    setPrevSelectedTab(mainModalSelectedTab);
  };

  const handleCreateAccountModalClose = () => {
    setActiveModal(null);
    setCreateAccountModalOpen(false);
    setLoginModalOpen(false);
    setSelectedTab(prevSelectedTab);
  };

  const handleForgotPasswordModalOpen = () => {
    setActiveModal("forgotPassword");
    setForgotPasswordModalOpen(true);
    setLoginModalOpen(false);
    setPrevSelectedTab(mainModalSelectedTab); // Store the selected tab of the main modal before opening the "Forgot Password" modal
  };

  const handleForgotPasswordModalClose = () => {
    setActiveModal("login");
    setForgotPasswordModalOpen(false);
    handleCreateAccountModalClose(false);
    setSelectedTab(prevSelectedTab);

  };

  const [isCreateAccountLinkClicked, setCreateAccountLinkClicked] = useState(false);

  const handleCreateAccountLinkClick = () => {
    setCreateAccountLinkClicked(true);
    handleCreateAccountModalOpen();
  };

  // Durai Bro work

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabsStyles = {
    padding: isbetweenThanmdlg ? '10px 30px' : isbetweenThanmdlg2 ? '10px 50px' : '10px 80px',

  };
  // For Free trail

  const handleFreeTrailModalOpen = () => {

    setActiveModal("freeTrail");
    setFreeTrailModalOpen(true);
    setLoginModalOpen(false);
    setForgotPasswordModalOpen(false);
    handleCreateAccountModalClose(false);
    setPrevSelectedTab(selectedTab); // Store the current selected tab
  };


  const handleFreeTrailModal = () => {

    setActiveModal("freeTrail");
    setFreeTrailModalOpen(true);
    setLoginModalOpen(false);
    setForgotPasswordModalOpen(false);
    handleCreateAccountModalClose(false);
    setPrevSelectedTab(mainModalSelectedTab); // Store the selected tab of the main modal before opening the "Forgot Password" modal
  };

  const handleFreeTrailModalClose = () => {
    setActiveModal("freeTrail");
    setFreeTrailModalOpen(false);
    handleCreateAccountModalClose(false);
    setSelectedTab(prevSelectedTab);
  };

  const navigatetocontact = () => {
    navigate('/Contact')
  }

  const navigatetoAboutUs = () => {
    navigate('/AboutUs')
  }
  const navigatetohome = () => {
    navigate('/Home')
  }

  const LoginValidateClick = () => {
    navigate('/Dashboard')
  }
  //for Subscribe Modal
  const handleSubscribeOpenModal = () => {
    setSubscribeModalOpen(true);
    setPrevSelectedTab(selectedTab);
  };

  const handleSubscribeCloseModal = () => {
    setSubscribeModalOpen(false);
    setSelectedTab(prevSelectedTab);
  };
  //for SignUp Choose Modal
  const handleSignUpChooseModal = () => {
    setLoginModalOpen(false);
    setSignUpChooseModalOpen((prev) => !prev);
    setPrevSelectedTab(selectedTab);
  };


  // for Mobile view Menu
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  useEffect(() => {
    // Store the selectedTab in localStorage when it changes
    localStorage.setItem('selectedTab', selectedTab.toString());
  }, [selectedTab]);

  return (
    <>
      <AppBar className='AppBar' position="fixed" sx={{ height: isMobile ? 50 : 50 }}>
        <Container maxWidth={isMobile ? '100%' : '960px'}>
          <Box minHeight={isMobile ? '50px' : '50px'} sx={isMobile || isTablet ? styles.headerIconAlignMob : styles.headerIconAlign}>
            {isMobile || isTablet ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={login_logo} alt='logo' className='sitelogo' />
              </Box>
            ) : null}
            <Box>
              {isMobile || isTablet ? (
                <>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleDrawerOpen}
                    aria-label="menu"
                    sx={{ marginLeft: 'auto', paddingRight: '15px' }}
                  >
                    <MenuIcon sx={{ color: 'black' }} />
                  </IconButton>
                  <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={handleDrawerClose}
                    sx={{
                      width: '100% !important',
                      height: 'auto !important',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', borderBottom: '1px solid #ddd' }}>
                      <IconButton edge="start" color="inherit" onClick={handleDrawerClose} aria-label="close-menu" sx={{ paddingRight: '15px' }}>
                        <CloseIcon sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>
                    <MenuItem className="MenuItem" onClick={() => { navigatetohome(); handleDrawerClose(); }}>
                      <HomeIcon sx={{ marginRight: '10px' }} />
                      Home
                    </MenuItem>
                    <MenuItem className="MenuItem" onClick={() => { navigatetoAboutUs(); handleDrawerClose(); }}>
                      <InfoIcon sx={{ marginRight: '10px' }} />
                      About Us
                    </MenuItem>
                    <MenuItem className="MenuItem" onClick={() => { navigatetocontact(); handleDrawerClose(); }}>
                      <PermContactCalendarIcon sx={{ marginRight: '10px' }} />
                      Contact Us
                    </MenuItem>
                    <MenuItem className="MenuItem" onClick={() => { navigate('Subscribe'); handleDrawerClose(); }}>
                      <LoyaltyIcon sx={{ marginRight: '10px' }} />
                      Subscribe Now
                    </MenuItem>
                    <MenuItem className="MenuItem" onClick={() => { handleFreeTrailModalOpen(); handleDrawerClose(); }}>
                      <FreeBreakfastIcon sx={{ marginRight: '10px' }} />
                      Free Trial
                    </MenuItem>
                    <MenuItem onClick={() => { navigate('LoginPage'); handleDrawerClose(); }}>
                      <LockOpenIcon sx={{ marginRight: '10px' }} />
                      Login
                    </MenuItem>
                  </Drawer>
                </>
              ) : (
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    display: 'flex',
                    color: 'black',
                    '& .MuiTabs-scrollButtons': {
                      padding: '10px', // Increase the padding for scroll buttons if needed
                    },
                    '& .MuiTab-root': {
                      ...tabsStyles, // Adjust padding for larger screens
                      margin: '0px 10px', // Set uniform margin for all tabs
                      fontWeight: '700',
                      textTransform: 'capitalize',
                      fontSize: '16px',
                      '&:hover': {
                        color: '#cc0033', // Change text color to red on hover
                      },
                      '&.Mui-selected': {
                        color: '#cc0033', // Change text color when the tab is active/selected
                      },
                    },
                  }}>
                  <Tab label="Home" onClick={navigatetohome} />
                  <Tab label="About Us" onClick={navigatetoAboutUs} />
                  <Tab label="Contact Us" onClick={navigatetocontact} />
                  <Tab label="Subscribe Now" onClick={handleSubscribeOpenModal} />
                  <Tab label="Free Trial" onClick={handleFreeTrailModalOpen} />
                  <Tab label="Login" onClick={handleLoginModalOpen} />
                </Tabs>
              )}
            </Box>
          </Box>
        </Container>
      </AppBar>

      {/* ForgotPassword */}


      <Modal dir="rtl" className='ForgetpwdModal' open={isForgotPasswordModalOpen} onClose={handleForgotPasswordModalClose} BackdropProps={{
        onClick: (event) => {
          event.stopPropagation();
        },
      }}
        disableBackdropClick>
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', borderRadius: '4px', width: '100%',
          maxWidth: '550px', display: 'flex', flexDirection: 'column',
        }}>
          <Box sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px', }}>
            <IconButton
              sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }} onClick={handleForgotPasswordModalClose} >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>Forgot Password</Typography>
          </Box>
          <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Username" placeholder="Enter your username" margin="normal" variant="outlined" size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ marginLeft: '30%' }}><AccountCircle /></IconButton>
                  </InputAdornment>
                ),
              }} />

            <TextField
              label="Email" placeholder="example@test.com" margin="normal" type="email" variant="outlined" size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ marginLeft: '30%' }}><EmailIcon /></IconButton>
                  </InputAdornment>
                ),
              }} />
          </Box>
          <Box sx={{ mt: 3, borderTop: '1px solid #d5d5d5', display: 'flex', justifyContent: 'space-between', padding: '20px 10px 20px 15px', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom onClick={handleLoginModalOpen} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <ArrowCircleLeftIcon /><span style={{ color: '#337ab7', marginLeft: '5px' }}>User Login</span>

            </Typography>
            {/* <Button variant="outlined" sx={{ marginLeft: '30%',':hover': {
            backgroundColor: '#adadad'}, }} color="secondary" onClick={handleForgotPasswordModalClose}>
              Cancel 
            </Button>
            <Button variant="contained" color="primary" sx={{backgroundColor: 'rgb(220, 0, 55)'}}>
              Proceed
            </Button> */}

            <CustomButton label="Cancel" color="secondary" sx={{
              marginLeft: '30%', ':hover': {
                backgroundColor: '#adadad'
              },
            }} onClick={handleForgotPasswordModalClose} />
            <CustomButton label="Proceed" color="primary" />
          </Box>

        </Box>
      </Modal>

      {/* Login */}

      <Modal className={`custom-modal ${isRtl ? 'rtl-modal' : 'ltr-modal'}`} open={isLoginModalOpen} onClose={handleLoginModalClose}
        BackdropProps={{
          onClick: (event) => {
            event.stopPropagation();
          },
        }}
        disableBackdropClick>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', borderRadius: '5px 5px', width: '100%', maxWidth: '550px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px', alignItems: 'center' }}>
            <IconButton sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }} onClick={handleLoginModalClose}><CloseIcon /></IconButton>
            <Typography variant="h6" gutterBottom>User Login</Typography>
          </Box>
          <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Username" placeholder="Enter your username" margin="normal" variant="outlined" size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ marginLeft: '30%' }}>
                      <AccountCircle />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Password" placeholder="Enter your password" margin="normal" type={showPassword ? 'text' : 'password'} className="textfield" variant="outlined" size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }} />
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={<Checkbox color="primary" />} label="Remember me"
              />
              <Typography variant="body2" sx={{ cursor: 'pointer' }}><span style={{ color: '#337ab7' }} onClick={handleFreeTrailModal}>Free Trail</span></Typography>
              <Typography variant="body2" sx={{ cursor: 'pointer' }} onClick={handleForgotPasswordModalOpen}><span style={{ color: '#337ab7', cursor: 'pointer' }}>Forget Password</span></Typography>
            </Box>
          </Box>
          <Box sx={{ borderTop: '1px solid #d5d5d5', display: 'flex', justifyContent: 'space-between', padding: '20px 10px 20px 15px', alignItems: 'center' }}>
            <Typography variant="body2" onClick={handleSignUpChooseModal}>
              Don't have an Account? <span style={{ color: '#337ab7', cursor: 'pointer' }}>Create an Account</span>
            </Typography>
            <Button variant="outlined" sx={{ marginLeft: '10%' }} color="secondary" onClick={handleLoginModalClose}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={LoginValidateClick} ><FontAwesomeIcon icon={faKey} style={{ marginRight: '8px' }} /> Login</Button>            </Box>
        </Box>
      </Modal>

      {/* Create An Account */}

      <Dialog open={isCreateAccountModalOpen} onClose={handleCreateAccountModalClose} fullWidth maxWidth="md" BackdropProps={{
        onClick: (event) => {
          event.stopPropagation();
        },
      }}
        disableBackdropClick>

        <DialogTitle sx={{
          height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '16px',
          background: 'rgb(228, 228, 228)', color: '#000', borderBottom: '1px solid grey'
        }}>
          <b>Sign Up</b>
          <DialogActions>
            <IconButton
              sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }} onClick={handleCreateAccountModalClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>

        <grid container sx={{ alignItems: 'center', justifyContent: 'space-between', paddingRight: '16px' }}>

          <Typography variant='h6' sx={{ textAlign: 'center' }}><b></b></Typography>

          <Box sx={{ mt: 3 }}></Box>
        </grid>

        <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', paddingRight: '16px' }}>

          <Typography variant='h7'></Typography>
        </Grid>

      </Dialog>

      {/* Free Trail on Tab */}

      <Modal className='FreeTrailModal' open={isFreeTrailOpen} onClose={handleFreeTrailModalClose} BackdropProps={{
        onClick: (event) => {
          event.stopPropagation();
        },
      }}
        disableBackdropClick>
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff',
          borderRadius: '10px', width: '100%', maxWidth: '550px', display: 'flex', flexDirection: 'column',
        }}>
          <Box sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px', }}>
            <IconButton
              sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }} onClick={handleFreeTrailModalClose}  >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>Free Trail</Typography>
          </Box>
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Typography variant="h6" gutterBottom>Moto Visuals</Typography>
            <Typography variant="h7" gutterBottom>Enter mobile number to receive one time password</Typography>

          </Box>
          <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'row', gap: '10px', maxLength: '10px' }}>
            <TextField
              label="FirstName" placeholder="Enter your username" margin="normal" sx={{ width: '60%' }} variant="outlined" maxWidth className="textfield" size="small" />
            <TextField
              label="LastName" placeholder="Enter your lastname" margin="normal" sx={{ width: '60%' }} variant="outlined" className="textfield" size="small" />
          </Box>
          <Box sx={{ mt: -5 }}></Box>
          <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="AAP / Worldpac Customer number" placeholder="AAP / Worldpac Customer number" margin="normal" variant="outlined" className="textfield" size="small" />
            <Box sx={{ mt: 2 }}></Box>
            <Grid container alignItems="center" >
              <Grid item>
                <TextField
                  select variant="outlined" size="small" value={countryCode} onChange={handleCountryCodeChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}>
                  {countries.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.code} {country.countryCode}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth variant="outlined" size="small" label="Mobile Number" value={mobileNumber} onChange={handleMobileNumberChange} placeholder="Mobile Number" />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}></Box>
            <TextField
              label="Email" placeholder="Enter your Email" margin="normal" variant="outlined" className="textfield" size="small" />
          </Box>
          <Box sx={{ borderTop: '1px solid #d5d5d5', display: 'flex', justifyContent: 'space-between', padding: '20px 10px 20px 15px', alignItems: 'center' }}>
            <FormControlLabel control={<Checkbox color="primary" />} label="By clicking submit, I agree to receive a One Time PIN via SMS" />
            {/* <Button variant="contained" color="primary" style={{backgroundColor: 'rgb(220, 0, 55)', marginRight: '3%' }}> Proceed</Button> 
                            <Button variant="outlined"  color="secondary" onClick={handleFreeTrailModalClose}>Cancel</Button> */}
            <CustomButton label="Proceed" color="primary" sx={{ marginRight: '3%' }} />
            <CustomButton label="Cancel" color="secondary" onClick={handleFreeTrailModalClose} />
          </Box>
        </Box>
      </Modal>


      {/* Free Trail on Login */}

      <Modal className='FreeTrailModal' open={isFreeTrailOpenPopup} onClose={handleFreeTrailModalClose} BackdropProps={{
        onClick: (event) => {
          event.stopPropagation();
        },
      }}
        disableBackdropClick>
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff',
          borderRadius: '10px', width: '100%', maxWidth: '550px', display: 'flex', flexDirection: 'column',
        }}>
          <Box sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px', }}>
            <IconButton
              sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }} onClick={handleFreeTrailModalClose}  >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>Free Trail</Typography>
          </Box>
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Typography variant="h6" gutterBottom>Moto Visuals</Typography>
            <Typography variant="h7" gutterBottom>Enter mobile number to receive one time password</Typography>

          </Box>
          <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'row', gap: '10px', maxLength: '10px' }}>
            <TextField
              label="FirstName" placeholder="Enter your username" margin="normal" sx={{ width: '60%' }} variant="outlined" maxWidth className="textfield" size="small" />
            <TextField
              label="LastName" placeholder="Enter your lastname" margin="normal" sx={{ width: '60%' }} variant="outlined" className="textfield" size="small" />
          </Box>
          <Box sx={{ mt: -5 }}></Box>
          <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="AAP / Worldpac Customer number" placeholder="AAP / Worldpac Customer number" margin="normal" variant="outlined" className="textfield" size="small" />
            <Box sx={{ mt: 2 }}></Box>
            <Grid container alignItems="center" >
              <Grid item>
                <TextField
                  select variant="outlined" size="small" value={countryCode} onChange={handleCountryCodeChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}>
                  {countries.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.code} {country.countryCode}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth variant="outlined" size="small" label="Mobile Number" value={mobileNumber} onChange={handleMobileNumberChange} placeholder="Mobile Number" />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}></Box>
            <TextField
              label="Email" placeholder="Enter your Email" margin="normal" variant="outlined" className="textfield" size="small" />
          </Box>
          <Box sx={{ borderTop: '1px solid #d5d5d5', display: 'flex', justifyContent: 'space-between', padding: '20px 10px 20px 15px', alignItems: 'center' }}>
            <FormControlLabel control={<Checkbox color="primary" />} label="By clicking submit, I agree to receive a One Time PIN via SMS" />
            {/* <Button variant="contained" color="primary" style={{backgroundColor: 'rgb(220, 0, 55)', marginRight: '3%' }}> Proceed</Button> 
                            <Button variant="outlined"  color="secondary" onClick={handleFreeTrailModalClose}>Cancel</Button> */}
            <CustomButton label="Proceed" color="primary" sx={{ marginRight: '3%' }} />
            <CustomButton label="Cancel" color="secondary" onClick={handleFreeTrailModalClose} />
          </Box>
        </Box>
      </Modal>

      {/* for Subscribe Modal */}
      <ModalSubscribe
        open={isSubscribeModalOpen}
        onClose={handleSubscribeCloseModal}
        title="Modal Title"
        content="This is the content of the Modal."
      />

      {/* for SignUp Choose Modal */}
      <ModalSignUpChoose
        open={isSignUpChooseModalOpen}
        onClose={handleSignUpChooseModal}
        title="Sign Up"
        content="This is the content of the Modal."
      />
    </>
  );
};
export default Header;
