import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Typography, Modal, TextField, FormControl, FormLabel, InputAdornment, createTheme, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ReactQuill from 'react-quill';
import '../Settings/Settings.css';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../../../Components/Button';

const Email_Content = () => {
    const [message1, setMessage1] = useState('');
    const [message2, setMessage2] = useState('');
    const [message3, setMessage3] = useState('');
    const [Textmessage, setmessage4] = useState('');
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);

    const editorRef = useRef(null);
    const [characterCount, setCharacterCount] = useState(0);
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "& .MuiInputBase-input": {
            borderRadius: 4,
            position: "relative",
            backgroundColor:
                theme.palette.mode === "light" ? "#ffffff" : "#1A2027",
            border: "1px solid",
            borderColor:
                theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
            fontSize: 16,
            width: "320px",
            padding: "10px 12px",
            transition: theme.transitions.create([
                "border-color",
                "background-color",
                "box-shadow",
            ]),
            fontFamily: [].join(","),
            "&:focus": {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current.getEditor();
            const text = editor.getText();
            setCharacterCount(text.length);
        }
    }, [message1]);
    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current.getEditor();
            const text = editor.getText();
            setCharacterCount(text.length);
        }
    }, [message2]);
    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current.getEditor();
            const text = editor.getText();
            setCharacterCount(text.length);
        }
    }, [message3]);
    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current.getEditor();
            const text = editor.getText();
            setCharacterCount(text.length);
        }
    }, [Textmessage]);

    const handleMessageChange1 = (value) => {
        setMessage1(value);
    };
    const handleMessageChange2 = (value) => {
        setMessage2(value);
    };
    const handleMessageChange3 = (value) => {
        setMessage3(value);
    };
    const handleMessageChange4 = (value) => {
        setmessage4(value);
    };

    const handleTextChange1 = () => {
        if (editorRef.current) {
            const editor = editorRef.current.getEditor();
            const text = editor.getText();
        }
    };

    const handleTextChange2 = () => {
        if (editorRef.current) {
            const editor = editorRef.current.getEditor();
            const text = editor.getText();
        }
    };
    const handleTextChange3 = () => {
        if (editorRef.current) {
            const editor = editorRef.current.getEditor();
            const text = editor.getText();
        }
    };
    const handleTextChange4 = () => {
        if (editorRef.current) {
            const editor = editorRef.current.getEditor();
            const text = editor.getText();
            setCharacterCount(text.length);
        }
    };
    const handleuploadOpenModal = () => {
        setUploadModalOpen(true);
    };
    const handleuploadCloseModal = () => {
        setUploadModalOpen(false);
    };

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1920,
          },
        },
      });
      
      
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));

    return (

        <Grid sx={{  width: '100%', justifyContent: 'center' }}>
            <Grid className='contentemaildetails'>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <FormLabel>Email From Name<br/>
                            <FormControl variant="standard">
                                <BootstrapInput defaultValue="web test" id="bootstrap-input" /></FormControl>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <FormLabel>BCC Address for Sent Mail<br/>
                            <FormControl variant="standard">
                                <BootstrapInput defaultValue="test@gmail.com" id="bootstrap-input" /></FormControl>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <FormLabel>Subject<br/>
                            <FormControl variant="standard">
                                <BootstrapInput
                                    defaultValue="test case" id="bootstrap-input" />
                            </FormControl>
                        </FormLabel>
                    </Grid>
                </Grid>
                <Grid className='messagecontent' container spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography gutterbottom='h6'>Email Message 1</Typography>
                        <ReactQuill
                            value={message1}
                            onChange={handleMessageChange1}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline'],
                                ],
                            }}
                            formats={['bold', 'italic', 'underline', 'font']}
                            onSelectionChange={handleTextChange1}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography gutterbottom='h6'>Email Message 2</Typography>
                        <ReactQuill
                            value={message2}
                            onChange={handleMessageChange2}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline'],
                                ],
                            }}
                            formats={['bold', 'italic', 'underline', 'font']}
                            onSelectionChange={handleTextChange2}
                        />
                    </Grid>
                    <Box sx={{ mt: 4 }}></Box>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography gutterbottom='h6'>Email Message 2</Typography>
                        <ReactQuill
                            value={message3}
                            onChange={handleMessageChange3}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline'],
                                ],
                            }}
                            formats={['bold', 'italic', 'underline', 'font']}
                            onSelectionChange={handleTextChange3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography gutterbottom='h6'>Text Messages</Typography>
                        <ReactQuill
                            value={Textmessage}
                            multiline rows={5}
                            onChange={handleMessageChange4}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline'],
                                ],
                            }}
                            formats={['bold', 'italic', 'underline']}
                            onSelectionChange={handleTextChange4}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: "left" }} onClick={handleuploadOpenModal}>
                        <IconButton><CameraAltIcon />
                            <Typography gutterbottom='h6'>Upload Logo</Typography></IconButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: "right" }}>
                        <CustomButton color="primary" sx={{ marginTop: "30px", marginRight: "5px" }} label="Cancel" />
                        <CustomButton color="primary" sx={{ marginTop: "30px", marginRight: "5px" }} label="Save" /> 
                    </Grid>
                </Grid>
                <Modal open={isUploadModalOpen} onClose={handleuploadCloseModal}>
                    <Grid className={ isMobile ? 'UploadlogoModalMobile' : isTablet? 'UploadlogoModalTablet' : 'UploadlogoModal'}>
                        <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                            <IconButton
                                sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                                onClick={handleuploadCloseModal}><CloseIcon />
                            </IconButton>
                            <Typography variant="h5" gutterBottom>Upload Logo</Typography>
                        </Grid>
                        <Grid container spacing={2} sx={{ padding: '15px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField placeholder="URL For When Logo is Clicked" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                            </InputAdornment>),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="file" placeholder="Drop files here or click to upload"
                                    margin="normal" fullWidth variant="outlined" className="textfield" size="large" multiline rows={isMobile ? 6 : isTablet ? 4 : 5} />
                                <input id="logoUploadInput" type="file" style={{ display: 'none' }}
                                    onChange={(e) => {
                                        const selectedFile = e.target.files[0];
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                            <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Submit" />
                                <CustomButton color="primary" sx={{ marginRight: "5px" }} onClick={handleuploadCloseModal} label="Cancel" />   
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
            </Grid>
        </Grid>
    );
};
export default Email_Content;