import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Grid, Typography, Button, Modal, TextField, InputAdornment,createTheme, useMediaQuery  } from "@mui/material";
import '../Employee_Accounts/Emp_Accounts.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import CustomButton from '../../../../Components/Button';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (

        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(email) {
    return { email };
}

const rows = [
    createData('TestAkilan1'),
    createData('TestAkilan2'),
    createData('TestAkilan3'),
    createData('TestAkilan4'),
    createData('test5'),
    createData('test6'),
    createData('test7'),
    createData('test8'),
    createData('tcp9.com'),
    createData('test10'),
    createData('tcp11.akilan'),
    createData('test12'),
    createData('test13'),
    createData('test14'),
    createData('test15'),
    createData('test16'),
    createData('test17'),
    createData('test18'),
    createData('tcp19.com'),
    createData('test20'),
    createData('test@exert21.com'),
    createData('tcp22.akilan'),
    createData('test23'),
];

const options = [
    { value: 20, label: 'Manual Drivetrain' },
    { value: 30, label: 'Axles & Driveline' },
    { value: 40, label: 'Body' },
    { value: 50, label: 'Brake system' },
    { value: 60, label: 'cooling system' }, { value: 70, label: 'Engine' }, { value: 80, label: 'Tire' }, { value: 90, label: 'Winter Check' },
];

export default function CustomPaginationActionsTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isLocationModalOpen, setLocationModalOpen] = useState(false);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    const handleLocationOpenModal = () => {
        setLocationModalOpen(true);
    };

    const handleCloseModal = () => {
        setLocationModalOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            tb: 768,
            md: 1024,
            lg: 1280,
            xl: 1920,
          },
        },
      });
    
      const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
      const isTablet = useMediaQuery(theme.breakpoints.between("sm","tb"));
      const isAboveTablet = useMediaQuery(theme.breakpoints.between("tb","md"));
      
      let buttonPadding = {};
      let setGridPosition = {};
      
      const setGridPos ={
        paddingLeft: "5%", 
        paddingTop: "5%", 
        paddingRight: "5%",
        paddingBottom: "20%",
        width: "100%",
      }
      
      const setGridPosMobile ={
        paddingTop: "55px", 
        paddingRight: "5%",
        paddingLeft: "5%",
        paddingBottom: "30%",
        width:'100%',
      }
      
      const setGridPosTablet ={
        paddingTop: "10%", 
        paddingRight: "5%",
        paddingLeft: "5%",
        paddingBottom: "30%",
        width:'100%',
      }
      
      const setGridPosAboveTablet ={
        paddingTop: "10%", 
        paddingRight: "10%",
        paddingLeft: "10%",
        paddingBottom: "30%",
        width:'100%',
      }
      
      if(isMobile){
        setGridPosition = setGridPosMobile ;
      }
      else if(isTablet){
        setGridPosition = setGridPosTablet;
      }
      else if(isAboveTablet){
        setGridPosition = setGridPosAboveTablet; 
      }
      else{
        setGridPosition = setGridPos;
      }
    

    return (
        <Grid sx={setGridPosition}>
            <Grid>
                <Grid container>
                    <Box className='manageuser'>
                        <Typography variant="h5" gutterBottom>Manage Locations</Typography>
                        <Box sx={{ border: "1px solid grey" }}></Box>
                        <Box sx={{ mt: 2 }}></Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField label="Business Entity" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton></IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Location Class Name"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField label="LocationName" placeholder="Enter your LocationName" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <div className="container">
                                    <div className="columns">
                                        <div className="column">
                                            <div style={{ whiteSpace:'nowrap' }} className="up-in-toggle">
                                                <Typography variant="h7" gutterBottom>View Deleted:  </Typography>
                                                <input type="radio" id="switch_left" name="switch_2" value="yes" />
                                                <label htmlFor="switch_left">On</label>
                                                <input type="radio" id="switch_right" name="switch_2" value="no" checked />
                                                <label htmlFor="switch_right">Off</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid sx={{ float: 'right' }}>
                            <CustomButton color="primary" label="Search" />
                            <CustomButton color="primary" sx={{ marginLeft: "2px" }} onClick={handleLocationOpenModal} label="Add Location" />
                        </Grid>
                    </Box>
                </Grid>
                <Box sx={{ mt: 2 }}></Box>
            </Grid>
            <Modal open={isLocationModalOpen} onClose={handleCloseModal}>
                <Grid className={isMobile ? 'LocationModalMobile' : isTablet ? 'LocationModalTablet' : 'LocationModal'}>
                    <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                            onClick={handleCloseModal}><CloseIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>Add Location</Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ padding: '15px' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Business Entity" placeholder="Enter your Business Entity" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Address Type" placeholder="Enter your Address Type" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Location Name" placeholder="Enter your Location Name" margin="normal" fullWidth variant="outlined" className="textfield" size="small" />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Location Class Name" placeholder="Enter your Location Class Name" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Store Number" placeholder="Enter your Store Number" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="account_number" placeholder="Enter your account_number" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Country" placeholder="Enter your Country" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Telephone Number" placeholder="Enter your Telephone Number" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="State/Province" placeholder="Enter your State/Province" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Street Address" placeholder="Enter your Street Address" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Business Unit Brand" placeholder="Enter your Business Unit Brand" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="City" placeholder="Enter your City" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="First Name" placeholder="Enter your First Name" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Zip Code" placeholder="Enter your Zip Code" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextField label="Account Location Limit" placeholder="Enter your Account Location Limit" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}></Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}></Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Grid>
                                <CustomButton color="primary" sx={{float: 'right' }} label="Save" />                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

            <Grid className='manageuser'>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="custom pagination table">
                        <TableBody>
                            <TableRow>
                                <TableCell align="left"><b>Location Name</b></TableCell>
                                <TableCell ><b>Location Class Name</b></TableCell>
                                <TableCell ><b>Street Address</b></TableCell>
                                <TableCell ><b>City</b></TableCell>
                                <TableCell ><b>Phone</b></TableCell>
                                <TableCell ><b>Action</b></TableCell>
                            </TableRow>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row) => (
                                <TableRow key={row.email}>
                                    <TableCell component="th" scope="row"> {row.email}</TableCell>
                                    <TableCell component="th" scope="row"> {row.email}</TableCell>
                                    <TableCell component="th" scope="row"> {row.email}</TableCell>
                                    <TableCell component="th" scope="row"> {row.email}</TableCell>
                                    <TableCell component="th" scope="row"> {row.email}</TableCell>
                                    <TableCell align="left"><ModeEditIcon /><DeleteIcon /></TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter >
                            <TableRow >
                                <TablePagination

                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]} className='pagination' colSpan={6}
                                    count={rows.length} rowsPerPage={rowsPerPage} page={page}
                                    sx={{ maxWidth:'100vw',justifyContent:'initial'}}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
