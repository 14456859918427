import React, { useRef, useEffect } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import Box from '@mui/material/Box';

const Countrycodeintl = () => {
  const phoneInputRef = useRef(null);

  useEffect(() => {
    const iti = intlTelInput(phoneInputRef.current, {
      initialCountry: 'us',
      separateDialCode: true,
    });

    phoneInputRef.current.addEventListener('countrychange', () => {
      const selectedCountryData = iti.getSelectedCountryData();
      console.log('Selected country data:', selectedCountryData);
    });

    return () => {
      iti.destroy();
    };
  }, []);

  return (
    <Box width="100%" height="100px">
      <input type="tel" ref={phoneInputRef} placeholder="Mobile Number For One-Time PIN" style={{ width: '100%', height: '45px' }} />
    </Box>
  );
};

export default Countrycodeintl;
