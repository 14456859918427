import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import Contact from './pages/Home/Contact';
import Header from './pages/common/Header';
import Footer from './pages/common/Footer';
import Aboutus from './pages/Home/Aboutus';
import LoginPage from './pages/Home/LoginPage';
import Subscribe from './pages/Home/Mobileview/Subscribe';
import Dashboard from './pages/Dashboard/Dashboard';
import Header_Dashboard from './pages/Dashboard/Header';
import Footer_Dashboard from './pages/Dashboard/Footer';
import Leftsidebar from './pages/Dashboard/Leftsidebar';
import Myvideo from './pages/Dashboard/SidebarTools/Myvideo';
import PersonalSettings from './pages/Dashboard/SidebarTools/Settings/Personal Settings';
import ApiKey from './pages/Dashboard/SidebarTools/Settings/Api Key';
import ManageFavourites from './pages/Dashboard/SidebarTools/Settings/Manage Favourite';
import AnimationLoopSettings from './pages/Dashboard/SidebarTools/Settings/Animation Loop Settings';
import TextMessage from './pages/Dashboard/SidebarTools/Settings/TextMessage';
import VLEInstruction from './pages/Dashboard/SidebarTools/Settings/VLE_Instruction';
import PhoneNumbers from './pages/Dashboard/SidebarTools/Settings/PhoneNumers';
import UploadUserVideo from './pages/Dashboard/SidebarTools/Settings/Upload_User_videos';
import ManageUser from './pages/Dashboard/SidebarTools/Employee_Accounts/ManageUser';
import ManageLocation from './pages/Dashboard/SidebarTools/Employee_Accounts/ManageLocations';
import ManageLocationClass from './pages/Dashboard/SidebarTools/Employee_Accounts/ManageLocationClass';
import UsageReports from './pages/Dashboard/SidebarTools/Reports/UsageReports';
import EmailSendReport from './pages/Dashboard/SidebarTools/Reports/EmailSendReport';
import GenerateLinkReport from './pages/Dashboard/SidebarTools/Reports/GenerateLinkReport';
import EmbeddedAnimationsReport from './pages/Dashboard/SidebarTools/Reports/EmbeddedAnimationsReport';
import EmailTabs from './pages/Dashboard/SidebarTools/Settings/Email_Tabs';

import { Box } from '@mui/material';

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

function AppRoutes() {
  const location = useLocation();

    console.log(location);
  
  const allowedPaths = ['/Dashboard', '/Myvideo', '/PersonalSettings','/api_key_settings','/favourites_settings','/email_address_settings',
'/looped_animations_settings','/sms_messages','/vle_instructions','/phone_numbers','/user_custom_videos','/manage_user','/manage_locations','/manage_region',
'/usage_report','/email_sent_report','/generate_link_report','/embedded_animations_report','/email_tabs'];
  const isDashboardPage = allowedPaths.includes(location.pathname);
  
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {isDashboardPage && (
          <>
            <Header_Dashboard />
            <Leftsidebar />
            <Footer />
          </>
        )}
        {!isDashboardPage &&(
          
          <>
          <Header />
          </>
        )}
        
        <Routes>
          <Route path='/Home' element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/AboutUs' element={<Aboutus />} />
          <Route path='/LoginPage' element={<LoginPage />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          {isDashboardPage && <Route path='/Myvideo' element={<Myvideo />} />}
          {isDashboardPage && <Route path='/PersonalSettings' element={<PersonalSettings />} />}
          {isDashboardPage && <Route path='/api_key_settings' element={<ApiKey />} />}
          {isDashboardPage && <Route path='/favourites_settings' element={<ManageFavourites/>} />}
          {isDashboardPage && <Route path='/looped_animations_settings' element={<AnimationLoopSettings/>} />}
          {isDashboardPage && <Route path='/sms_messages' element={<TextMessage/>} />}
          {isDashboardPage && <Route path='/vle_instructions' element={<VLEInstruction/>} />}
          {isDashboardPage && <Route path='/phone_numbers' element={<PhoneNumbers/>} />}
          {isDashboardPage && <Route path='/user_custom_videos' element={<UploadUserVideo/>} />}
          {isDashboardPage && <Route path='/manage_user' element={<ManageUser/>} />}
          {isDashboardPage && <Route path='/manage_locations' element={<ManageLocation/>} />}
          {isDashboardPage && <Route path='/manage_region' element={<ManageLocationClass/>} />}
          {isDashboardPage && <Route path='/usage_report' element={<UsageReports/>} />}
          {isDashboardPage && <Route path='/email_sent_report' element={<EmailSendReport/>} />}
          {isDashboardPage && <Route path='/generate_link_report' element={<GenerateLinkReport/>} />}
          {isDashboardPage && <Route path='/embedded_animations_report' element={<EmbeddedAnimationsReport/>} />}
          {isDashboardPage && <Route path='/email_tabs' element={<EmailTabs/>} />}

          <Route path='/Subscribe' element={<Subscribe />} />
        </Routes>
        {!isDashboardPage && <Footer />}
      </Box>
    </>
  );
}

export default App;
