import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Tabs, Tab,  Button, Container, Grid, useMediaQuery, createTheme, styled  } from '@mui/material';
import CustomListItem from '../../Components/CustomListItem';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalTermsandConditions from '../../pages/Home/TermsandConditions';
import ModalPrivacyPolicy from '../../pages/Home/PrivacyPolicy';
import LoginIcon from '@mui/icons-material/Login';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ModalLogin from '../../pages/Home/Language';



var styles = {

  footerAppBar: {
    backgroundColor: 'rgb(228, 228, 228)',
    top: 'auto',
    bottom:'0px',
    textTransform: 'capitalize',
    position:'fixed',
  },
  footerAppBarScroll: {
    backgroundColor: 'rgb(228, 228, 228)',
    top: 'auto',
    textTransform: 'capitalize',
    position:'relative',
    paddingBottom: '50px'
  },
  footerAppBarMob: {
    backgroundColor: 'rgb(228, 228, 228)',
    top: 'auto',
    bottom: 0,
    textTransform: 'capitalize',
    position:'fixed',
    paddingBottom: '50px'
  },
  footerAppBarLg: {
    backgroundColor: 'rgb(228, 228, 228)',
    top: 'auto',
    bottom: 0,
    textTransform: 'capitalize',
    position:'fixed'
  },
  toolbar:{
    minHeight:'50px !important',
  },
  toolbarSmallDesktop:{
    minHeight:'50px !important',
  },
  toolbarMob:{
    minHeight:'45px !important',
  },
  copyright: {
    color:'#000'
  },
  language: {
    textAlign: 'right',
  },
  footerFixedBar: {
    backgroundColor: '#ffffffbf',
    padding:'7px 10px' ,
    top: 'auto',
    bottom: 0,
    textTransform: 'capitalize',
    position:'fixed',
    display:'block',
    textAlign: 'center'
  },
  footerFixedBarHide:{
    display:'none'
  },
  footerSignUp:{
    color:'#4c4c4c',
    border:'1px solid #4c4c4c',
    borderRadius:'2px',
    '&:hover': {
      backgroundColor: '#4c4c4c',
      color: '#ffffff',
      border:'1px solid #4c4c4c',
      borderRadius:'2px'
    }
  }
};

const Footer = () => {

  const navigate = useNavigate();

  const location = useLocation();

  const isCurrentPage =  location.pathname;

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const handleToggleLoginModal = () => {
    setIsLoginModalOpen((prev) => !prev);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024, // Increase the value for tablet width
        lg: 1280,
        xl: 1920,
        lg2: 1620,
      },
    },
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletDown = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isbetweenThanmdlg = useMediaQuery(theme.breakpoints.between('md','lg'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const isbetweenThanmdlg2 = useMediaQuery(theme.breakpoints.between('lg','lg2'));
  const [isTCModalOpen, setTCModalOpen] = useState(false);
  const [isPPModalOpen, setPPModalOpen] = useState(false);

  const navigatetohome =() => {
    navigate('/Home')
  }

  const navigatetocontact =()  => {
    navigate('/Contact')
  }

  const navigatetoAboutUs =() => {
     navigate('/AboutUs')
  }

  const handleTCmodalopen = () => {
    setTCModalOpen(true);
  };

  const handleTCmodalclose = () => {
    setTCModalOpen(false);
    
  };

  const handlePPmodalopen = () => {
    setPPModalOpen(true);
  };

  const handlePPmodalclose = () => {
    setPPModalOpen(false);
    
  };

 const MobileTabs = styled(Tabs)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-scrollButtons': {
        display: 'block',
        top:'12px',
        width:'auto',
        color:'#686868'
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& .MuiTabs-scrollButtons': {
        color:'#686868'
      },
    },
  }));

  const tabsStyles = {
    padding: isbetweenThanmdlg ? '10px 10px' : isbetweenThanmdlg2 ? '10px 2px' : '10px 8px',
    minWidth: isbetweenThanmdlg ? '60px' : isbetweenThanmdlg2 ? '60px' : '70px',
    fontSize: isbetweenThanmdlg ? '12px' : isbetweenThanmdlg2 ? '12px' : '14px',
    // Add any other styles you want to change for lg and above
  };
  const isSmScreen = useMediaQuery('(max-width: 1280px)'); // Check if screen width is less than or equal to 1024px
  const isCenterJustifiedCopyRight = isSmScreen ? 'center' : 'flex-start';
  const isCenterJustifiedLang = isSmScreen ? 'center' : 'flex-end';

  var footerAppBarPadding = '';
  if (
    (isCurrentPage === '/' ||
      isCurrentPage === '/Home' || 
      isCurrentPage === '/LoginPage' ||
      isCurrentPage === '/Subscribe') &&
    isMobile
  ) {
      if(isCurrentPage === '/LoginPage' || isCurrentPage === '/Subscribe'){
        footerAppBarPadding = styles.footerAppBarLg;
      }else{
        footerAppBarPadding = styles.footerAppBarMob;
      }
  } else if (isTablet) {
    footerAppBarPadding = styles.footerAppBar;
  } else if (isbetweenThanmdlg) {
    footerAppBarPadding = styles.footerAppBarLg;
  } else if (isLgUp) {
    footerAppBarPadding = styles.footerAppBarLg;
  } else{
    if(isCurrentPage==='/LoginPage' || isCurrentPage==='/'){
      footerAppBarPadding = styles.footerAppBar;
    }else{
        footerAppBarPadding = styles.footerAppBarScroll;
    }
  }
  
  return (
    <footer id={'footerdiv'} style={{ zIndex:"100000!important", display:isMobile ? 'none':'' }}>


<AppBar sx={{ ...(footerAppBarPadding && { ...footerAppBarPadding }) }}>
      <Container maxWidth="960px">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
            <Toolbar sx={{ justifyContent: isCenterJustifiedCopyRight, ...(isTabletDown ? styles.toolbarMob : styles.toolbar) }}>
            <MobileTabs variant="scrollable" scrollButtons="auto" sx={{
                  '& .MuiTab-root': {
                  textTransform: 'capitalize',
                  fontWeight:'600',
                  '&:hover': {
                    color: '#cc0033', // Change text color to red on hover
                  },
                  }}}>
                {/* <Tab sx={{...tabsStyles}} label="Home" onClick={navigatetohome}/> */}
                {/* <Tab sx={{...tabsStyles}} label="Contact Us" onClick={navigatetocontact}/>
                <Tab sx={{...tabsStyles}} label="About Us" onClick={navigatetoAboutUs}/> */}
                <Tab sx={{...tabsStyles}} label="Terms and Conditions" onClick={handleTCmodalopen}/>
                <Tab sx={{...tabsStyles}} label="Privacy Policy" onClick={handlePPmodalopen}/>
              </MobileTabs>
            </Toolbar>
          </Grid>

          {isMobile || isTablet ? (
            <>
            <Grid item xs={6} sm={6} md={12} lg={4} xl={3}>
              <Toolbar sx={{ justifyContent: isCenterJustifiedCopyRight, color:'#000', ...(isTabletDown || isLgDown ? styles.toolbarMob : styles.toolbar) }}>
                <Typography variant="body2" sx={styles.language}>
                © <strong>2023</strong> MotoVisuals
                </Typography>
              </Toolbar>
            </Grid>
            <Grid item xs={6} sm={6} md={12} lg={2} xl={3}>
              <Toolbar sx={{ justifyContent: isCenterJustifiedLang, color:'#000', ...(isTabletDown || isLgDown ? styles.toolbarMob : isLgUp ? styles.toolbarSmallDesktop : styles.toolbar) }}>
                <Typography variant="body2" sx={styles.language}>
                  <CustomListItem icon="inbox" title="Inbox" />
                </Typography>
              </Toolbar>
            </Grid>  
            </>
          ) : (
            <>
            <Grid item xs={6} sm={6} md={12} lg={4} xl={3}>
              <Toolbar sx={{ justifyContent: isCenterJustifiedCopyRight, color:'#000', ...(isTabletDown || isLgDown ? styles.toolbarMob : styles.toolbar) }}>
                <Typography variant="body2" sx={styles.language}>
                © <strong>2023</strong> MotoVisuals
                </Typography>
              </Toolbar>
            </Grid>  

            <Grid item xs={6} sm={6} md={12} lg={2} xl={3}>
              <Toolbar sx={{ justifyContent: isCenterJustifiedLang, color:'#000', ...(isTabletDown || isLgDown ? styles.toolbarMob : styles.toolbar) }}>
                <Typography variant="body2" sx={styles.language}>
                  <CustomListItem icon="inbox" title="Inbox" />
                </Typography>
              </Toolbar>
            </Grid>
            </>
          )}
        </Grid>
      </Container>
    </AppBar>
    

    {/* Showing/Hiding the Login and Signup buttons in Mobile view  */}
    <AppBar sx={
      (isCurrentPage==='/Contact' || 
      isCurrentPage==='/AboutUs' || 
      isCurrentPage==='/' ||
      isCurrentPage==='/Home') && isMobile

      
      ? styles.footerFixedBar : styles.footerFixedBarHide}>
        <Container maxWidth="960px">
          <Button className="btn" onClick={() => {navigate('LoginPage')}} sx={{marginRight:'20px', backgroundColor: '#cc0033', borderRadius:'2px' }} color="primary" variant="contained"><LockOpenIcon sx={{pr:'5px'}} /> Login</Button>
          <Button className="btn" sx={styles.footerSignUp} variant="outlined"><LoginIcon sx={{pr:'5px'}} /> Sign Up</Button>
      </Container>
    </AppBar>
    

    <ModalLogin open={isLoginModalOpen} onClose={handleToggleLoginModal} title="Login" />

        <ModalTermsandConditions
          open={isTCModalOpen}
              onClose={handleTCmodalclose}
              title="Modal Title"
              content="This is the content of the Modal."
        />

        <ModalPrivacyPolicy
          open={isPPModalOpen}
          onClose={handlePPmodalclose}
          title="Modal Title"
          content="This is the content of the Modal."
        />

      </footer>
  );
}

export default Footer;
