import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material';
import LeakingPiston from '../../../Assets/Images/LeakingPiston.jpg';
import EmailIcon from '@mui/icons-material/Email';
import {theme} from '../../../Components/themeforview.js';



const Myvideo = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));

    return (
        <Grid sx={{ paddingLeft: '10%', paddingTop: isMobile || isTablet ? '55px': '8%', paddingRight: '10%' }}>
            <Typography className='stickyHeading' sx={{ textAlign: 'center', fontWeight: 'bold', top:'50px!important' }} gutterBottom variant="h4" component="div">My Videos</Typography>
            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                <Grid item md={3} lg={3}>
                    <Card sx={{ display: 'flex', flexDirection: 'row', width: '250px', height: '300px' }}>
                        <CardActionArea className='card12'>
                            <CardMedia sx={{ height: '250px', margin: 0, padding: 0 }}>
                                <iframe src="https://www.youtube.com/embed/EgF01aSQyno?si=lYCF5qQaCv09wmIh"
                                    height="100%" frameborder="0" allowfullscreen style={{ width: '100%' }} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </CardMedia>
                            <CardContent sx={{ height: '70px', textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                            </CardContent>
                            <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center', justifyContent: 'center' }}>
                                <Grid>
                                    <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={3} lg={3}>
                    <Card sx={{ display: 'flex', flexDirection: 'row', width: '250px', height: '300px' }}>
                        <CardActionArea className='card12'>
                            <CardMedia component="img" height="250" image={LeakingPiston} alt="Leaking Piston" />
                            <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                            </CardContent>
                            <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center', justifyContent: 'center' }}>
                                <Grid>
                                    <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={3} lg={3}>
                    <Card sx={{ display: 'flex', flexDirection: 'row', width: '250px', height: '300px' }}>
                        <CardActionArea className='card12'>
                            <CardMedia sx={{ height: '250px', margin: 0, padding: 0 }}>
                                <iframe src="https://www.youtube.com/embed/EgF01aSQyno?si=lYCF5qQaCv09wmIh"
                                    height="100%" frameborder="0" allowfullscreen style={{ width: '100%' }}></iframe>
                            </CardMedia>                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                            </CardContent>
                            <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center', justifyContent: 'center' }}>
                                <Grid>
                                    <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={3} lg={3}>
                    <Card sx={{ display: 'flex', flexDirection: 'row', width: '250px', height: '300px' }}>
                        <CardActionArea className='card12'>
                            <CardMedia component="img" height="250" image={LeakingPiston} alt="Leaking Piston" />
                            <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                            </CardContent>
                            <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center', justifyContent: 'center' }}>
                                <Grid>
                                    <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={3} lg={3}>
                    <Card sx={{ display: 'flex', flexDirection: 'row', width: '250px', height: '300px' }}>
                        <CardActionArea className='card12'>
                            <CardMedia component="img" height="250" image={LeakingPiston} alt="Leaking Piston" />
                            <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                            </CardContent>
                            <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center', justifyContent: 'center' }}>
                                <Grid>
                                    <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={3} lg={3}>
                    <Card sx={{ display: 'flex', flexDirection: 'row', width: '250px', height: '300px' }}>
                        <CardActionArea className='card12'>
                            <CardMedia sx={{ height: '250px', margin: 0, padding: 0 }}>
                                <iframe src="https://www.youtube.com/embed/EgF01aSQyno?si=lYCF5qQaCv09wmIh"
                                    height="100%" frameborder="0" allowfullscreen style={{ width: '100%' }}></iframe>
                            </CardMedia>                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                            </CardContent>
                            <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center', justifyContent: 'center' }}>
                                <Grid>
                                    <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Myvideo;
