import React, { useState, useEffect } from "react";
import { Container, Grid, useMediaQuery, createTheme  } from '@mui/material';
import login_logo from '../../Assets/Images/logo_home.png';
import pre_login_banner from '../../Assets/Images/vv_prelogin_banner.jpg';
import { motion } from "framer-motion"

const styles = {

    ContainerBar: {
        padding:'100px 50px 100px 50px'
    },
    ContainerBarMob: {
        padding:'50px'
    }
  };

const Dashboard = () => {

const [animationComplete, setAnimationComplete] = useState(false);

useEffect(() => {
    // Set animation complete once the component mounts (page load)
    setAnimationComplete(true);
}, []);

const theme = createTheme({
    breakpoints: {
        values: {
        xs: 0,
        sm: 600,
        md: 1024, // Increase the value for tablet width
        lg: 1280,
        xl: 1920,
        lg2: 1620,
        mdT : 1200
        },
    },
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTabletDown = useMediaQuery(theme.breakpoints.down('md'));

    return(
        <Container sx={isMobile || isTabletDown ? styles.ContainerBarMob : styles.ContainerBar}>
            <Grid container>
                <Grid item xs={12}>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={animationComplete ? { opacity: 1, scale: 1 } : {}}
                        transition={{ duration: 1 }}
                        className='login_logo'
                        style={{ 'textAlign': 'center', 'marginBottom': '40px', 'marginTop': '40px' }}
                    >
                        <img src={login_logo} alt="login_logo" style={{ maxWidth: "100%", height: "auto" }} />
                    </motion.div>
                </Grid>
                <Grid item xs={12}>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={animationComplete ? { opacity: 1, scale: 1 } : {}}
                        transition={{ duration: 1.5 }}
                        className='pre_login_banner'
                        style={{ 'textAlign': 'center' }}
                    >
                        <img src={pre_login_banner} alt="pre_login_banner" style={{ maxWidth: "100%", height: "auto" }} />
                    </motion.div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;