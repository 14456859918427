import React from 'react';
import {useMediaQuery, createTheme} from '@mui/material';


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      tb: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
      lg2: 1620,
    },
  },
});


// const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
// const isBelowTablet = useMediaQuery(theme.breakpoints.between("sm","tb"));
// const isTablet = useMediaQuery(theme.breakpoints.between("tb","md"));
// const isAboveTablet = useMediaQuery(theme.breakpoints.between("md","lg"));



export { theme };