import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Grid, Typography, Button, Modal, TextField, InputAdornment, createTheme, useMediaQuery } from "@mui/material";
import '../Employee_Accounts/Emp_Accounts.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import CustomButton from '../../../../Components/Button';


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (

        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(email) {
    return { email };
}

const rows = [
    createData('TestAkilan1'),
    createData('TestAkilan2'),
    createData('TestAkilan3'),
    createData('TestAkilan4'),
    createData('test5'),
    createData('test6'),
    createData('test7'),
    createData('test8'),
    createData('tcp9.com'),
    createData('test10'),
    createData('tcp11.akilan'),
    createData('test12'),
    createData('test13'),
    createData('test14'),
    createData('test15'),
    createData('test16'),
    createData('test17'),
    createData('test18'),
    createData('tcp19.com'),
    createData('test20'),
    createData('test@exert21.com'),
    createData('tcp22.akilan'),
    createData('test23'),
];

const options = [
    { value: 20, label: 'Manual Drivetrain' },
    { value: 30, label: 'Axles & Driveline' },
    { value: 40, label: 'Body' },
    { value: 50, label: 'Brake system' },
    { value: 60, label: 'cooling system' }, { value: 70, label: 'Engine' }, { value: 80, label: 'Tire' }, { value: 90, label: 'Winter Check' },
];

const columns = [
    { field: 'LocationName', headerName: 'Location Name', width: 250,fontWeight: 'bolder' },
    { field: 'StreetAddress', headerName: 'Street Address', width: 250 ,fontWeight: 'bolder'},
    { field: 'City', headerName: 'City', width: 250 },
    {
        field: 'Phone',
        headerName: 'Phone',
        type: 'tel',
        width: 250,
    },

];
const rowsdata = [
    { id: 1, LocationName: 'kb', StreetAddress: 'Snow', City: 'Jon', Phone: 7826095535 },
    { id: 2, LocationName: 'Amravti', StreetAddress: 'Lannister', City: 'Cersei', Phone: 7826095542 },
    { id: 3, LocationName: 'Pune', StreetAddress: 'Lannister', City: 'Jaime', Phone: 7826095545 },
    { id: 4, LocationName: 'Mk1', StreetAddress: 'Stark', City: 'Arya', Phone: 1678260955 },
    { id: 5, LocationName: 'Mk15', StreetAddress: 'Targaryen', City: 'Daenerys', Phone: 8878260955 },
    { id: 6, LocationName: 'Mk16', StreetAddress: 'Melisandre', City: null, Phone: 1782609550 },
    { id: 7, LocationName: 'Mk17', StreetAddress: 'Clifford', City: 'Ferrara', Phone: 4782609554 },
    { id: 8, LocationName: 'Mk18', StreetAddress: 'Frances', City: 'Rossini', Phone: 3782609556 },
    { id: 9, LocationName: 'Mk19', StreetAddress: 'Roxie', City: 'Harvey', Phone: 6782609555 },
];


export default function CustomPaginationActionsTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isLocationClassModalOpen, setLocationClassModalOpen] = useState(false);
    const [isAssignLocationModalOpen, setAssignLocationModalOpen] = useState(false);
    const [open, setOpen] = useState(false);


    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleAssignLocationOpenModal = () => {
        setAssignLocationModalOpen(true);
    };
    const handleEmailOpenModal = () => {
        setLocationClassModalOpen(true);
    };

    const handleCloseModal = () => {
        setLocationClassModalOpen(false);
        setAssignLocationModalOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const toggleSidebar = () => {
        setOpen(!open);
      };

      const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            tb: 768,
            md: 1024,
            lg: 1280,
            xl: 1920,
          },
        },
      });
    
      const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
      const isTablet = useMediaQuery(theme.breakpoints.between("sm","tb"));
      const isAboveTablet = useMediaQuery(theme.breakpoints.between("tb","md"));
      
      let buttonPadding = {};
      let setGridPosition = {};
      
      const setGridPos ={
        paddingLeft: "5%", 
        paddingTop: "5%", 
        paddingRight: "5%",
        paddingBottom: "20%",
        width: "100%",
      }
      
      const setGridPosMobile ={
        paddingTop: "55px", 
        paddingRight: "5%",
        paddingLeft: "5%",
        paddingBottom: "30%",
        width:'100%',
      }
      
      const setGridPosTablet ={
        paddingTop: "10%", 
        paddingRight: "5%",
        paddingLeft: "5%",
        paddingBottom: "30%",
        width:'100%',
      }
      
      const setGridPosAboveTablet ={
        paddingTop: "10%", 
        paddingRight: "10%",
        paddingLeft: "10%",
        paddingBottom: "30%",
        width:'100%',
      }
      
      if(isMobile){
        setGridPosition = setGridPosMobile ;
      }
      else if(isTablet){
        setGridPosition = setGridPosTablet;
      }
      else if(isAboveTablet){
        setGridPosition = setGridPosAboveTablet; 
      }
      else{
        setGridPosition = setGridPos;
      }
    

      

    return (
        <Grid className={`mainview ${open ? 'open' : 'close'}`} sx={ setGridPosition }>
            <Grid>
                <Grid container>
                    <Box className='manageuser'>
                        <Typography variant="h5" gutterBottom>Manage Location Classes</Typography>
                        <Box sx={{ border: "1px solid grey" }}></Box>
                        <Box sx={{ mt: 2 }}></Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField label="Business Entity" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton></IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <div className="container">
                                    <div className="columnsswitch">
                                        <div className="column">
                                            <div style={{ whiteSpace:'nowrap'}}  className="up-in-toggle">
                                                <Typography variant="h7" gutterBottom>View Deleted:  </Typography>
                                                <input type="radio" id="switch_left" name="switch_2" value="yes" />
                                                <label htmlFor="switch_left">On</label>
                                                <input type="radio" id="switch_right" name="switch_2" value="no" checked />
                                                <label htmlFor="switch_right">Off</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid sx={{ float: 'right' }}>
                            <CustomButton color="primary" label="Search" />
                            <CustomButton color="primary" sx={{ marginLeft: "2px" }} onClick={handleEmailOpenModal} label="Add Location Class" />

                        </Grid>
                    </Box>
                </Grid>
                <Box sx={{ mt: 2 }}></Box>
            </Grid>
            <Modal open={isLocationClassModalOpen} onClose={handleCloseModal}>
                <Grid className={isMobile ? 'LocationClassModalMobile' : isTablet ? 'LocationClassModalTablet' : 'LocationClassModal'}>
                    <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                            onClick={handleCloseModal}><CloseIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>Add Location class</Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ padding: '15px' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField label="Business Entity" placeholder="Enter your Business Entity" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid>
                                <CustomButton color="primary" sx={{ float: 'right' }} label="Save" />                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

            <Grid className='manageuser'>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="custom pagination table">
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Location Class Name</b></TableCell>
                                <TableCell align="right"><b>Action</b></TableCell>
                            </TableRow>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row) => (
                                <TableRow key={row.email}>
                                    <TableCell component="th" scope="row"> {row.email}</TableCell>
                                    <TableCell align="right"><ModeEditIcon /><DeleteIcon /><Button variant="contained" color="primary" sx={{ backgroundColor: "rgb(220, 0, 55)", whiteSpace: 'nowrap'}} onClick={handleAssignLocationOpenModal}>Assign location</Button></TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow >
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]} className='pagination' colSpan={3}
                                    count={rows.length} rowsPerPage={rowsPerPage} page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
            <Modal open={isAssignLocationModalOpen} onClose={handleCloseModal}>
                <Grid className={ isMobile ? 'AssignModalMobile' : isTablet ? 'AssignModalTablet' :'AssignModal'}>
                    <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                            onClick={handleCloseModal}><CloseIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>Manage Locations</Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ padding: '15px' }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <TextField fullWidth margin="normal" variant="outlined" size="small" label="Location Class Name"
                                select
                                SelectProps={{
                                    labelId: 'demo-simple-select-label',
                                    id: 'demo-simple-select',
                                }}>
                                <MenuItem value={10}>test@exert.com</MenuItem>
                                <MenuItem value={20}>moto@mail.com</MenuItem>
                                <MenuItem value={30}>service@mail.com</MenuItem>
                                <MenuItem value={40}>visuals@mail.com</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <TextField label="Location Name" placeholder="Enter your Location Name" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <TextField fullWidth margin="normal" variant="outlined" size="small" label="Filter location"
                                select
                                SelectProps={{
                                    labelId: 'demo-simple-select-label',
                                    id: 'demo-simple-select',
                                }}>
                                <MenuItem value={10}>test@exert.com</MenuItem>
                                <MenuItem value={20}>moto@mail.com</MenuItem>
                                <MenuItem value={30}>service@mail.com</MenuItem>
                                <MenuItem value={40}>visuals@mail.com</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    align="left"
                                    rows={rowsdata}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    checkboxSelection
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid>
                                <Button variant="contained" color="primary" sx={{ backgroundColor: "rgb(220, 0, 55)", float: 'right' }}>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    );
}
