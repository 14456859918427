import React, {useState, useEffect } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountTypes2 from '../../Assets/Images/default/Accounttypes-02.png';
import AccountTypes1 from '../../Assets/Images/default/Accounttypes-01.png';
import ModalActivateAccount from '../../pages/Home/ActivateAccount';
import ModalSubscribe from '../../pages/Home/Subscribe';
import CustomButton from '../../Components/Button';


const useStyles = {
    dialogTitle: {
        background: 'rgb(228, 228, 228)',
        color: '#000'
    },
    languageText: {
        fontSize: '14px',
        textAlign: 'center'
    },
    gridItem: {
        textAlign: 'center', // Center the content inside Grid items
    },
    img: {
        width: "50%",
        height: "auto",
        display: 'block',
        margin: '0 auto', // Center the image inside the Grid item
        marginBottom:'10px',
        cursor: 'pointer',
    }
};

const SignupChoose = ({ open, onClose, title, onConfirm }) => {
    
    const [isSignupChooseModalOpen, setSignupChooseModalOpen] = useState(open);
    const [isActivateAccountModalOpen, setActivateAccountModalOpen] = useState(false);
    const [isSubscribeModalOpen, setSubscribeModalOpen] = useState(false);

    
    useEffect(() => {
        setSignupChooseModalOpen(open); // Update the state when the 'open' prop changes
      }, [open]);

    const handleActivateAccountModal = () => {
        setSignupChooseModalOpen(false);
        setActivateAccountModalOpen(true);
    };

    const handleSignupChooseModalClose = () => {
        onClose();
        setSignupChooseModalOpen(false);
    };

    const handleActivateAccountModalClose = () => {
        setActivateAccountModalOpen(false);
        onClose();
        setSignupChooseModalOpen(true);
    };

    const handleSignUpClick = (type) => {
        setSubscribeModalOpen(true);
        console.log('Clicked image type:', type);
    };  

    //for Subscribe Modal
    const handleSubscribeModal = () => {
        onClose();
        setSignupChooseModalOpen(false);
        setSubscribeModalOpen((prev) => !prev);
    };


   
  return (
    <>
    <Dialog open={isSignupChooseModalOpen} onClose={handleSignupChooseModalClose} fullWidth maxWidth="md">
      <DialogTitle className="custom-dialog-title" sx={useStyles.dialogTitle}>
        <b>{title}</b>
        <IconButton><CloseIcon onClick={onClose} /></IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
        <Grid container spacing={2} sx={{pt:'20px'}}>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12} sx={useStyles.gridItem}>
                <Typography variant='h5' sx={{padding:'10px', fontSize:'30px'}}>What kind of user are you?</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} sx={useStyles.gridItem}>
                    <img
                        src={AccountTypes2}
                        alt="Business"
                        style={useStyles.img}
                        onClick={() => handleSignUpClick('Business')}
                    />
                <Typography variant='body1'><b>Business</b></Typography>
                <Typography>Business users receive full site access for a monthly subscription fee.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} sx={useStyles.gridItem}>
                <Typography>
                    <img
                        src={AccountTypes1}
                        alt="Educational"
                        style={useStyles.img}
                        onClick={() => handleSignUpClick('Educational')}
                    />
                </Typography>
                <Typography><b>Educational</b></Typography>
                <Typography>If you're a student and want access, please ask a member of staff to create an account and set up on-campus and off-campus (VLE) access. All accounts are verified before approval.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12} sx={useStyles.gridItem}>
                <Typography variant='h6'>OR <span className='OneTimePin' onClick={handleActivateAccountModal}>Reactivate an existing account</span></Typography>
            </Grid>
        </Grid>
        </form>
      </DialogContent>
      <DialogActions className='custom-dialog-footer'>
        <CustomButton variant="outlined" label="Cancel" onClick={onClose} />
      </DialogActions>
    </Dialog>
  

    {/* for Activate Account Modal */}  
    <ModalActivateAccount
      open={isActivateAccountModalOpen}
      onClose={handleActivateAccountModalClose}
      title="Activate Account"
      content="This is the content of the Modal."
    />


    {/* for Subscribe Modal */}  
    <ModalSubscribe
        open={isSubscribeModalOpen}
        onClose={handleSubscribeModal}
        title="Modal Title"
        content="This is the content of the Modal."
        />
    
    </>
  );
};

export default SignupChoose;





