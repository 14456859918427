import * as React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material';
import LeakingPiston from '../../../Assets/Images/LeakingPiston.jpg';
import EmailIcon from '@mui/icons-material/Email';
import {theme} from '../../../Components/themeforview';




const Newanimation = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isBelowTablet = useMediaQuery(theme.breakpoints.between("sm","tb"));
    const isTablet = useMediaQuery(theme.breakpoints.between("tb","md"));
    const isAboveTablet = useMediaQuery(theme.breakpoints.between("md","lg"));
    return (
        <Grid >
            <Typography className={isMobile ? 'stickyHeading' : ''}   sx={{textAlign:'center',fontWeight: 'bold'}} gutterBottom variant="h4" component="div">New</Typography>        
        <Grid container spacing={2} sx={{ justifyContent: 'center'}}>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image="https://cdn.motovisuals.com/homepageImages/Tyres.gif" alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid> 
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image="https://cdn.motovisuals.com/homepageImages/Tyres.gif" alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid> 
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid> 
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid> 
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid> 
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>
                <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image="https://cdn.motovisuals.com/homepageImages/Tyres.gif" alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center',justifyContent:'center' }}>
                                        <Grid>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                </Grid>     

        </Grid>
        </Grid>
       

    );

}
export default Newanimation;
