import React, { useState } from 'react';
import { Grid, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const useStyles = {
  dialogTitle: {
    background: 'rgb(228, 228, 228)',
    color: '#000',
  },
  languageText: {
    fontSize: '14px',
    textAlign: 'center',
    cursor: 'pointer',
  },
};


const Language = ({ open, onClose, title, onConfirm }) => {
  const [isRtl, setIsRtl] = useState(false);

  const handleLanguageClick = (isRtlLanguage) => {
    setIsRtl(isRtlLanguage);
    if (isRtlLanguage) {
      const rootStyle = document.querySelector('#root');
      rootStyle.classList.add('hebrew'); 
      rootStyle.style.direction = 'rtl';
    } else {
      const rootStyle = document.querySelector('#root');
      rootStyle.classList.remove('hebrew'); 
      rootStyle.style.direction = 'ltr';
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle className="custom-dialog-title" sx={useStyles.dialogTitle}>
        <b>{title}</b>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2} sx={{ pt: '20px' }}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Box>
                <Typography onClick={() => handleLanguageClick(true)} sx={useStyles.languageText}>
                  Hebrew
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography onClick={() => handleLanguageClick(false)} sx={useStyles.languageText}>
                UK English
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography onClick={() => handleLanguageClick(false)} sx={useStyles.languageText}>
                UK English
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography onClick={() => handleLanguageClick(false)} sx={useStyles.languageText}>
                UK English
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography onClick={() => handleLanguageClick(false)} sx={useStyles.languageText}>
                UK English
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography onClick={() => handleLanguageClick(false)} sx={useStyles.languageText}>
                UK English
              </Typography>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className="custom-dialog-footer">
        <Button className="btn" onClick={onClose} variant="outlined" size="small">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Language;