import React, { useState } from 'react';
import { Typography, Box, Grid  } from '@mui/material';
import { Dialog, DialogTitle, DialogActions} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const useStyles = {
    gridItem: {
        height: '60px',                                                                                       
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: '16px', 
      background: 'rgb(228, 228, 228)', 
      color: '#000', 
      borderBottom:'1px solid grey',
    },
    closeButton: {
      cursor: 'pointer',
    },
  };



const TermsandConditions = ({ open, onClose,}) => {

    const [isTCModalOpen, setTCModalOpen] = useState(false);

    const handleTCmodalopen = () => {
        setTCModalOpen(true);
      };
    
      const handleTCmodalclose = () => {
        setTCModalOpen(false);
        
      };


return (
    <>

{/* // For Terms And Conditions */}
<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">

       
    <DialogTitle  sx={ useStyles.dialogTitle}><b>Terms and Conditions</b>
            <DialogActions>
              <IconButton
                 sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}onClick={onClose}>
              <CloseIcon />
            </IconButton>
      </DialogActions> </DialogTitle> 

<grid container sx={{alignItems: 'center', justifyContent: 'space-between', paddingRight: '16px'}}>

<Typography variant='h6' sx={{textAlign: 'center'}}><b>Terms and Condition</b></Typography>

<Box sx={{mt:3}}></Box>
</grid>

      
      <Grid container sx={{alignItems: 'center', justifyContent: 'space-between', paddingRight: '16px'}}>

             <Typography variant='h7'></Typography>
            </Grid>
</Dialog>
</>
  );
}

export default TermsandConditions;