import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Box, Typography, TextField, useMediaQuery, createTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { AccountCircle } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FlagIcon from '@mui/icons-material/Flag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import GoogleCaptcha from '../../Components/GoogleCaptcha';
import CustomButton from '../../Components/Button';

const Contact = () => {
    const navigate = useNavigate();

    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 1024, // Increase the value for tablet width
          lg: 1280,
          xl: 1920
        },
      },
    });

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container style={{ padding: '16px', position:'a',paddingTop:'80px',paddingBottom:'80px'}}>
            <Grid container>
                {/* <Grid item xs={18} sm={15} md={18}> */}
                    <Box sx={{ mt:1, backgroundColor: '#e9e9e999', padding: '20px 20px 140px 20px' , borderRadius: '5px' }}>
                      <Typography variant="h5" gutterBottom>Contact Form</Typography>
                        <Box sx={{border:'1px solid grey'}}></Box>
                        <Box sx={{mt:2}}></Box>
                                <Box sx={{mt:1}}>
                                        <Typography variant="body1"> Please complete this form, and a business solutions representative from your area will be in touch to set up an appointment or provide more info.</Typography>
                                        <Typography variant="body1"><span style={{fontWeight: 'bold'}}>Want to speak with someone now?</span></Typography>
                                        <Typography variant="body2">You can always call us toll-free at</Typography><Box sx={{mt:2}}></Box>
                                        <Box sx={{mt:2}}></Box>
                                        <Typography variant="body1"><span style={{fontWeight: 'bold'}}> 855-770-MOTO (6686), Option 3</span></Typography>


                                </Box>

                             <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField label="Business Name" placeholder="Enter your Business Name" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                       InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                                <IconButton sx={{marginLeft:'30%'}}><AccountCircle /></IconButton>
                                          </InputAdornment>
                                        ),
                                      }}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField 
                                    label="FirstName" placeholder="Enter your username" margin="normal" variant="outlined" fullWidth className="textfield" size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                              <IconButton sx={{marginLeft:'30%'}}><AccountCircle /></IconButton>
                                        </InputAdornment>
                                      ),
                                    }}/>   
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField 
                                      label="LastName" placeholder="Enter your lastname" margin="normal"  variant="outlined" fullWidth className="textfield" size="small"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                                <IconButton sx={{marginLeft:'30%'}}><AccountCircle /></IconButton>
                                          </InputAdornment>
                                        ),
                                      }}/>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField 
                                   label="PhoneNumber" placeholder="Enter your PhoneNumber" margin="normal"  variant="outlined" fullWidth className="textfield" size="small"
                                    InputProps={{
                                     endAdornment: (
                                      <InputAdornment position="end">
                                         <IconButton sx={{marginLeft:'30%'}}><PhoneIcon /></IconButton>
                                      </InputAdornment>
                                     ),
                                   }}/>  
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField 
                                       label="Email Address" placeholder="Enter your Email Address" margin="normal" fullWidth variant="outlined"   className="textfield" size="small"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                                  <IconButton sx={{marginLeft:'30%'}}><EmailIcon /></IconButton>
                                            </InputAdornment>
                                          ),
                                        }}/>
                                    <Typography variant="body2" sx={{color:'#746e75'}}> We'll never share your email with anyone else.</Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                 <TextField 
                                   label="State/Province" placeholder="State/Province" margin="normal"  variant="outlined" fullWidth className="textfield" size="small"
                                    InputProps={{
                                     endAdornment: (
                                      <InputAdornment position="end">
                                         <IconButton sx={{marginLeft:'30%'}}><FlagIcon /></IconButton>
                                      </InputAdornment>
                                     ),
                                   }}/> 
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField 
                                   label="Zip Code" placeholder="Zip Code" margin="normal"  variant="outlined" fullWidth className="textfield" size="small"
                                    InputProps={{
                                     endAdornment: (
                                      <InputAdornment position="end">
                                         <IconButton sx={{marginLeft:'30%'}}><FontAwesomeIcon icon={faMapPin} /></IconButton>
                                      </InputAdornment>
                                     ),
                                   }}/> 
                                  </Grid>
                             

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                 <TextField 
                                   label="Country" placeholder="Country" margin="normal"  variant="outlined" fullWidth className="textfield" size="small"
                                    InputProps={{
                                     endAdornment: (
                                      <InputAdornment position="end">
                                         <IconButton sx={{marginLeft:'30%'}}><FlagIcon /></IconButton>
                                      </InputAdornment>
                                     ),
                                   }}/> 
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField 
                                   label="Best Contact Time / Comments*"  margin="normal"  variant="outlined" fullWidth className="textfield" size="large" multiline rows={5}/>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}><GoogleCaptcha />
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={6} lg={6} sx={{textAlign: isMobile ? 'center' : 'right'}}>
                                  <CustomButton label="Proceed" sx={{marginTop: '30px'}} />

                                </Grid>
                          </Grid>
                    </Box>
            </Grid>
        </Container>
    );
};

export default Contact;
