import React, {useState, useEffect } from 'react';
import { Container, Grid, } from '@mui/material';
import ModalSubscribe from '../../Home/Subscribe';


const Subscribe = ({ open, onClose, }) => {
    
    const [isSignupChooseModalOpen, setSignupChooseModalOpen] = useState(open);
    const [isActivateAccountModalOpen, setActivateAccountModalOpen] = useState(false);
    const [isSubscribeModalOpen, setSubscribeModalOpen] = useState(true);

    
    useEffect(() => {
        setSignupChooseModalOpen(open);
      }, [open]);

    const handleActivateAccountModal = () => {
        setSignupChooseModalOpen(false);
        setActivateAccountModalOpen(true);
    };

    const handleSignupChooseModalClose = () => {
        onClose();
        setSignupChooseModalOpen(false);
    };

    const handleActivateAccountModalClose = () => {
        setActivateAccountModalOpen(false);
        onClose();
        setSignupChooseModalOpen(true);
    };

    const handleSignUpClick = (type) => {
        setSubscribeModalOpen(true);
        console.log('Clicked image type:', type);
    };  

    //for Subscribe Modal
    const handleSubscribeModal = () => {
        onClose();
        setSignupChooseModalOpen(false);
        setSubscribeModalOpen((prev) => !prev);
    };


   
  return (
    <>
    <Container className='MobileSubscribe' style={{ padding: '16px', position:'a',padding:'50px'  }}>
        <Grid container>
            <ModalSubscribe
                open={isSubscribeModalOpen}
                onClose={handleSubscribeModal}
                title="Modal Title"
                content="This is the content of the Modal."
            />
        </Grid>
    </Container>    
    
    
    </>
  );
};

export default Subscribe;