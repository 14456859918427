import React, { useState } from 'react';
import { Grid, Box, Typography, TextField, List, createTheme, useMediaQuery, ListItem,FormControl, } from "@mui/material";
import Button from "@mui/material/Button";
import '../Settings/Settings.css';
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import CustomButton from '../../../../Components/Button';

const Vleinstructions = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor:
        theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
      border: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
      fontSize: 16,
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      fontFamily: [].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm","lg"));
  
  let setGridPosition = {};
  const setGridPos ={
    padding: "2%",
    paddingLeft:'20%', 
    paddingTop: '5%',
    paddingRight: '20%', 
    width: '100%'
  }

  const setGridPosMobile ={
    paddingLeft:'5%', 
    paddingTop: '55px',
    paddingRight: '5%',
    paddingBottom: '30%', 
    width: '100%'
  }

  if(isMobile || isTablet){
    setGridPosition = setGridPosMobile ;
  }
  else{
    setGridPosition = setGridPos;
  }

  return (
    <Grid sx={setGridPosition}>
      <Grid container>
        <Box className='Apidetails'>
          <Typography variant="h5" gutterBottom>VLE Instructions</Typography>
          <Box sx={{ border: "1px solid grey" }}></Box>
          <Box sx={{ mt: 2 }}></Box>
          <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>How to use VLE address</Typography>
          </Grid>
          <List>
            <ListItem>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={ isMobile || isTablet ? (null):("marginLeft:'15%',")}>
                  <Typography variant="h6" gutterBottom>
                    Please enter the full URL of the page the student will go to in order to access MotoVisuals in your Virtual Learning Environment.
                  </Typography>
                  <FormControl fullWidth={true} variant="standard" >
                    <BootstrapInput  defaultValue="MotoVisuals" id="bootstrap-input" />
                  </FormControl>
                  <CustomButton label="save"  sx={{marginLeft: '2%', marginTop: '3px', float:'right'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={ isMobile || isTablet ? (null):("marginLeft:'15%',")}>
                  <Typography variant="h6" gutterBottom>
                    Please copy below script to above VLE address (motovisuals.com) web page where you want to show the link.
                  </Typography>
                  <TextField id="outlined-multiline-static" multiline rows={5} sx={{ width:'100%' }}
                    defaultValue={`<script type="text/javascript">
                                        function redirectToVV(){
                                          var host=window.location.host;
                                          window.open("https://motovisuals.com/api/v1/getVehicleInstitute/"+host);
                                        }
                                      </script>
                                      <a href="javascript:redirectToVV();">MotoVisuals</a>`} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={12}>
                    <CustomButton label="Copy" sx={{float:'right'}} />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Vleinstructions;