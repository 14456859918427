import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Grid, Typography, Button, Modal } from "@mui/material";
import '../Settings/Settings.css';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { CardActionArea, CardMedia, TextField, InputAdornment, FormControl, useMediaQuery, createTheme } from '@mui/material';
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import CustomButton from '../../../../Components/Button';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (

        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const rows = [
    { id: 1, AnimationID: 'kb', VideoName: '234356' },
    { id: 2, AnimationID: 'Amravati', VideoName: '134354' },
    { id: 3, AnimationID: 'Pune', VideoName: '774353' },
    { id: 4, AnimationID: 'Mk1', VideoName: '894302' },
    { id: 5, AnimationID: 'Mk15', VideoName: '908761' },
    { id: 6, AnimationID: 'Mk16', VideoName: '567800' },
    { id: 7, AnimationID: 'Mk17', VideoName: '431908' },
    { id: 8, AnimationID: 'Mk18', VideoName: '086314' },
    { id: 9, AnimationID: 'Mk19', VideoName: '680759' },
];


export default function PhoneNumbers() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isUploadNewvideoModalOpen, setUploadNewvideoModalOpen] = useState(false);
    const [isUservideoModalOpen, setUservideoModalOpen] = useState(false);
    const [isUservideoEditModalOpen, setUservideoEditModalOpen] = useState(false);
    const [isUservideoDeleteModalOpen, setUservideoDeleteModalOpen] = useState(false);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleUploadNewvideoModalOpen = () => {
        setUploadNewvideoModalOpen(true);
    };

    const handleUservideoModalOpen = () => {
        setUservideoModalOpen(true);
    };

    const handleUserEditvideoModalOpen = () => {
        setUservideoEditModalOpen(true);
    };

    const handleUserDeletevideoModalOpen = () => {
        setUservideoDeleteModalOpen(true);
    };

    const handleCloseModal = () => {
        setUservideoModalOpen(false);
        setUservideoEditModalOpen(false);
        setUservideoDeleteModalOpen(false);
        setUploadNewvideoModalOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "& .MuiInputBase-input": {
            borderRadius: 4,
            position: "relative",
            backgroundColor:
                theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
            border: "1px solid",
            borderColor:
                theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
            fontSize: 16,
            padding: "10px 12px",
            transition: theme.transitions.create([
                "border-color",
                "background-color",
                "box-shadow",
            ]),
            fontFamily: [].join(","),
            "&:focus": {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1920,
          },
        },
      });
      
      
      const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
      const isTablet = useMediaQuery(theme.breakpoints.between("sm","lg"));
      
      let setGridPosition = {};
      const setGridPos ={
        paddingLeft: "20%", 
        paddingTop: "8%", 
        paddingRight: "20%",
        width: "100%",
        justifyContent: "center",
      }
      
      const setGridPosMobile ={
        paddingTop: "15%", 
        paddingRight: "5%",
        paddingLeft: "5%",
        paddingBottom: "30%",
        width: "100%",
        justifyContent: "center",
      }
      
      if(isMobile || isTablet){
        setGridPosition = setGridPosMobile ;
      }
      else{
        setGridPosition = setGridPos;
      }

    return (
        <Grid sx={setGridPosition}>
            <Grid>
                <Grid container>
                    <Box className='manageuser'>
                        <Typography variant="h5" gutterBottom>Upload User Videos</Typography>
                        <Box sx={{ border: "1px solid grey" }}></Box>
                        <Box sx={{ mt: 2 }}></Box>
                        <Grid sx={{ float: 'right' }}>
                            <CustomButton color="primary" onClick={handleUploadNewvideoModalOpen} label="Upload New Videos" />
                        </Grid>
                    </Box>
                </Grid>
                <Box sx={{ mt: 2 }}></Box>
            </Grid>

          {/* Upload New Videos Modal */}

            <Modal open={isUploadNewvideoModalOpen} onClose={handleCloseModal}>
                <Grid className={isMobile ? 'UploadUsereditModalMobile' : isTablet ? 'UploadUsereditModalTablet' : 'UploadUsereditModal'} >
                    <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                            onClick={handleCloseModal}><CloseIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>Upload video</Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ padding: '15px' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <FormControl fullWidth={true} variant="standard" >Video Name *<BootstrapInput  id="bootstrap-input" /></FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Typography variant="h7" gutterBottom>Upload video</Typography>
                            <TextField id="outlined-multiline-static" multiline rows={4} fullWidth/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                            <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Save" />
                            <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Cancel" />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

            <Grid className='manageuser'>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="custom pagination table">
                        <TableBody>
                            <TableRow >
                                <TableCell><b>id</b></TableCell>
                                <TableCell><b>Animation ID</b></TableCell>
                                <TableCell><b>Video Name</b></TableCell>
                                <TableCell><b>Action</b></TableCell>
                            </TableRow>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row) => (
                                <TableRow key={rows}>
                                    <TableCell component="th" scope="row"> {row.id}</TableCell>
                                    <TableCell component="th" scope="row"> {row.VideoName}</TableCell>
                                    <TableCell component="th" scope="row"> {row.AnimationID}</TableCell>
                                    <TableCell ><PlayArrowIcon sx={{ cursor: 'pointer' }} onClick={handleUservideoModalOpen} /><ModeEditIcon sx={{ cursor: 'pointer' }} onClick={handleUserEditvideoModalOpen} /><DeleteIcon sx={{ cursor: 'pointer' }} onClick={handleUserDeletevideoModalOpen} /></TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow sx={{ overflowX:'auto'}}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]} className='pagination'
                                    count={rows.length} rowsPerPage={rowsPerPage} page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
            <Modal open={isUservideoModalOpen} onClose={handleCloseModal}>
                <Grid className={ isMobile? 'PlayUserModalMobile' : 'PlayUserModal'} >
                    <Grid container sx={{ width:'100%', height:'100%' }}>
                        <Grid item xs={12} sm={8} md={8} lg={8} sx={{ width:'100%', height:isMobile ? '50%':'100%'}}>

                                    <iframe src="https://www.youtube.com/embed/EgF01aSQyno?si=lYCF5qQaCv09wmIh" height="100%" width="100%"
                                        frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>

                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{}}>
                            <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px 10px 10px', borderRadius: '5px' }}>
                                <IconButton
                                    sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                                    onClick={handleCloseModal}><CloseIcon />
                                </IconButton>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>Testvideo</Typography>
                            </Grid>
                            <Grid sx={{ height: '280px', padding: '3px' }}>
                                <Typography variant="h7" gutterBottom sx={{ overflowWrap: 'break-word', }}>
                                    <code>
                                        &lt;iframe width="560" height="315" src="https://www.youtube.com/embed/EgF01aSQyno" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen&gt;&lt;/iframe&gt;
                                    </code>
                                </Typography>
                            </Grid>
                            <Grid>
                                <Box sx={{ mt: 3, borderTop: '2px solid #d5d5d5', display: 'flex' }}></Box>
                            </Grid>
                            <Grid sx={{ padding: '15px' }}>
                                <CustomButton color="primary" sx={{ float: 'right' }} label="Copy" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

            {/* Edit Modal */}

            <Modal open={isUservideoEditModalOpen} onClose={handleCloseModal}>
                <Grid className={isMobile ? 'UploadUsereditModalMobile' : isTablet ? 'UploadUsereditModalTablet' : 'UploadUsereditModal'}>
                    <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                            onClick={handleCloseModal}><CloseIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>Upload video</Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ padding: '15px' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <FormControl fullWidth={true}  variant="standard" >Video Name *
                                <BootstrapInput defaultValue="test" id="bootstrap-input" /></FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Typography variant="h7" gutterBottom>Upload video</Typography>
                            <TextField
                                id="outlined-multiline-static" multiline rows={isMobile ? 7 : 4} fullWidth
                                defaultValue={`<iframe width="560" height="315" src="https://www.youtube.com/embed/2-hNM0XhZ3k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                            <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Save" />
                            <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Cancel" />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

            {/*  Delete Modal   */}

            <Modal open={isUservideoDeleteModalOpen} onClose={handleCloseModal}>
                <Grid className={ isMobile ? 'UploadUserDeleteModalMobile' : 'UploadUserDeleteModal'}>
                    <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                            onClick={handleCloseModal}><CloseIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>Please Confirm</Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ padding: '15px' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Typography variant="h7" gutterBottom>Are you sure to delete</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: '0px' }} >
                            <Box sx={{ mt: 3, borderTop: '2px solid #d5d5d5', display: 'flex' }}></Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                            <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Yes" />
                            <CustomButton color="primary" sx={{ marginRight: "5px" }} label="No" />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    );
}