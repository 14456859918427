import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material';
import LeakingPiston from '../../../Assets/Images/LeakingPiston.jpg';
import BrakeFluid from '../../../Assets/Images/901028.jpg';
import ShareIcon from '@mui/icons-material/Share';
import EmailIcon from '@mui/icons-material/Email';
import {theme} from '../../../Components/themeforview';


const History = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isBelowTablet = useMediaQuery(theme.breakpoints.between("sm","tb"));
    const isTablet = useMediaQuery(theme.breakpoints.between("tb","md"));
    const isAboveTablet = useMediaQuery(theme.breakpoints.between("md","lg"));

    return (
        <div>
        <Typography className={isMobile ? 'stickyHeading' : ''}  sx={{textAlign:'center',fontWeight: 'bold'}} gutterBottom variant="h4" component="div">History</Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header" sx={{ backgroundColor: '#ededed' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Narrated</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{paddingLeft:'20px'}}>
                    <Grid container spacing={2} sx={{justifyContent:'center'}}>
                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row', width: '160px', justifyContent:'center' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img"  height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row',width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row',width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>

                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row',width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>

                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row',width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>

                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row',width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row',width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={LeakingPiston} alt="Leaking Piston" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Leaking Piston</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>

                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" sx={{ backgroundColor: '#ededed' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Interactive</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2} sx={{justifyContent:'center'}}>
                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row',width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={BrakeFluid} alt="Brake Fluid" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Brake Fluid</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>

                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row',width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={BrakeFluid} alt="Brake Fluid" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Brake Fluid</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row' ,width: '160px'}}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={BrakeFluid} alt="Brake Fluid" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Brake Fluid</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item md={2} lg={2}>
                            <Card sx={{ display: 'flex', flexDirection: 'row',width: '160px' }}>
                                <CardActionArea className='card12'>
                                    <CardMedia component="img" height="150" image={BrakeFluid} alt="Brake Fluid" />
                                    <CardContent sx={{ textAlign: 'center', backgroundColor: '#ce0034', color: '#FFFFFF' }}>
                                        <Typography gutterBottom variant="h7" component="div">Brake Fluid</Typography>
                                    </CardContent>
                                    <Grid className='details' container sx={{ backgroundColor: '#ce0034', textAlign: 'center' }}>
                                        <Grid item md={6} lg={6}>
                                            <ShareIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                        <Grid item md={6} lg={6}>
                                            <EmailIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default History;
