import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Grid, Typography, FormControlLabel, Button,Modal,TextField,InputAdornment, createTheme, useMediaQuery} from "@mui/material";
import '../Settings/Settings.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Bannerimg from '../../../../Assets/Images/banner_img.png';
import CustomButton from '../../../../Components/Button';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (

        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(email) {
    return { email };
}

const rows = [
    createData('https://www.midas.com/jacksonville-fl/offers.aspx?shopnum=3136&dmanum=428'),
    createData('https://www.desertcarcare.com/appointments?coupon=6'),
    createData('https://www.goodyearautoservice.com/en-US/make-appointment'),
    createData('https://www.goodyearautoservice.com/en-US/make-appointment'),
    createData('https://www.midas.com/jacksonville-fl/offers.aspx?shopnum=3136&dmanum=428'),
    createData('https://www.desertcarcare.com/appointments?coupon=6'),
    createData('https://www.goodyearautoservice.com/en-US/make-appointment'),
    createData('https://www.goodyearautoservice.com/en-US/make-appointment'),
    createData('https://www.midas.com/jacksonville-fl/offers.aspx?shopnum=3136&dmanum=428'),
    createData('https://www.desertcarcare.com/appointments?coupon=6'),
    createData('https://www.goodyearautoservice.com/en-US/make-appointment'),
    createData('https://www.goodyearautoservice.com/en-US/make-appointment'),
];

export default function CustomPaginationActionsTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isEmailModalOpen, setEmailModalOpen] = useState(false);
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);


    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleEmailOpenModal = () => {
        setEmailModalOpen(true);
      };
    
      const handleEmailCloseModal = () => {
        setEmailModalOpen(false);
      };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [selectedEmail, setSelectedEmail] = React.useState(""); 
    const handleRadioChange = (event, email) => {
        setSelectedEmail(email);
    };
    const handleuploadOpenModal = () => {
        setUploadModalOpen(true);
    };
    const handleuploadCloseModal = () => {
        setUploadModalOpen(false);
    };

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1920,
          },
        },
      });
      
      
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));
    
    return (
        <Grid sx={{ width: '100%', justifyContent: 'center' }}>
            <Grid>
                <Grid container>
                    <Box className='emaildetails'>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: "left" }} onClick={handleuploadOpenModal}>
                        <IconButton><CameraAltIcon />
                            <Typography gutterbottom='h6'>Upload Logo</Typography></IconButton>
                    </Grid>
                    </Box>
                </Grid>
                <Modal open={isUploadModalOpen} onClose={handleuploadCloseModal}>
                    <Grid className={ isMobile ? 'UploadlogoModalMobile' : isTablet? 'UploadlogoModalTablet' : 'UploadlogoModal'}>
                        <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                            <IconButton
                                sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                                onClick={handleuploadCloseModal}><CloseIcon />
                            </IconButton>
                            <Typography variant="h5" gutterBottom>Upload Logo</Typography>
                        </Grid>
                        <Grid container spacing={2} sx={{ padding: '15px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField placeholder="URL For When Logo is Clicked" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                                            </InputAdornment>),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="file" placeholder="Drop files here or click to upload"
                                    margin="normal" fullWidth variant="outlined" className="textfield" size="large" multiline rows={isMobile ? 6 : isTablet ? 4 : 5} />
                                <input id="logoUploadInput" type="file" style={{ display: 'none' }}
                                    onChange={(e) => {
                                        const selectedFile = e.target.files[0];
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                                <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Submit" />
                                <CustomButton color="primary" sx={{ marginRight: "5px" }} onClick={handleuploadCloseModal} label="Cancel" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
                <Box sx={{ mt: 2 }}></Box>
            </Grid >
            <Box className='emaildetailsbanner'>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="custom pagination table">
                        <TableBody>
                            <TableRow>
                                <TableCell align="left"><b>Default</b></TableCell>
                                <TableCell ><b>Banner</b></TableCell>
                                <TableCell ><b>Banner URL</b></TableCell>
                                <TableCell align="right"><b>Action</b></TableCell>
                                <TableCell></TableCell>
                               
                            </TableRow>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row) => (
                                <TableRow key={row.email}>
                                    <TableCell align="left">
                                    <RadioGroup aria-label="select-email" name="select-email"
                                        value={selectedEmail === row.email ? row.email : ""} onChange={(event) => handleRadioChange(event, row.email)} >
                                        <FormControlLabel value={row.email} control={<Radio />}/></RadioGroup>
                                    </TableCell>
                                    <TableCell component="th" scope="row"><img src={Bannerimg} alt="Banner Image" width="100" height="60" /></TableCell>
                                    <TableCell component="th" scope="row"> {row.email}</TableCell>
                                    <TableCell align="right"><ModeEditIcon /><DeleteIcon /></TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]} colSpan={3}
                                    count={rows.length} rowsPerPage={rowsPerPage} page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
        </Grid>
    );
}