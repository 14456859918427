import React from 'react';
import "vidstack/styles/base.css";
import "vidstack/styles/ui/buttons.css";
import "vidstack/styles/ui/sliders.css";
import 'vidstack/define/media-player.js';
import 'vidstack/styles/defaults.css'
import 'vidstack/styles/community-skin/video.css'
import { defineCustomElements } from 'vidstack/elements';
import { MediaPlayer, MediaOutlet } from "@vidstack/react";
import srt from '../../../Assets/Videos/Fluid_Maintenance_Generic_AV_US_LHD.vtt';

defineCustomElements();
function Player() {

  const videoUrl = "https://cdn.vehiclevisuals.com/Video/US/Fluid_Maintenance_Generic_AV_US_LHD.mp4";
  const videoUrlVod = "https://cdn.vehiclevisuals.com/vod/Video/US/Fluid_Maintenance_Generic_AV_US_LHD_480.mp4";
  const videoUrlVodDASH = "https://cdn.vehiclevisuals.com/Video/US/Fluid_Maintenance_Generic_AV_US_LHD/dash.mpd";
  const videoUrlVodHLS = "https://cdn.vehiclevisuals.com/Video/US/Fluid_Maintenance_Generic_AV_US_LHD/hls.m3u8";
  // src="https://media-files.vidstack.io/sprite-fight/subs/english.vtt"

  return (
    <media-player
      title="Sprite Fight"
      src="https://cdn.vehiclevisuals.com/Video/US/Fluid_Maintenance_Generic_AV_US_LHD.mp4"
      // src="https://cdn.motovisuals.com/Video/US/Brake_Master_Cylinder_Generic_AV_US/hls.m3u8"
      // src="https://cdn.vehiclevisuals.com/js/bradmax_player.js?v=27092021"
      // src="https://stream.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/low.mp4"
      // src="https://cdn.vehiclevisuals.com/Video/Interactive/Plug_in_Hybrid_Electric_Vehicle_Interactive_LHD.mp4"
      // src="https://cdn.motovisuals.com/Video/US/Brake_Master_Cylinder_Generic_AV_US.mp4"
      poster="LeakingPiston"
      thumbnails="https://cdn.vehiclevisuals.com/banners/905001.jpg"
      aspect-ratio="16/9"
      crossorigin>
      <media-outlet>
        <media-poster></media-poster>
        <track
          // src={srt}
          // src="https://media-files.vidstack.io/sprite-fight/subs/english.vtt"
          src="https://cdn.vehiclevisuals.com/captions/905001/Fluid_Maintenance_Generic_AV_US_LHD.srt"
          // src="https://cdn.motovisuals.com/captions/902516/BrakeMasterCylinderAVUS_23_5_2016.srt"
          label="English"
          srclang="en-US"
          kind="subtitles"
          data-type="srt"
          default
        />
        <track
          src={srt}
          srclang="en-US"
          kind="chapters"
          loop="true"
          default />
      </media-outlet>
      <media-community-skin></media-community-skin>
    </media-player>
  );
}
export default Player;
