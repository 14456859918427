import React from 'react';
import { Grid, Box, Typography, useMediaQuery, createTheme, TextField,} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import { InputAdornment } from '@mui/material';
import '../Settings/Settings.css';
import CustomButton from '../../../../Components/Button';
import '../../../RTL.css';



const Apikey = () => {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  
  
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));
  
  let setGridPosition = {};
  const setGridPos ={
    paddingLeft: "0%", 
    paddingTop: "8%", 
    paddingRight: "20%",
    width: "100%",
  }
  
  const setGridPosMobile ={
    paddingTop: "30%", 
    paddingRight: "5%",
    paddingLeft: "5%",
    paddingBottom: "30%",
    width:'100%',
  }
  
  if(isMobile || isTablet){
    setGridPosition = setGridPosMobile ;
  }
  else{
    setGridPosition = setGridPos;
  }

  return (
    <Grid className={`${isMobile || isTablet ? 'apicontainerMobile':'apicontainer'}`}>
      <Grid container sx={setGridPosition}>
        <Box className='Apidetails'>
          <Typography variant="h5" gutterBottom>Manage API Key</Typography>
          <Box sx={{ border: "1px solid grey" }}></Box>
          <Box sx={{ mt: 2 }}></Box>

          <Grid container spacing={3} >
          <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField label="Api Key" placeholder="Enter your New UserName" fullWidth margin="normal" variant="outlined" className="textfield" size="small" 
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ }}></IconButton>
                      </InputAdornment>),
                  }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField label="Description" placeholder="Enter the name of the application this API key will be used with -e.g the name of the management system or DVI system"
                 margin="normal" fullWidth variant="outlined" className="textfield" size="large" multiline rows={2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{}}></IconButton>
                      </InputAdornment>),
                  }} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
            
            <CustomButton color="primary" sx={{ marginTop: "30px",marginRight: "5px"}} label="Cancel" />    
            <CustomButton color="primary" sx={{ marginTop: "30px",marginRight: "5px"}} label="Save" />                
          </Grid> 
         
        </Box>
        
      </Grid>
      
    </Grid>
    
  );
};

export default Apikey;