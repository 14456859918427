import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions,createTheme,useMediaQuery, Autocomplete, Button, Box, Typography, Tabs, Tab, Grid, TextField, IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import HelpIcon from '@mui/icons-material/Help';
import LinkIcon from '@mui/icons-material/Link';
import MenuItem from '@mui/material/MenuItem';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import brakesystem from '../../../Assets/Images/BrakeSystem.png';
import leakingpiston from '../../../Assets/Images/LeakingPiston.jpg';
import homelogo from '../../../Assets/Images/logo_home.png';
import CustomButton from '../../../Components/Button';
import CloseIcon from '@mui/icons-material/Close';
import '../../RTL.css';


const Email = ({ open, onClose }) => {
  const [selectedTab, setSelectedTab] = useState('Text Messages');
  const [message, setMessage] = useState('');
  const editorRef = useRef(null);
  const [characterCount, setCharacterCount] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // function extractTextFromQuillValue(quillValue) {
  //   const delta = quillValue?.ops || [];
  //   return delta
  //     .map((op) => (typeof op.insert === 'string' ? op.insert : ''))
  //     .join('');
  // }

  const options = [

    { value: 20, label: 'Manual Drivetrain' },
    { value: 30, label: 'Axles & Driveline' },
    { value: 40, label: 'Body' },
    { value: 50, label: 'Brake system' },
    { value: 60, label: 'cooling system' }, { value: 70, label: 'Engine' }, { value: 80, label: 'Tire' }, { value: 90, label: 'Winter Check' },
  ];

  const buttonStyle = {
    backgroundColor: 'rgb(220, 0, 55)',
    color: 'white',
  };



  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      const text = editor.getText();
      setCharacterCount(text.length);
    }
  }, [message]);

  const handleMessageChange = (value) => {
    setMessage(value);
  };

  const handleTextChange = () => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      const text = editor.getText();
      setCharacterCount(text.length);
    }
  };

  const fontSizeOptions = [
    '8px', '10px', '12px', '14px', '18px', '24px', '36px'
  ];
  
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        tb: 768,
        md: 1024,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm","tb"));
    
  let setGridPosition = {};
  const setGridPos ={
    paddingLeft: "10%", 
    paddingTop: "0%", 
    paddingRight: "10%",
    width: "80%!important",
    height:'100%!important',
    justifyContent: "center",
    margin:'0px!important'
  }
    
  const setGridPosMobile ={
    paddingLeft: "0%", 
    paddingTop: "0%", 
    paddingBottom: "0%", 
    paddingRight: "0%",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    margin:'0px',
  }
    
  if(isMobile || isTablet){
    setGridPosition = setGridPosMobile ;
  }
  else{
    setGridPosition = setGridPos;
  }



  return (
    <Dialog maxWidth sx={setGridPosition} open={open} onClose={onClose} >

      <DialogTitle sx={{ fontWeight: 'bold', backgroundColor: '#e9e9e999' }}>Email An Animation</DialogTitle>
      <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                            onClick={onClose}><CloseIcon />
                        </IconButton>
      <Box sx={{ border: '1px solid grey' }}></Box>
      <Tabs variant='scrollable' scrollButtons allowScrollButtonsMobile value={selectedTab} onChange={handleTabChange}>
        <Tab label="E-Mail" value="E-Mail" />
        <Tab label="Text Messages" value="Text Messages" />
        <Tab label="Marketing Promotion Link" value="Marketing Promotion Link" />
      </Tabs>
      <Box sx={{ border: '1px solid grey' }}></Box>
      <form>
        <DialogContent className='selectedtab'>
          {/* Email content starts here */}
          {selectedTab === 'E-Mail' && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField fullWidth margin="normal" variant="outlined" size="small" label="From E-Mail"
                  select
                  SelectProps={{
                    labelId: 'demo-simple-select-label',
                    id: 'demo-simple-select',
                  }}>
                  <MenuItem value={10}>test@exert.com</MenuItem>
                  <MenuItem value={20}>moto@mail.com</MenuItem>
                  <MenuItem value={30}>service@mail.com</MenuItem>
                  <MenuItem value={40}>visuals@mail.com</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField label="Work Order" placeholder="Enter your Work Order" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                      </InputAdornment>
                    ),
                  }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField label="To E-Mail" placeholder="E-Mail" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                      </InputAdornment>),
                  }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField label="Subject" placeholder="Subject" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                      </InputAdornment>),
                  }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Autocomplete
                  fullWidth margin="normal" options={options}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Select System" variant="outlined" />
                  )} />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControlLabel control={<Checkbox color="primary" />} sx={{ marginTop: '5px' }} label="Display Related Animations" />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormLabel>Select Message:</FormLabel>
                    <TextField label="Subject" margin="normal" variant="outlined" className="textfield" size="small"
                      select
                      SelectProps={{
                        labelId: 'demo-simple-select-label',
                        id: 'demo-simple-select',
                      }}
                      sx={{ flex: 1 }}>
                      <MenuItem value={10}>Msg-1</MenuItem>
                      <MenuItem value={20}>Msg-2</MenuItem>
                      <MenuItem value={30}>Msg-3</MenuItem>
                    </TextField>
                  </div>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group">
                    <FormControlLabel value="User Defined Messages" control={<Radio />} label="User Defined Messages" />
                    <FormControlLabel value="System Defined Messages" control={<Radio />} label="System Defined Messages" />
                  </RadioGroup>

                  <ReactQuill
                    ref={editorRef} value={message} onChange={handleMessageChange} onSelectionChange={handleTextChange}
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline'],
                      ],
                    }}
                    formats={['bold', 'italic', 'underline', 'font']} />

                  <Typography variant="caption" color="textSecondary" align="right">
                    {`${characterCount}/1400`}
                  </Typography>

                  <Grid item xs={12}>
                    <Typography gutterBottom variant="body2">The SMS / Text Message system relies on external services for message delivery. If you encounter any problems, please contact our customer support team</Typography>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ border: '1px solid grey' }}></Box>
              </Grid>
              <Grid sx={{ paddingLeft: '15px' }}>
                <Typography gutterBottom variant='body1'>Select a Footer Image:</Typography>

                <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid item>
                    <img src={homelogo} alt="login_logo" style={{ maxWidth: "100%", }} />
                  </Grid>
                  <Grid item>
                    <img src={leakingpiston} alt="brakesystem" style={{ maxWidth: "100%", height: "auto" }} />                    </Grid>
                  <Grid item>
                    <img src={brakesystem} alt="brakesystem" style={{ maxWidth: "100%", height: "auto" }} />                    </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <CustomButton label="Send Email" />
                </Grid>
                <Grid item>
                  <CustomButton label="Send By Outlook" />
                </Grid>
                <Grid item>
                  <CustomButton variant="outlined" label="Cancel" />
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Text Message content starts here */}

          {selectedTab === 'Text Messages' && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField label="From E-Mail" placeholder="Enter your E-Mail" margin="normal" fullWidth variant="outlined" className="textfield" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField label="Work Order" placeholder="Enter your Work Order" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ marginLeft: '30%' }}></IconButton>
                      </InputAdornment>
                    ),
                  }} />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Autocomplete
                  fullWidth margin="normal" options={options}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Select System" variant="outlined" />
                  )} />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControlLabel control={<Checkbox color="primary" />} sx={{ marginTop: '5px' }} label="Display Related Animations" />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}></Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl>
                  <FormLabel>Select Message:</FormLabel>
                  <RadioGroup
                    row aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group">
                    <FormControlLabel value="User Defined Messages" control={<Radio />} label="User Defined Messages" />
                    <FormControlLabel value="System Defined Messages" control={<Radio />} label="System Defined Messages" />
                  </RadioGroup>
                  <TextField
                    variant="filled" fullWidth className="textfield" multiline
                    rows={4} value={message} onChange={handleMessageChange} />

                  <Typography variant="caption" color="textSecondary" align="right">
                    {`${characterCount}/1400`}
                  </Typography>

                  <Grid item xs={12}>
                    <Typography gutterBottom variant="body2">The SMS / Text Message system relies on external services for message delivery. If you encounter any problems, please contact our customer support team</Typography>
                  </Grid>

                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ border: '1px solid grey' }}></Box>
              </Grid>

              <Grid>
                <Grid sx={{ marginTop: '10px', marginLeft: '5px' }}>
                  <FormControlLabel control={<Checkbox color="primary" />} label="The recipient of this text message has given verbal or written consent to receive text messages regarding their vehicle repair." />
                  <Tooltip placement="bottom-end" width="20px" title="Our SMS provider and many telecoms carriers require that messages are only sent to customers that have opted in to receiving messages. The opt-in process can be as simple as asking your customer 'Is it OK if we text you a video explaining the service?'. Please do not use this system to send large numbers of marketing messages to people on a customer list. This practice results in our sending number being flagged as a spam number, and messages then go undelivered. Please reach out to us if you need help finding a method for SMS marketing." arrow><HelpIcon /></Tooltip>
                </Grid>
                <Grid>
                  <Typography gutterBottom variant="body1">opt-in-Date:</Typography><Box></Box>
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <CustomButton variant="outlined" label="Cancel" />
                </Grid>
              </Grid>

            </Grid>
          )}
          {selectedTab === 'Marketing Promotion Link' && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Link Expire In"
                  select
                  SelectProps={{
                    labelId: 'demo-simple-select-label',
                    id: 'demo-simple-select',
                  }}>
                  <MenuItem value={10}>7 Days</MenuItem>
                  <MenuItem value={20}>15 Days</MenuItem>
                  <MenuItem value={30}>1 Month</MenuItem>
                  <MenuItem value={40}>3 Month</MenuItem>
                  <MenuItem value={50}>6 Month</MenuItem>
                  <MenuItem value={60}>12 Month</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField label="Where will this link be used?" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ marginLeft: '30%' }}><Tooltip placement="bottom-end" title="If you are placing this link inside a web page or on your Facebook page, please enter the address here. Leave the box empty if you are sending the link in a marketing email." arrow><HelpIcon /></Tooltip></IconButton>
                      </InputAdornment>
                    ),
                  }} />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField label="Call To Action Text" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ marginLeft: '30%' }}><Tooltip placement="bottom-end" title="Enter text here that will be shown under the video you send to your customer , e.g.Click here to schedule an appointment" arrow><HelpIcon /></Tooltip></IconButton>
                      </InputAdornment>
                    ),
                  }} />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField label="Call to Action text color" type='color' margin="normal" fullWidth variant="outlined" className="textfield" size="small" />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField label="Call To Action Url" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ marginLeft: '30%' }}><Tooltip placement="bottom-end" title="Enter the web address that the coustomer will be directed to when clicking the link, e.g. https://mygarage.appointmentbooking.com;" arrow><HelpIcon /></Tooltip></IconButton>
                      </InputAdornment>
                    ),
                  }} />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} >
                <Typography sx={{ marginTop: '24px' }} variant="body2">Preview:</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Autocomplete
                  fullWidth margin="normal" options={options}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Select System" variant="outlined" />
                  )} />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', alignItems: 'center' }} >
                <Typography variant="body2" ><LinkIcon /> Promotional Link:</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Grid container spacing={1}>
                  <Grid item>
                    <CustomButton variant="contained" label="Generate Link" />
                  </Grid>
                  <Grid item>
                  <CustomButton variant="contained" label="Copy Link" />
                  </Grid>
                  <Grid item>
                  <CustomButton variant="contained" label="Previously Generated Links" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ border: '1px solid grey' }}></Box>
              </Grid>

              <Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }} spacing={1}>
                <Grid item>
                <CustomButton variant="outlined" label="Cancel" />
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>

      </form>
    </Dialog>
  );
};

export default Email;
