import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const GoogleCaptcha = () => {
  const handleCaptchaChange = (value) => {
    // This function will be called when the user completes the reCAPTCHA
    console.log("reCAPTCHA value:", value);
  };

  return (
      <ReCAPTCHA
        sitekey="YOUR_RECAPTCHA_SITE_KEY"
        onChange={handleCaptchaChange}
      />
  );
};

export default GoogleCaptcha;
