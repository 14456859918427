import React, { useState } from 'react';
import {  DialogTitle, DialogContent,  useMediaQuery, createTheme,  Box, Tabs, Tab, Grid } from '@mui/material';
import EmailAdressSettings from './Email Address settings';
import EmailBannerSettings from './Email_Banner_Settings';
import EmailContentSettings from './Email_Content_Settings';

const Email_Tabs = () => {
  const [selectedTab, setSelectedTab] = useState('Email Address Settings');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
});


const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));

let setGridPosition = {};
const setGridPos ={
  paddingLeft: "10%", 
  paddingTop: "8%", 
  paddingRight: "10%",
  paddingBottom: "20%",
  width: "100%",
}

const setGridPosMobile ={
  paddingTop: "30%", 
  paddingRight: "5%",
  paddingLeft: "5%",
  paddingBottom: "30%",
  width:'100%',
}

if(isMobile || isTablet){
  setGridPosition = setGridPosMobile ;
}
else{
  setGridPosition = setGridPos;
}

  return (
    <Grid sx={setGridPosition} >
      <DialogTitle sx={{ fontWeight: 'bold', backgroundColor: '#e9e9e999' }}>Email Settings</DialogTitle>
      <Box sx={{ border: '1px solid grey' }}></Box>
      <Tabs variant='scrollable' scrollButtons allowScrollButtonsMobile value={selectedTab} onChange={handleTabChange}>
        <Tab label="Email Address Settings" value="Email Address Settings" />
        <Tab label="Email Content Settings" value="Email Content Settings" />
        <Tab label="Email Banner Settings" value="Email Banner Settings" />
      </Tabs>
      <Box sx={{ border: '1px solid grey' }}></Box>
      <form>
        <DialogContent sx={{ padding:isMobile ? '5px!important': '',}} className='selectedtab'>
          {selectedTab === 'Email Address Settings' && (
            <EmailAdressSettings/>
          )}
          {selectedTab === 'Email Content Settings' && (
            <EmailContentSettings/>
          )}
          {selectedTab === 'Email Banner Settings' && (
            <EmailBannerSettings/>
          )}
        </DialogContent>
      </form>
    </Grid>
  );
};

export default Email_Tabs;