import React, {useState} from 'react';
import { Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, Radio, RadioGroup, FormControl, FormLabel,  Select, MenuItem, FormControlLabel, Checkbox, Stepper, Step, StepLabel, IconButton, InputAdornment } from '@mui/material';
import { Label } from '@mui/icons-material';
import Countrycodeintl from '../../Components/Countrycodeintl';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email'; 
import PhoneIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import PinIcon from '@mui/icons-material/Pin';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import visaIcon from '../../Assets/Images/default/visa.png';
import GoogleCaptcha from '../../Components/GoogleCaptcha';
import ModalOneTimePin from '../../pages/Home/OneTimePin';


const useStyles = {
    textField: {
      margin: 'normal',
      marginTop: '0px',
    },
    gridItem: {
        marginTop: '0px important',
    },
    dialogTitle: {
      background: 'rgb(228, 228, 228)',
      color: '#000',
    },
    closeButton: {
      cursor: 'pointer',
    },
    gridItemBillDets:{
      height: '40px',
      borderRadius:'20px',

    }
  };

const steps = ['Business Information', 'Process Payment'];

const Subscribe = ({ open, onClose, title, onConfirm }) => {

  const [mobileNumber, setMobileNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [billEmail, setBillEmail] = React.useState('');
 



  const [countryCode, setCountryCode] = React.useState(''); // Selected country code state
  const [billCountryCode, setBillCountryCode] = React.useState(''); // Selected country code state
  const [activeStep, setActiveStep] = useState(0);
  const [subscription, setSubscription] = useState(''); 
  const [isOneTimePinModalOpen, setOneTimePinModalOpen] = useState(false);
  const [isSubscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const [isCardExpiryMonth, setCardExpiryMonth] = useState(false);
  const [isCardExpiryYear, setCardExpiryYear] = useState(false);
 // const [expirationYear, setExpirationYear] = useState('');

  const countries = [
    { code: 'USA', countryCode: 'United States' },
    { code: 'CAN', countryCode: 'Canada' },
    { code: 'UK', countryCode: 'United Kingdom' },
    // Add more countries as needed
  ];

  const cardExpiration = [
    { monthValue: '01', monthData: 'Jan' },
    { monthValue: '02', monthData: 'Feb' },
    { monthValue: '03', monthData: 'Mar' },
    { monthValue: '04', monthData: 'Apr' },
    { monthValue: '05', monthData: 'May' },
    { monthValue: '06', monthData: 'Jun' },
    { monthValue: '07', monthData: 'Jul' },
    { monthValue: '08', monthData: 'Aug' },
    { monthValue: '09', monthData: 'Sep' },
    { monthValue: '10', monthData: 'Oct' },
    { monthValue: '11', monthData: 'Nav' },
    { monthValue: '12', monthData: 'Dec' },
    // Add more countries as needed
  ];

  const handleCardExpiryMonth =(event) =>{
    setCardExpiryMonth(event.target.value);
  }

  const handleCardExpiryYear =(event) =>{
    setCardExpiryYear(event.target.value);
  }

  const yearMax = '2038';
  const currentYear = new Date().getFullYear();
  var i;
  var expirationYear = [];
  for(i=currentYear;i<=yearMax;i++){
    expirationYear.push({
      Year:i
    })
  }

  

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };
  

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  
  const handleBillEmailChange = (event) => {
    setBillEmail(event.target.value);
  };

  const [formData, setFormData] = useState({
    businessName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    aap_buy_from: '',
    aap_accountno: '',
    first_name: '',
    last_name: '',
    job_title: '',
    max_location: '',
    plan_id: '',
    promo_code: '',
    mobile_otp: '',
    iaccept: '',
    same_as_primary_contact: '',
    bill_first_name: '',
    bill_last_name: '',
    bill_addres1: '',
    bill_addres2: '',
    bill_city: '',
    bill_state: '',
    bill_country: '',
    bill_zip: '',
    bill_email: '',
    bill_phone: '',
    card_number: '',
    name_on_card: '',
    card_expiry_month: '',
    card_expiry_year: '',
    card_cvv: '',
    card_mobile_otp: '',
});

  const handleConfirm = () => {
    // Handle form submission here
    // You can pass the form data to the onConfirm callback or perform any other action
    const dataToSubmit = {
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      state: formData.state,
      zip:formData.zip,
      country:formData.country,
      email: formData.email,
      mobileNumber: formData.mobileNumber,
      aap_accountno: formData.aap_accountno,
      first_name: formData.first_name,
      last_name: formData.last_name,
      job_title: formData.job_title,
      max_location: formData.max_location,
      plan_id: formData.plan_id,
      promo_code: formData.promo_code,
      mobile_otp: formData.mobile_otp,
      iaccept: formData.iaccept,
      same_as_primary_contact: formData.same_as_primary_contact,
      bill_first_name: formData.bill_first_name,
      bill_last_name: formData.bill_last_name,
      bill_addres1: formData.bill_addres1,
      bill_addres2: formData.bill_addres2,
      bill_city: formData.bill_city,
      bill_state: formData.bill_state,
      bill_country: formData.bill_country,
      bill_zip: formData.bill_zip,
      bill_email: formData.bill_email,
      bill_phone: formData.bill_phone,
      card_number: formData.card_number,
      name_on_card: formData.name_on_card,
      card_expiry_month: formData.card_expiry_month,
      card_expiry_year: formData.card_expiry_year,
      card_cvv: formData.card_cvv,
      card_mobile_otp: formData.card_mobile_otp
    };
    onConfirm(dataToSubmit);
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? '' : `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`,
    }));

    console.log('Changing field:', name, 'to value:', value);
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleBillCountryCodeChange = (event) => {
    setBillCountryCode(event.target.value);
  };

  const [errors, setErrors] = useState({});

  const handleNext = () => {
    const newErrors = {};
    if (formData.businessName === '') {
      newErrors.businessName = 'Business Name is required.';
    }if (formData.address1 === '') {
      newErrors.address1 = 'Address 1 is required.';
    }if (formData.address2 === '') {
      newErrors.address2 = 'Address 2 is required.';
    }if (formData.city === '') {
      newErrors.city = 'City is required.';
    }if (formData.address1 === '') {
      newErrors.state = 'State is required.';
    }if (formData.zip === '') {
      newErrors.zip = 'Zip is required.';
    }if (formData.country === '') {
      newErrors.country = 'Country is required.';
    }if (formData.email === '') {
      newErrors.email = 'Email is required.';
    }if (formData.mobileNumber === '') {
      newErrors.mobileNumber = 'Mobile Number is required.';
    }if (formData.aap_accountno === '') {
      newErrors.aap_accountno = 'AAC Account Number is required.';
    }if (formData.first_name === '') {
      newErrors.first_name = 'First Name is required.';
    }if (formData.last_name === '') {
      newErrors.last_name = 'Last Name is required.';
    }if (formData.job_title === '') {
      newErrors.job_title = 'Job Title is required.';
    }if (formData.max_location === '') {
      newErrors.max_location = 'Max Location is required.';
    }if (formData.plan_id === '') {
      newErrors.plan_id = 'Subscription Plan is required.';
    }if (formData.promo_code === '') {
      newErrors.promo_code = 'Promo Code is required.';
    }if (formData.mobile_otp === '') {
      newErrors.mobile_otp = 'Mobile No for One-Time is required.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Prevent moving to the next step
    }
  
    setActiveStep((prevStep) => prevStep + 1); // Move to the next step
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1); // Move to the previous step
  };

  const handleReset = () => {
    setActiveStep(0); // Reset back to the first step
  };

  const handleSubscriptionChange = (event) => {
    setSubscription(event.target.value);
  };


  //for OneTimePin Modal popup

   //subscribe
   const handleOneTimePinOpenModal = () => {
    setOneTimePinModalOpen(true);
  };

  const handleOneTimePinCloseModal = () => {
    setOneTimePinModalOpen(false);
  };

  return (
    <>
    <Dialog className='MobileSubscribe' open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle className="custom-dialog-title" sx={useStyles.dialogTitle}>
        <b>Sign Up</b>
        <IconButton><CloseIcon onClick={onClose} sx={useStyles.closeButton} /></IconButton>
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel sx={{pt:'15px', pb:'15px'}}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form>
        {activeStep === 0 && (   
        <Grid container spacing={2}>
          <Grid item xs={12} >
                 <TextField
                    sx={useStyles.textField}
                    label="Business Name"
                    name="businessName"
                    id="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    error={!!errors.businessName}
                    helperText={errors.businessName}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <WorkIcon />
                        </InputAdornment>
                      ),
                    }}
                /> 
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
           <TextField
                    sx={useStyles.textField}
            label="Address 1"
            name="address1"
            id="address1"
            value={formData.address1}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            required
            error={!!errors.address1}
            helperText={errors.address1}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ContactMailIcon />
                </InputAdornment>
              ),
            }}
          />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
           <TextField
                    sx={useStyles.textField}
            label="Address 2"
            name="address2"
            id="address2"
            value={formData.address2}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            required
            error={!!errors.address2}
            helperText={errors.address2}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ContactMailIcon />
                </InputAdornment>
              ),
            }}
          /> 
          </Grid>   
          <Grid item xs={12} sm={12} md={6} lg={6} >
           <TextField
            sx={useStyles.textField}
            label="City"
            name="city"
            id="city"
            value={formData.city}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            required
            error={!!errors.city}
            helperText={errors.city}
          />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
             <TextField
                    sx={useStyles.textField}
                label="State / Province"
                name="state"
                id="state"
                value={formData.state}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                error={!!errors.state}
                helperText={errors.state}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
             <TextField
                    sx={useStyles.textField}
                label="ZIP"
                name="zip"
                id="zip"
                value={formData.zip}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                error={!!errors.zip}
                helperText={errors.zip}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
          <Select
                label="Country"
                value={countryCode}
                name="country"
                id="country"
                onChange={handleCountryCodeChange}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                error={!!errors.country}
                helperText={errors.country}
              >
                <MenuItem value="">
                  <em>Select Country</em>
                </MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.code} {country.countryCode}
                  </MenuItem>
                ))}
              </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
             <TextField
                sx={useStyles.textField}
                label="Email"
                value={formData.email}
                name="email"
                id="email"
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                error={!!errors.email}
                helperText={errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
             <TextField
                    sx={useStyles.textField}
                label="Phone"
                name="mobileNumber"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                error={!!errors.mobileNumber}
                helperText={errors.mobileNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
            />
          </Grid>
               
          
          <Grid item xs={12}>
            <Typography variant="body1" sx={{padding:'10px 0px'}}><b>I Buy parts from:</b></Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3}  lg={3} xl={3} >
              <FormControlLabel
                control={<Checkbox />}
                value = "advance_auto_parts"
                label="Advance Auto Parts"
                name="advance_auto_parts"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}  lg={3} xl={3} >
              <FormControlLabel
                control={<Checkbox />}
                value="Carquest"
                label="Carquest"
                name="Carquest"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}  lg={3} xl={3} >
              <FormControlLabel
                control={<Checkbox />}
                value="worldpac"
                label="Worldpac"
                name="worldpac"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}  lg={3} xl={3} >
              <FormControlLabel
                control={<Checkbox />}
                value="autopart_international"
                label="Autopart International"
                name="autopart_international"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                 <TextField
                    sx={useStyles.textField}
                    label="AAP Account Number"
                    name="aap_accountno"
                    value={formData.aap_accountno}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    error={!!errors.aap_accountno}
                    helperText={errors.aap_accountno}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PinIcon  />
                        </InputAdornment>
                      ),
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12}  >
                <Typography variant="body1" sx={{padding:'10px 0px', borderBottom:'1px solid #dddddd'}} underline="always">
                    <b>Contact Information:</b>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
                 <TextField
                    sx={useStyles.textField}
                    label="First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    error={!!errors.first_name}
                    helperText={errors.first_name}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
                 <TextField
                    sx={useStyles.textField}
                    label="Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    error={!!errors.last_name}
                    helperText={errors.last_name}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12} >
                 <TextField
                    sx={useStyles.textField}
                    label="Job Title"
                    name="job_title"
                    value={formData.job_title}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    error={!!errors.job_title}
                    helperText={errors.job_title}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <WorkIcon />
                        </InputAdornment>
                      ),
                    }}
                /> 
          </Grid>   
           <Grid item xs={12} >
                <Typography variant="body1" sx={{padding:'10px 0px', borderBottom:'1px solid #dddddd'}} underline="always">
                    <b>Subscription Plans:</b>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
                 <TextField
                    sx={useStyles.textField}
                    label="Number of locations"
                    name="max_location"
                    value={formData.max_location}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    error={!!errors.max_location}
                    helperText={errors.max_location}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonPinCircleIcon />
                        </InputAdornment>
                      ),
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6}></Grid>    
            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
            <Box 
            sx={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    paddingLeft:'10px'
                  }}
                >
                <FormControlLabel error={!!errors.plan_id} helperText={errors.plan_id} checked={subscription === "monthly"} onChange={handleSubscriptionChange} name="plan_id" value="monthly" control={<Radio />} label="Monthly Subscription  $59 ( $59 Per Location )" />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
                <Box 
                  sx={{
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          paddingLeft:'10px'
                        }}
                      >
                  <FormControlLabel error={!!errors.plan_id} helperText={errors.plan_id} checked={subscription === "yearly"} onChange={handleSubscriptionChange} name="plan_id" value="yearly" control={<Radio />} label="Yearly Subscription  $590 ( $590 Per Location )" />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid> 
            <Grid item xs={6} sm={6} md={10} lg={10} xl={10}>
               <TextField
                    sx={useStyles.textField}
                label="Promo Code"
                name='promo_code'
                value={formData.promo_code}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                error={!!errors.promo_code}
                helperText={errors.promo_code}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2} xl={2} sx={{paddingLeft:'0px !important'}}>
              <Button color="primary" className='subscribe-applyCode'>Apply Promo Code</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12}>
              <Countrycodeintl name="mobile_otp" value={formData.mobile_otp} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="body1">
                To prevent fraud, we need to send a One-Time PIN to verify that you're not a bot using our website to test stolen credit cards.
              </Typography>
            </Grid>            
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12} >
              <FormControlLabel name="iaccept" value={formData.iaccept} control={<Checkbox />} label="Terms and Conditions" />          
            </Grid>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12} >
              <GoogleCaptcha />
            </Grid>
            </Grid>  
        )}
          {activeStep === 1 && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" sx={{padding:'10px 0px', borderBottom:'1px solid #dddddd'}} underline="always">
                      <b>Billing Information:</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}  lg={12} xl={12} >
                  <FormControlLabel name='same_as_primary_contact' value={formData.same_as_primary_contact} control={<Checkbox />} label="Same as primary contact" />          
                </Grid>
              
              <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
                   <TextField
                    sx={useStyles.textField}
                      label="First Name"
                      name='bill_first_name'
                      value={formData.bill_first_name}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
                   <TextField
                    sx={useStyles.textField}
                      label="Last Name"
                      name='bill_last_name'
                      value={formData.bill_last_name}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} >
                 <TextField
                  sx={useStyles.textField}
                  label="Address 1"
                  name='bill_addres1'
                  value={formData.bill_addres1}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ContactMailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                 <TextField
                    sx={useStyles.textField}
                  label="Address 2"
                  name='bill_addres2'
                  value={formData.bill_addres2}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ContactMailIcon />
                      </InputAdornment>
                    ),
                  }}
                /> 
            </Grid>   
            <Grid item xs={12} sm={12} md={6} lg={6} >
             <TextField
                    sx={useStyles.textField}
              label="City"
              name="bill_city"
              value={mobileNumber}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
              size="small"
              required
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
               <TextField
                    sx={useStyles.textField}
                  label="State / Province"
                  name="bill_state"
                  value={formData.bill_state}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
               <TextField
                    sx={useStyles.textField}
                  label="ZIP"
                  name="bill_zip"
                  value={formData.bill_zip}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  required
              />
            </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
          <Select
                label="Country"
                name="bill_country"
                value={billCountryCode}
                onChange={handleBillCountryCodeChange}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
              >
                <MenuItem value="">
                  <em>Select Country</em>
                </MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.code} {country.countryCode}
                  </MenuItem>
                ))}
              </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
             <TextField
                    sx={useStyles.textField}
                label="Email"
                name="bill_email"
                value={billEmail}
                onChange={handleBillEmailChange}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
             <TextField
                    sx={useStyles.textField}
                label="Phone"
                name='bill_phone'
                value={formData.bill_phone}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="body1" sx={{padding:'10px 0px', borderBottom:'1px solid #dddddd'}} underline="always">
                <b>Bill Details:</b>
            </Typography>
          </Grid>
          
          <Grid item container sx={{backgroundColor:'#dddddd', marginTop:'20px', marginLeft: '15px'}}>
            <Grid item xs={12} sm={12} md={4}  lg={4} xl={4} sx={useStyles.gridItemBillDets}>
              <Typography variant="body1">
                  <b>Subscription Type :</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} sx={useStyles.gridItemBillDets}>
              <Typography variant="body1">
                  <b>Yearly Subscription</b>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4}  lg={4} xl={4} sx={useStyles.gridItemBillDets}>
              <Typography variant="body1">
                  <b>Total Pay :</b>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} sx={useStyles.gridItemBillDets}>
              <Typography variant="body1">
                  <b>$590</b>
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="body1" sx={{padding:'10px 0px', borderBottom:'1px solid #dddddd'}} underline="always">
                <b>Card Information:</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>      
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={visaIcon} alt='logo' className='visaIcon' />
            </Box>        
          </Grid>

          <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
                   <TextField
                    sx={useStyles.textField}
                      label="Card Number"
                      name='card_number'
                      value={formData.card_number}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PinIcon />
                          </InputAdornment>
                        ),
                      }}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
                   <TextField
                    sx={useStyles.textField}
                      label="Name On Card"
                      value="name_on_card"
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                  />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                  <Select
                    name='card_expiry_month'
                    value={isCardExpiryMonth}
                    onChange={handleCardExpiryMonth}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Month</em>
                    </MenuItem>
                    {cardExpiration.map((expirationList) => (
                      <MenuItem key={expirationList.monthValue} value={expirationList.monthValue}>
                        {expirationList.monthValue} {expirationList.monthData}
                      </MenuItem>
                    ))}
                  </Select>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Select
                    name='card_expiry_year'
                    value={isCardExpiryYear}
                    onChange={handleCardExpiryYear}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Year</em>
                    </MenuItem>
                    {expirationYear.map((item) => (
                      <MenuItem key={item.Year} value={item.Year}>
                        {item.Year}
                      </MenuItem>
                    ))}
                  </Select>
            </Grid>

            <Grid item xs={12} sm={12} md={6}  lg={6} xl={6} >
                   <TextField
                    sx={useStyles.textField}
                      label="CVV"
                      name='card_cvv'
                      value={formData.card_cvv}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                  />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant='body2'>
                <b>We've sent a One-Time PIN to <span onClick={handleOneTimePinOpenModal} className='OneTimePin'>Edit Number</span>:</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12}>
                   <TextField
                    sx={useStyles.textField}
                      label="Please Enter One-Time PIN"
                      name='card_mobile_otp'
                      value={formData.card_mobile_otp}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PinIcon />
                          </InputAdornment>
                        ),
                      }}
                  />
            </Grid>
            <Grid item xs={6} sm={6} md={12}  lg={12} xl={12} sx={{ ...useStyles.gridItem, paddingBottom:'20px'}}>
              <GoogleCaptcha />
            </Grid>

                
          </Grid>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions className='DialogActionsMobileSubscribe'>
        {activeStep === 0 && (
          <Button className='btn' onClick={handleNext} color="primary" variant="contained" size="small">
            Next
          </Button>
        )}
        {activeStep === 1 && (
          <Button className='btn' onClick={handleBack} variant="outlined" size="small">
            Back
          </Button>
        )}
        {activeStep === 1 ? (
          <Button className='btn' onClick={handleReset} variant="outlined" size="small">
            Reset
          </Button>
        ) : (
          <Button className='btn' onClick={onClose} variant="outlined" size="small">
            Close
          </Button>
        )}
        {activeStep === 1 && (
          <Button className='btn' onClick={handleConfirm} color="primary" variant="contained" size="small">
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>

    <ModalOneTimePin
    open={isOneTimePinModalOpen}
    onClose={handleOneTimePinCloseModal}
    title="Mobile Number For One-Time PIN"
    content="This is the content of the Modal."
    />

    </>
  );
};

export default Subscribe;
