import React from "react";
import { Container, Grid, Box, Typography } from '@mui/material';

const Aboutus = () => {
    return (
        <Container style={{ padding: '16px', position:'a',paddingTop:'80px',paddingBottom:'80px'}}>
            <Grid container>
                    <Box sx={{ mt:1, backgroundColor: '#e9e9e999', padding: '20px' , borderRadius: '5px', width:'100%',height:'100%'}}>
                      <Typography variant="h5" gutterBottom>About Us</Typography>
                      <Box sx={{border:'1px solid grey'}}></Box>
                    
                      <Box sx={{mt:3}}></Box>
                    <Grid container spacing={2}>
                     <Grid item xs={12} sm={12} md={6} lg={6}> 
                       <Typography variant="h4" gutterBottom>Why MotoVisuals?</Typography>

                       </Grid>
                       
                   </Grid>
                   </Box>
            </Grid>
        </Container>
    );
};

export default Aboutus;
