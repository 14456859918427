import React from 'react';
import Button from "@mui/material/Button";

const CustomButton = ({ label,sx,onClick,color }) => {
  return (
    <Button variant="contained" color={color} sx={{ backgroundColor: "rgb(220, 0, 55)", ...sx }} onClick={onClick}>
      {label}
    </Button>
  );
};

export default CustomButton;
