import React, { useState } from 'react';
import { Grid, Box, Typography, TextField, FormLabel, useMediaQuery, createTheme, FormControl, Modal} from "@mui/material";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputAdornment } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import '../Settings/Settings.css';
import CustomButton from '../../../../Components/Button';
import { Row } from 'antd';

const PersonalSettings = () => {

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        tb: 768,
        md: 1024,
        lg: 1280,
        xl: 1920,
      },
    },
  });


  const handleLoginOpenModal = () => {
    setLoginModalOpen(true);
  };

  const handleLoginCloseModal = () => {
    setLoginModalOpen(false);
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor:
        theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
      border: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      fontFamily: [].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));
  
  let setGridPosition = {};
  const setGridPos ={
    paddingLeft: "20%", 
    paddingRight: "20%", 
    paddingTop: "10%",
    width:"100%",
    maxWidth:"100%",
  }

  const setGridPosMobile ={
    paddingLeft: "5%", 
    paddingTop: "20%", 
    paddingRight: "5%",
    paddingBottom: "30%",
    width:'100%',
  }

  const setGridPosTablet ={
    paddingLeft: "10%", 
    paddingTop: "25%", 
    paddingRight: "10%",
    paddingBottom: "30%",
    width:'100%',
  }

  if(isMobile){
    setGridPosition = setGridPosMobile ;
  }
  else if(isTablet){
    setGridPosition = setGridPosTablet;
  }
  else{
    setGridPosition = setGridPos;
  }

  return (

    <Grid
    sx={setGridPosition}>
      <Grid container>
        <Box className='userdetails'>
          <Typography variant="h5" gutterBottom>User Details</Typography>
          <Box sx={{ border: "1px solid grey" }}></Box>
          <Box sx={{ mt: 2 }}></Box>

          <Grid container spacing={ isMobile ? 0 : 3}>
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <FormLabel sx={{ marginBottom: "5px" }}>First Name:<br/>
                <FormControl  sx={{ width:'100%'}} variant="standard">
                  <BootstrapInput  defaultValue="MotoVisuals" id="bootstrap-input" /></FormControl>
              </FormLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormLabel sx={{ marginBottom: "5px" }}>Last Name:<br/>
                <FormControl sx={{ width:'100%'}} variant="standard">
                  <BootstrapInput defaultValue="Test User" id="bootstrap-input" /></FormControl>
              </FormLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormLabel sx={{ marginBottom: "5px" }}>Phone Number:<br/>
                <FormControl sx={{ width:'100%'}} variant="standard">
                  <BootstrapInput
                    defaultValue="9503503906" id="bootstrap-input" />
                </FormControl>
              </FormLabel>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
            <CustomButton sx={{ marginTop: "30px", marginRight: "5px" }} label="Save" />  
            <CustomButton sx={{ marginTop: "30px", marginRight: "5px" }} label="Cancel" />  
            <CustomButton sx={{ marginTop: "30px", marginRight: "5px" }} onClick={handleLoginOpenModal} label="Change Login Detauls" />
          </Grid>
          <Modal open={isLoginModalOpen} onClose={handleLoginCloseModal}>
            <Grid className={ isMobile ? 'settingsModalMobile' : isTablet ? 'settingsModalTablet' : 'settingsModal'}>
              <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                <IconButton
                  sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                  onClick={handleLoginCloseModal}><CloseIcon />
                </IconButton>
                <Typography variant="h5" gutterBottom>Username & Password</Typography>
              </Grid>
              <Grid container spacing={ isMobile ? 0 : 2} sx={{ padding: '15px' }}>


                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField label="Current UserName" placeholder="Enter your Current UserName" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton sx={{ marginLeft: '30%' }}><AccountCircle /></IconButton>
                        </InputAdornment>
                      ),
                    }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField label="New UserName" placeholder="Enter your New UserName" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton sx={{ marginLeft: '30%' }}><AccountCircle /></IconButton>
                        </InputAdornment>),
                    }} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                  <CustomButton sx={{ marginTop: "30px", marginRight: "5px" }} label="Save UserName" />    
                  <CustomButton color="primary" sx={{ marginTop: "30px", marginRight: "5px" }} label="Cancel" />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField label="Current Password" placeholder="Enter your Current Password" margin="normal" type={showPassword ? 'text' : 'password'} fullWidth variant="outlined" className="textfield" size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>),
                    }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField label="New Password" placeholder="Enter your New Password" margin="normal" type={showPassword ? 'text' : 'password'} fullWidth variant="outlined" className="textfield" size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>),
                    }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField label="Re-Enter Password" placeholder="Re-Enter your New Password" margin="normal" type={showPassword ? 'text' : 'password'} fullWidth variant="outlined" className="textfield" size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>),
                    }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                <CustomButton sx={{ marginRight: "5px" }} label="Save UserName" />    
                  <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Cancel" />
                </Grid>
              </Grid>
            </Grid>
          </Modal>
        </Box>

      </Grid>

    </Grid>

  );
};

export default PersonalSettings;