import React from "react";
import { Container, Grid, Box, Typography, TextField, FormControlLabel, Button, Checkbox, Link } from '@mui/material';
import login_logo from '../../Assets/Images/logo_home.png';
import { useNavigate } from 'react-router-dom';


const styles = {

    ContainerBar: {
        padding:'100px 50px 100px 50px'
    }
  };

const LoginPage = () => {
  const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get("email"),
            password: data.get("password"),
        });
    };

     const LoginValidateClick = () => {
      navigate('/Dashboard')
    }
   

    return(
        <Container component="main" maxWidth="sm">
      <Box
        sx={{
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
            <Box sx={{ alignItems: 'center' }}>
                <img src={login_logo} alt='logo' className='loginLogo'  />
            </Box>
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} sx={{textAlign:'right'}}>
              <Link href="#" variant="body2">Free Trail</Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={LoginValidateClick}  
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs={12} sx={{textAlign:'center'}}>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    );
};

export default LoginPage;
