import React from 'react';
import {Grid,Box,Typography, useMediaQuery, createTheme} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import '../Settings/Settings.css';
import { styled } from '@mui/material/styles';
import HeightIcon from '@mui/icons-material/Height';
import DeleteIcon from '@mui/icons-material/Delete';

const ManageFavourites = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center', 
  }));

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));
  
  let setGridPosition = {};
  const setGridPos ={
    padding: "2%",
    marginLeft:'30%', 
    paddingTop: '7%',
    width: '40%'
  }

  const setGridPosMobile ={
    padding: "2%",
    paddingLeft:'5%', 
    paddingTop: '15%',
    paddingRight: '5%',
    paddingBottom: '30%', 
    width: '100%'
  }

  const setGridPosTablet ={
    padding: "2%",
    paddingLeft:'10%',
    marginLeft:'10%', 
    paddingTop: '15%',
    paddingRight: '5%',
    paddingBottom: '30%', 
    width: '80%'
  }
  if(isMobile ){
    setGridPosition = setGridPosMobile ;
  }
  else if(isTablet){
    setGridPosition = setGridPosTablet;
  }
  else{
    setGridPosition = setGridPos;
  }

  return (
    <Grid sx={setGridPosition}>
      <Grid container>
        <Box className='Apidetails'>
          <Typography sx={{ textAlign:'center!important'}} variant="h5" gutterBottom>Manage Favorites</Typography>
          <Box sx={{ border: "1px solid grey" }}></Box>
          <Box sx={{ mt: 2 }}></Box>

          <Grid container spacing={3} sx={{ height:'650px',overflowX:'auto',marginTop:'0px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ width: '100%' }}>
                <Stack spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Brake Fluid Replacement<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Change Oil and Filter<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>On Car Brake Lathe<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ManageFavourites;