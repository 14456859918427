import React, { useState } from 'react';
import './Sidebarmobile.css';
import { createTheme, useMediaQuery} from '@mui/material';
import { useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import MailIcon from '@mui/icons-material/Mail';
import LoopIcon from '@mui/icons-material/Loop';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportIcon from '@mui/icons-material/Report';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HelpIcon from '@mui/icons-material/Help';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VideocamIcon from '@mui/icons-material/Videocam';
import MenuIcon from "@mui/icons-material/Menu"; 
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Email from './SidebarTools/Email';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '.././RTL.css';
import { Grid } from 'antd';

const Leftsidebarmobile = () => {

  const navigate = useNavigate();
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [OpenBtn, setOpenBtn] = useState(true);
  const [OpenDownBtn, setDownOpenBtn] = useState(true);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  
  const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1920
        },
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigatetodashbord = () => {
    navigate('/Dashboard')
  };

  const navigatetomyvideo = () => {
    navigate('/Myvideo')
  };

  const navigatetoloopvideo = () => {
    window.open('https://sandbox.vehiclevisuals.com/#!/play_looped_animations', '_blank');
  };

  const handleEmailClick = () => {
    setEmailModalOpen(true);
  };
  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
  };

  const handleSubMenuItemClick = (subMenuItem) => {
    if (subMenuItem === 'Personal Settings') {
      navigate('/PersonalSettings');
    }
    if (subMenuItem === 'API Key') {
      navigate('/api_key_settings');
    }
    if (subMenuItem === 'Manage Favorites') {
      navigate('/favourites_settings');
    }
    if (subMenuItem === 'Email Address Settings') {
      navigate('/email_address_settings');
    }
    if (subMenuItem === 'Email Content Settings') {
      navigate('/email_content_settings');
    }
    if (subMenuItem === 'Email Banner Settings') {
      navigate('/email_banner_settings');
    }
    if (subMenuItem === 'Animation Loop Settings') {
      navigate('/looped_animations_settings');
    }
    if (subMenuItem === 'Manage User') {
      navigate('/manage_user');
    }
    if (subMenuItem === 'Manage Locations') {
      navigate('/manage_locations');
    }
    if (subMenuItem === 'Manage Location Classes') {
      navigate('/manage_region');
    }
    if (subMenuItem === 'Usage Report') {
      navigate('/usage_report');
    }
    if (subMenuItem === 'Email Sent Report') {
      navigate('/email_sent_report');
    }
    if (subMenuItem === 'Generate Link Report') {
      navigate('/generate_link_report');
    }
    if (subMenuItem === 'Embedded Animations Report') {
      navigate('/embedded_animations_report');
    }
    if (subMenuItem === 'Text Messages') {
      navigate('/sms_messages');
    }
    if (subMenuItem === 'VLE instructions') {
      navigate('/vle_instructions');
    }
    if (subMenuItem === 'Phone Numbers') {
      navigate('/phone_numbers');
    }
    if (subMenuItem === 'Upload User Videos') {
      navigate('/user_custom_videos');
    }
  };

  const toggleSidebar = () => {
    setOpen(!open);
    setOpenBtn(!OpenBtn);
  }

  const toggleSubMenu = (event, subMenuItem) => {
    const listItem = event.currentTarget;
    const subMenu = listItem.querySelector('.sub-menu');
    const arrow = listItem.querySelector('.arrow');

    const subMenus = document.querySelectorAll('.sub-menu');
    subMenus.forEach((menu) => {
      if (menu !== subMenu) {
        menu.style.display = 'none';
      }
    });

    if (arrow) {
      arrow.classList.toggle('rotated');
    }

    if (subMenu) {
      const subMenuStyle = getComputedStyle(subMenu);
      const subMenuDisplay = subMenuStyle.getPropertyValue('display');
      const newDisplay = subMenuDisplay === 'none' ? 'block' : 'none';
      subMenu.style.display = newDisplay;

      if (subMenuItem === openSubMenu) {
        setOpenSubMenu(null);
      } else {
        setOpenSubMenu(subMenuItem);
      }
    }
  };
  
  return (

      <div className={`sidebar ${open ? 'open' : 'close'}`} >
        {/* <div className={`sidebar-toggle-arrow ${OpenBtn ? 'toggle-arrow-btn' : ''}`} onClick={toggleSidebar}>
          <i aria-hidden="true"><ChevronRightIcon sx={{ float: 'right', cursor: 'pointer' }} /></i>
        </div> */}
        <ul className="nav-links">
          <li onClick={toggleSubMenu}>
            <span onClick={navigatetodashbord}>
              <i className='bx bx-grid-alt'><HomeIcon /></i>
              <span className="link_name">Dashboard</span>
            </span>
            <ul className="sub-menu blank">
              <li><span className="link_name">Home</span></li>
            </ul>
          </li>
          <li onClick={toggleSubMenu}>
            <span>
              <i className='bx bx-grid-alt'><MailIcon /></i>
              <span onClick={handleEmailClick} className="link_name">E-Mail</span>
            </span>
            <ul className="sub-menu blank">
              <li onClick={handleEmailClick}><span className="link_name">E-Mail</span></li>
            </ul>
          </li>
          <li onClick={toggleSubMenu}>
            <div className="iocn-link" onClick={navigatetoloopvideo}>
              <span>
                <i className='bx bx-collection'><LoopIcon /></i>
                <span className="link_name">Loop</span>
              </span>
              <i className='bx bxs-chevron-down arrow'></i>
            </div>
            <ul className="sub-menu">
              <li><span className="link_name" >Loop</span></li>
            </ul>
          </li>
          <li onClick={toggleSubMenu}>
            <span onClick={navigatetomyvideo}>
              <i className='bx bx-history'><VideocamIcon /></i>
              <span className="link_name">MyVideos</span>
            </span>
            <ul className="sub-menu blank">
              <li ><span className="link_name" >MyVideos</span></li>
            </ul>
          </li>
          <li onClick={toggleSubMenu}>
            <span>
              <i className='bx bx-pie-chart-alt-2'><SettingsIcon /></i>
              <span className="link_name">Settings</span>
              <i className={`bx bxs-chevron-down arrow ${OpenDownBtn ? 'bx bxs-chevron-down arrow btn' : ''}`} ><KeyboardArrowDownIcon /></i>
            </span>
            <ul className="sub-menu">
              <li><span className="link_name" >Settings</span></li>
              <li onClick={() => handleSubMenuItemClick('Personal Settings')}><span>Personal Settings</span></li>
              <li onClick={() => handleSubMenuItemClick('API Key')}><span>API Key</span></li>
              <li onClick={() => handleSubMenuItemClick('Manage Favorites')}><span>Manage Favorites</span></li>
              <li onClick={() => handleSubMenuItemClick('Email Address Settings')}><span>Email Address Settings</span></li>
              <li onClick={() => handleSubMenuItemClick('Email Content Settings')}><span>Email Content Settings</span></li>
              <li onClick={() => handleSubMenuItemClick('Email Banner Settings')}><span>Email Banner Settings</span></li>
              <li onClick={() => handleSubMenuItemClick('Animation Loop Settings')}><span>Animation Loop Settings</span></li>
              <li onClick={() => handleSubMenuItemClick('Download System Icons')}><span>Download System Icons</span></li>
              <li onClick={() => handleSubMenuItemClick('IP Auto Login Settings')}><span>IP Auto Login Settings</span></li>
              <li onClick={() => handleSubMenuItemClick('VLE instructions')}><span>VLE instructions</span></li>
              <li onClick={() => handleSubMenuItemClick('Phone Numbers')}><span>Phone Numbers</span></li>
              <li onClick={() => handleSubMenuItemClick('Phone Number Usage')}><span>Phone Number Usage</span></li>
              <li onClick={() => handleSubMenuItemClick('Embedded Menu')}><span>Embedded Menu</span></li>
              <li onClick={() => handleSubMenuItemClick('Upload User Videos')}><span>Upload User Videos</span></li>
            </ul>
          </li>
          <li onClick={toggleSubMenu}>
            <span>
              <i className='bx bx-line-chart'><ReportIcon /></i>
              <span className="link_name">Reports</span>
              <i class='bx bxs-chevron-down arrow' ><KeyboardArrowDownIcon /></i>
            </span>
            <ul className="sub-menu">
              <li><span className="link_name" >Reports</span></li>
              <li onClick={() => handleSubMenuItemClick('Usage Report')}><span>Usage Report</span></li>
              <li onClick={() => handleSubMenuItemClick('Email Sent Report')}><span>Email Sent Report</span></li>
              <li onClick={() => handleSubMenuItemClick('Generate Link Report')}><span>Generate Link Report</span></li>
              <li onClick={() => handleSubMenuItemClick('Embedded Animations Report')}><span>Embedded Animations Report</span></li>
            </ul>
          </li>

          <li onClick={toggleSubMenu}>
            <div className="iocn-link">
              <span>
                <i className='bx bx-plug'><ManageAccountsIcon /></i>
                <span className="link_name">Employee Accounts</span>
              </span>
              <i class='bx bxs-chevron-down arrow' ><KeyboardArrowDownIcon /></i>
            </div>
            <ul className="sub-menu">
              <li><span className="link_name">Employee Accounts</span></li>
              <li onClick={() => handleSubMenuItemClick('Manage User')}><span>Manage User</span></li>
              <li onClick={() => handleSubMenuItemClick('Manage Locations')}><span>Manage Locations</span></li>
              <li onClick={() => handleSubMenuItemClick('Manage Location Classes')}><span>Manage Location Classes</span></li>
            </ul>
          </li>
          <li>
            <span>
              <i className='bx bx-compass'><HelpIcon /></i>
              <span className="link_name">Help</span>
            </span>
            <ul className="sub-menu blank">
              <li><span className="link_name" >Help</span></li>
            </ul>
          </li>

          <li>
            <span>
              <i className='bx bx-cog'><PowerSettingsNewIcon /></i>
              <span className="link_name">Log Out</span>
            </span>
            <ul className="sub-menu blank">
              <li><span className="link_name" >Log Out</span></li>
            </ul>
          </li>
          <li>
          </li>
        </ul>
        {isEmailModalOpen && (
          <Email
            open={isEmailModalOpen}
            onClose={handleCloseEmailModal} />
        )}
      </div>

  );
};

export default Leftsidebarmobile;
