import React, { useState } from 'react';
import { Container, Box, useMediaQuery,Grid, createTheme} from '@mui/material';
import login_logo from '../../Assets/Images/logo_home.png';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Leftsidebarmobile from './Leftsidebarmobile';

import './Sidebarmobile.css';
import { useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import MailIcon from '@mui/icons-material/Mail';
import LoopIcon from '@mui/icons-material/Loop';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportIcon from '@mui/icons-material/Report';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HelpIcon from '@mui/icons-material/Help';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VideocamIcon from '@mui/icons-material/Videocam';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Email from './SidebarTools/Email';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '.././RTL.css';

import '../RTL.css';

const Header_Dashboard = () => {

  const navigate = useNavigate();
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [OpenBtn, setOpenBtn] = useState(true);
  const [OpenDownBtn, setDownOpenBtn] = useState(true);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  
  const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            tb: 968,
            md: 1024,
            lg: 1280,
            xl: 1920
        },
    },
  });

  const navigatetodashbord = () => {
    navigate('/Dashboard')
  };

  const navigatetomyvideo = () => {
    navigate('/Myvideo')
  };

  const navigatetoloopvideo = () => {
    window.open('https://sandbox.vehiclevisuals.com/#!/play_looped_animations', '_blank');
  };

  const handleEmailClick = () => {
    setEmailModalOpen(true);
  };
  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
  };

  const handleSubMenuItemClick = (subMenuItem) => {
    if (subMenuItem === 'Personal Settings') {
      navigate('/PersonalSettings');
    }
    if (subMenuItem === 'API Key') {
      navigate('/api_key_settings');
    }
    if (subMenuItem === 'Manage Favorites') {
      navigate('/favourites_settings');
    }
    if (subMenuItem === 'Email Settings') {
      navigate('/email_tabs');
    }
    if (subMenuItem === 'Animation Loop Settings') {
      navigate('/looped_animations_settings');
    }
    if (subMenuItem === 'Manage User') {
      navigate('/manage_user');
    }
    if (subMenuItem === 'Manage Locations') {
      navigate('/manage_locations');
    }
    if (subMenuItem === 'Manage Location Classes') {
      navigate('/manage_region');
    }
    if (subMenuItem === 'Usage Report') {
      navigate('/usage_report');
    }
    if (subMenuItem === 'Email Sent Report') {
      navigate('/email_sent_report');
    }
    if (subMenuItem === 'Generate Link Report') {
      navigate('/generate_link_report');
    }
    if (subMenuItem === 'Embedded Animations Report') {
      navigate('/embedded_animations_report');
    }
    if (subMenuItem === 'Text Messages') {
      navigate('/sms_messages');
    }
    if (subMenuItem === 'VLE instructions') {
      navigate('/vle_instructions');
    }
    if (subMenuItem === 'Phone Numbers') {
      navigate('/phone_numbers');
    }
    if (subMenuItem === 'Upload User Videos') {
      navigate('/user_custom_videos');
    }
  };

  const toggleSidebar = () => {
    setOpen(!open);
    setOpenBtn(!OpenBtn);
  }

  const toggleSubMenu = (event, subMenuItem) => {
    const listItem = event.currentTarget;
    const subMenu = listItem.querySelector('.sub-menu');
    const arrow = listItem.querySelector('.arrow');

    const subMenus = document.querySelectorAll('.sub-menu');
    subMenus.forEach((menu) => {
      if (menu !== subMenu) {
        menu.style.display = 'none';
      }
    });

    if (arrow) {
      arrow.classList.toggle('rotated');
    }

    if (subMenu) {
      const subMenuStyle = getComputedStyle(subMenu);
      const subMenuDisplay = subMenuStyle.getPropertyValue('display');
      const newDisplay = subMenuDisplay === 'none' ? 'block' : 'none';
      subMenu.style.display = newDisplay;

      if (subMenuItem === openSubMenu) {
        setOpenSubMenu(null);
      } else {
        setOpenSubMenu(subMenuItem);
      }
    }
  };
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      }));
      
      const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }));
      
      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
          padding: theme.spacing(1, 1, 1, 0),
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create('width'),
          width: '100%',
          [theme.breakpoints.up('md')]: {
            borderRadius:'25px'
          },
        },
      }));
      
    const isbetweenThanmdlg = useMediaQuery(theme.breakpoints.between('md','lg'));
    const isbetweenThanmdlg2 = useMediaQuery(theme.breakpoints.between('lg','lg2'));
    const tabsStyles = {
        padding: isbetweenThanmdlg ? '10px 30px' : isbetweenThanmdlg2 ? '10px 50px' : '10px 80px',
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm','tb'));
    const [isDelivery, setIsDelivery] = useState(true);
    const handleSwitchClick = () => {
      setIsDelivery(!isDelivery);
    };

    const handleMenuClick = () => {
      console.log("shgvfjhsdfjhb");
      return(<Leftsidebarmobile/>);
    };

    return (
        <>
        <header className='navbarheader'>
          <Container style={{ maxWidth: 'inherit', overflow: 'hidden', display: 'flex', flexDirection: 'column' ,backgroundColor:'rgb(228, 228, 228)' }}>
              <Box sx={{ display: 'flex',  textAlign:'center'}}>
                <Grid sx={{ height:'100%', borderRadius:'25px'}}>
                  <div className='login_logo' style={{  maxWidth: '300px', height:'100%' }}>
                      <img src={login_logo} alt="login_logo" style={{ width: "100%", height: "auto" }} />
                  </div>
                  </Grid>
                  {isMobile || isTablet ? (null): (
                  <Box sx={{ position:'relative', width:'45%', justifyContent:'center', borderRadius: '25px', height: 'auto', marginLeft: '30px', padding: '2px'}}>
                        <Search className='searchbar' sx={{ height:'100%', borderRadius:'25px' }} >
                            <SearchIconWrapper className='searchicon'><SearchIcon/></SearchIconWrapper>
                            <StyledInputBase className='inputbase' sx={{ width:'100%', position:'relative', height:'100%' }}
                              placeholder="Search for Animation"
                              inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                  </Box>
                  )}
                  {/* {isMobile || isTablet ? (null): (
                  <Box sx={{ height:'auto', padding:'6px' }}>
                      <div className="switch" onClick={handleSwitchClick}>
                          <span className={isDelivery ? 'on' : ''}>Interactive</span>
                          <span className={!isDelivery ? 'on' : ''}>Narrated</span>
                        <div className={`switch_bg ${isDelivery ? 'right' : ''}`}></div>
                      </div>
                  </Box>
                  )}
                  {isMobile || isTablet ? <Box sx={{ display:'flex', flexDirection:'column',zIndex:'20',height:'100%' }}> 
                  <Button onClick={toggleSidebar} sx={{ position:'fixed',  top:0, right:0, bottom: 'auto', minWidth:'30px', color:'black'}}>
                  <MenuIcon fontSize='large' sx={{}} />
                  </Button>
                   </Box>:(null)} */}
            </Box>
            {/* Sidebar for mobile start */}
           
        </Container>
       </header>
       
       </>
    );
};

export default Header_Dashboard;
