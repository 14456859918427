import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Grid, Typography, createTheme, useMediaQuery, Button, Modal, TextField, InputAdornment } from "@mui/material";
import '../Employee_Accounts/Emp_Accounts.css';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import MmsIcon from '@mui/icons-material/Mms';
import CheckIcon from '@mui/icons-material/Check';
import CustomButton from '../../../../Components/Button';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
 
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (

        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(email) {
    return { email };
}


const rows = [
    { id: 1, Type: 'mobile', price: '$1 AUD',  Phone: 7826095535 },
    { id: 2, Type: 'mobile', price: '$1 IND',  Phone: 7826095542 },
    { id: 3, Type: 'mobile', price: '$1 AUD',  Phone: 7826095545 },
    { id: 4, Type: 'mobile', price: '$1 IND',  Phone: 1678260955 },
    { id: 5, Type: 'mobile', price: '$1 AUD',  Phone: 8878260955 },
    { id: 6, Type: 'mobile', price: '$1 IND',  Phone: 1782609550 },
    { id: 7, Type: 'mobile', price: '$1 AUD',  Phone: 4782609554 },
    { id: 8, Type: 'mobile', price: '$1 IND',  Phone: 3782609556 },
    { id: 9, Type: 'mobile', price: '$1 AUD',  Phone: 6782609555 },
];


export default function PhoneNumbers() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isBuyNowModalOpen, setBuyNowModalOpen] = useState(false);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleBuyNowOpenModal = () => {
        setBuyNowModalOpen(true);
    };

    const handleCloseModal = () => {
        setBuyNowModalOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1920,
          },
        },
      });

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm','lg'))
    let setGridPosition = {};
    const setGridPos ={
        padding: "2%",
        paddingLeft:'10%', 
        paddingTop: '5%',
        paddingRight: '10%', 
        width: '100%'
    }

    const setGridPosMobile ={
        paddingTop:'20%',
        paddingLeft:'5%',
        paddingright:'5%',
        paddingBottom: '30%',
        width:'100%', 
    }

    if(isMobile || isTablet){
        setGridPosition = setGridPosMobile ;
    }
    else{
        setGridPosition = setGridPos;
    }

    let buyNumberSize = {};
    const buyNumberSizeMobile ={
        
        width:'100%',
        height:'80%',
        overflow:'auto'
    }
    if(isMobile){
        buyNumberSize = buyNumberSizeMobile ;
    }
    else{
        buyNumberSize = buyNumberSize;
    }


    return (
        <Grid sx={setGridPosition}>
            <Grid>
                <Grid container>
                    <Box className='manageuser'>
                        <Typography variant="h5" gutterBottom>Buy Number</Typography>
                        <Box sx={{ border: "1px solid grey" }}></Box>
                        <Box sx={{ mt: 2 }}></Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Country"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>Australia</MenuItem>
                                    <MenuItem value={20}>India</MenuItem>
                                    <MenuItem value={30}>United States</MenuItem>
                                    <MenuItem value={40}>France</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField label="Number" placeholder="Enter your Number" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton></IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                        </Grid>

                        <Grid sx={{ float: 'right' }}>
                            <CustomButton color="primary" label="Search" />
                        </Grid>
                    </Box>
                </Grid>
                <Box sx={{ mt: 2 }}></Box>
            </Grid>

            <Grid className='manageuser'>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="custom pagination table">
                        <TableBody>
                            <TableRow >
                                <TableCell ><b>#</b></TableCell>
                                <TableCell><b>Phone Number</b></TableCell>
                                <TableCell ><b>type</b></TableCell>
                                <TableCell align="center"><b>voice</b></TableCell>
                                <TableCell align="center"><b>Text Message</b></TableCell>
                                <TableCell><b>mms</b></TableCell>
                                <TableCell><b>fax</b></TableCell>
                                <TableCell><b>price</b></TableCell>
                                <TableCell><b>purchase</b></TableCell>
                            </TableRow>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row) => (
                                <TableRow key={rows}>
                                    <TableCell component="th" scope="row"> {row.id}</TableCell>
                                    <TableCell component="th" scope="row"> {row.Phone}</TableCell>
                                    <TableCell component="th" scope="row"> {row.Type}</TableCell>
                                    <TableCell align="center" component="th" scope="row"> <CheckIcon sx={{ color: '#3c763d',textAlign:'center',fontSize: '31.5px'}}/></TableCell>
                                    <TableCell align="center" component="th" scope="row"> <CheckIcon sx={{ color: '#3c763d',fontSize: '31.5px' }}/></TableCell>
                                    <TableCell component="th" scope="row"><CheckIcon sx={{ color: '#3c763d',fontSize: '31.5px' }}/></TableCell>
                                    <TableCell component="th" scope="row"><CloseIcon sx={{ color: 'red',fontSize: '31.5px' }}/></TableCell>
                                    <TableCell component="th" scope="row"> {row.price}</TableCell>
                                    <TableCell ><CustomButton color="primary" onClick={handleBuyNowOpenModal} label="Buy Now" /></TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                

                            <TableRow >
                                <TablePagination sx={{ padding: '0px'}}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]} className='pagination' colSpan={5}

                                    count={rows.length} rowsPerPage={rowsPerPage} page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
            <Modal open={isBuyNowModalOpen} onClose={handleCloseModal}>
                <Grid className={ isMobile? "BuyNowModalMobile" : isTablet? "BuyNowModalTablet" : "BuyNowModal"}>
                    <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                            onClick={handleCloseModal}><CloseIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>Buy This Number</Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ width:'100%', overflow:'auto', padding: '25px', paddingright: '30px', overflowWrap: 'break-word' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>+61483944343</Typography>
                    </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}><IconButton><CallIcon /></IconButton>voice</Typography>
                            <Typography variant="h6" gutterBottom sx={{ paddingLeft: '8%' }}>This number can receive incoming calls and make outgoing calls.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}><IconButton><EmailIcon /></IconButton>Text Message</Typography>
                            <Typography variant="h6" gutterBottom sx={{ paddingLeft: '8%' }}>This number can send and receive text messages to and from mobile numbers.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}><IconButton><MmsIcon /></IconButton>mms</Typography>
                            <Typography variant="h6" gutterBottom sx={{ paddingLeft: '8%' }}>this_number_can_send_and_receive_multi_media_messages_to_and_from_mobile_numbers.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={{ mt: 3, borderTop: '1px solid #d5d5d5', display: 'flex' }}></Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CustomButton color="primary" sx={{ float: 'right' }} label="Buy This Number" />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    );
}