import React, { useState } from 'react';
import { Grid, Typography, Menu, TextField, createTheme, useMediaQuery  } from "@mui/material";
import '../Employee_Accounts/Emp_Accounts.css';
import MenuItem from '@mui/material/MenuItem';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import { mkConfig, generateCsv } from "export-to-csv";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import IconButton from '@mui/material/IconButton';
import CustomButton from '../../../../Components/Button';
const csvConfig = mkConfig({ useKeysAsHeaders: true });

const columns = [
    {
        accessorKey: 'id',
        header: 'ID',
        size: 40,
    },
    {
        accessorKey: 'firstName',
        header: 'First Name',
        size: 150,
    },
    {
        accessorKey: 'lastName',
        header: 'Last Name',
        size: 150,
    },
    {
        accessorKey: 'company',
        header: 'Company',
        size: 150,
    },
    {
        accessorKey: 'city',
        header: 'City',
        size: 150,
    },
    {
        accessorKey: 'country',
        header: 'Country',
        size: 150,
    },
];

const data = [
    { id: 1, firstName: 'kb', lastName: 'Snow', company: 'testing1', city: 'Jon', country: 'India' },
    { id: 2, firstName: 'Amravti', lastName: 'Lannister', company: 'testing2', city: 'Cersei', country: 'Australia' },
    { id: 3, firstName: 'Pune', lastName: 'Lannister', company: 'testing3', city: 'Jaime', country: 'South Africa' },
    { id: 4, firstName: 'Mk1', lastName: 'Stark', company: 'testing4', city: 'Arya', country: 'Japan' },
    { id: 5, firstName: 'Mk15', lastName: 'Targaryen', company: 'testing5', city: 'Daenerys', country: 'Russia' },
    { id: 6, firstName: 'Mk16', lastName: 'Melisandre',company: 'testing5', city: null, country: 'Russia'},
    { id: 7, firstName: 'Mk17', lastName: 'Clifford',company: 'testing5', city: 'Ferrara', country: 'Russia' },
    { id: 8, firstName: 'Mk18', lastName: 'Frances',company: 'testing5', city: 'Rossini', country: 'Russia' },
    // { id: 9, firstName: 'Mk19', lastName: 'Roxie', city: 'Harvey', Phone: 6782609555 },
];

const Options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
};
// const csvExporter = new ExportToCsv(options);
const UsageReports = () => {
    const csvData = data.map((item) => ({
        'ID': item.id,
        'First Name': item.firstName,
        'Last Name': item.lastName,
        'Company': item.company,
        'City': item.city,
        'Country': item.country,
    }));

    const [exportMenuAnchor, setExportMenuAnchor] = useState(null);
    const [selectedExportOption, setSelectedExportOption] = useState('');
    // const [selectedRows, setSelectedRows] = useState([]);

    const handleExportIconClick = (event) => {
      setExportMenuAnchor(event.currentTarget);
    };

    const handleExportOptionClick = (option) => {
        setSelectedExportOption(option);
        setExportMenuAnchor(null);
        switch (selectedExportOption) {
            case 'Export All Data':
                handleExportCsv();
                break;
            case 'Export Page Rows':
                handleExportPageRows();
                break;
            case 'Export Selected Rows':
                handleExportSelectedRows();
                break;
            default:
                break;
        }
      };
    
      const handleCloseExportMenu = () => {
        setExportMenuAnchor(null);
      };
    
      const handleExportCsv = () => {
        const csvContent = generateCsv(csvConfig)(data);
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'exported_data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };
    


    const handleExportPageRows = () => {
        const pageRows = data.slice(pagination.pageIndex * pagination.pageSize, (pagination.pageIndex + 1) * pagination.pageSize);
        const pageCsvContent = generateCsv(csvConfig)(pageRows);
        const pageBlob = new Blob([pageCsvContent], { type: 'text/csv' });
        const pageUrl = window.URL.createObjectURL(pageBlob);
        const a = document.createElement('a');
        a.href = pageUrl;
        a.download = 'exported_page_rows.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(pageUrl);
        document.body.removeChild(a);
    };

    const handleExportSelectedRows = () => {
        const pageRows = data.slice(pagination.pageIndex * pagination.pageSize, (pagination.pageIndex + 1) * pagination.pageSize);
        const selectedCsvContent = generateCsv(csvConfig)(pageRows);
        const selectedBlob = new Blob([selectedCsvContent], { type: 'text/csv' });
        const selectedUrl = window.URL.createObjectURL(selectedBlob);
        const a = document.createElement('a');
        a.href = selectedUrl;
        a.download = 'exported_selected_rows.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(selectedUrl);
        document.body.removeChild(a);
    };
    

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5, 
      });
    
      const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1920,
          },
        },
    });
    
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));
    const isAboveTablet = useMediaQuery(theme.breakpoints.between("md","lg"));
    
    let setGridPosition = {};
    const setGridPos ={
      paddingLeft: "5%", 
      paddingTop: "5%", 
      paddingRight: "5%",
      paddingBottom: "20%",
      width: "100%",
    }
    
    const setGridPosMobile ={
      paddingTop: "55px", 
      paddingRight: "5%",
      paddingLeft: "5%",
      paddingBottom: "30%",
      width:'100%',
    }
    
    const setGridPosTablet ={
      paddingTop: "10%", 
      paddingRight: "5%",
      paddingLeft: "5%",
      paddingBottom: "30%",
      width:'100%',
    }
    
    const setGridPosAboveTablet ={
      paddingTop: "10%", 
      paddingRight: "10%",
      paddingLeft: "10%",
      paddingBottom: "30%",
      width:'100%',
    }
    
    if(isMobile){
      setGridPosition = setGridPosMobile ;
    }
    else if(isTablet){
      setGridPosition = setGridPosTablet;
    }
    else if(isAboveTablet){
      setGridPosition = setGridPosAboveTablet; 
    }
    else{
      setGridPosition = setGridPos;
    }


    return (
        <Grid sx={setGridPosition}>
            <Grid>
                <Grid container>
                    <Box className='manageuser'>
                        <Typography variant="h5" gutterBottom>Generate Link Reports</Typography>
                        <Box sx={{ border: "1px solid grey" }}></Box>
                        <Box sx={{ mt: 2 }}></Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Business Entity"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Location Class"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Location"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="User"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid sx={{ float: 'right' }}>
                            <CustomButton color="primary" label="Generate Graph" />
                            <CustomButton color="primary" sx={{ marginLeft: "2px" }} label="Generate Excel" />
                        </Grid>
                    </Box>
                </Grid>
                <Box sx={{ mt: 2 }}></Box>
            </Grid>

            <Grid className='manageuser'>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    onPaginationChange={setPagination}
                     state={{ pagination }}
                     enableFullScreenToggle={false}
                     muiTablePaginationProps={{
                        rowsPerPageOptions: [5, 10,15],
                        showFirstButton: false,
                        showLastButton: false,
                      }}
                        enableRowSelection
                    positionToolbarAlertBanner="bottom"
                    // onRowSelectionChange={(selectedRowIds) => {
                    //     const selectedRowsData = data.filter((row) => selectedRowIds.includes(row.id));
                    //     setSelectedRows(selectedRowsData);
                    // }}
                    renderTopToolbarCustomActions={({ table }) => (
                        <Grid
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                        <IconButton edge="start" color="inherit" aria-label="menu"
                             sx={{ marginLeft: 'auto' }} onClick={handleExportIconClick}>
                        <ImportExportIcon sx={{ color: 'black', paddingLeft: '0px' }} />
                        </IconButton>
                        <Menu anchorEl={exportMenuAnchor} open={Boolean(exportMenuAnchor)} onClose={handleCloseExportMenu}>
                            <MenuItem onClick={() => handleExportOptionClick('Export All Data')}>Export All Data</MenuItem>
                            <MenuItem onClick={() => handleExportOptionClick('Export Page Rows')} disabled={table.getRowModel().rows.length === 0}>Export Page Rows</MenuItem>
                            <MenuItem onClick={() => handleExportOptionClick('Export Selected Rows')}>Export Selected Rows</MenuItem>
                        </Menu>
                        </Grid>
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default UsageReports;
