import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Grid, Typography, Checkbox, FormControlLabel, Button, Modal, createTheme, useMediaQuery, TextField, InputAdornment } from "@mui/material";
import '../Settings/Settings.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../../../Components/Button';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    

    return (

        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(email) {
    return { email };
}

const rows = [
    createData('tcp1.akilan@gmail.com'),
    createData('test2@gmail.com'),
    createData('test3@gmail.com'),
    createData('test4@gmail.com'),
    createData('test5@gmail.com'),
    createData('test6@gmail.com'),
    createData('test7@gmail.com'),
    createData('test8@gmail.com'),
    createData('tcp9.com'),
    createData('test10@gmail.com'),
    createData('tcp11.akilan@gmail.com'),
    createData('test12@gmail.com'),
    createData('test13@gmail.com'),
    createData('test14@gmail.com'),
    createData('test15@gmail.com'),
    createData('test16@gmail.com'),
    createData('test17@gmail.com'),
    createData('test18@gmail.com'),
    createData('tcp19.com'),
    createData('test20@gmail.com'),
    createData('test@exert21.com'),
    createData('tcp22.akilan@gmail.com'),
    createData('test23@gmail.com'),
];

export default function EmailAddress() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isEmailModalOpen, setEmailModalOpen] = useState(false);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleEmailOpenModal = () => {
        setEmailModalOpen(true);
    };

    const handleEmailCloseModal = () => {
        setEmailModalOpen(false);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [selectedEmail, setSelectedEmail] = React.useState("");
    const handleRadioChange = (event, email) => {
        setSelectedEmail(email);
    };

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1920,
          },
        },
      });

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));


    return (
        <Grid sx={{  width: '100%', justifyContent: 'center' }}>
            <Grid>
                <Grid container>
                    <Box className='emaildetails'>
                        <Grid sx={{ float: 'left', textAlign: 'left' }}>
                            <Typography gutterBottom='h6' >Use this option if the emails you send are not being received by your customers</Typography>
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Set as Address as:videos@motovisuals.com" />
                        </Grid>
                        <Grid>
                            <CustomButton color="primary" sx={{ float: 'right', marginTop: '50px' }} onClick={handleEmailOpenModal} label="Add Email" /></Grid>
                    </Box>
                </Grid>
                <Box sx={{ mt: 2 }}></Box>
            </Grid >
            <Modal open={isEmailModalOpen} onClose={handleEmailCloseModal}>
                <Grid className= { isMobile ? 'EmailModalMobile' : isTablet ? 'EmailModalTablet' : 'EmailModal'}>
                    <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                            onClick={handleEmailCloseModal}><CloseIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>Add Email</Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ padding: '15px' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField label="E-Mail" placeholder="Enter your E-Mail" margin="normal" fullWidth variant="outlined" className="textfield" size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ marginLeft: '30%' }}><EmailIcon /></IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid>
                                <CustomButton color="primary" sx={{ float: 'right' }} onClick={handleEmailOpenModal} label="Save" />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            <Box className='emaildetailsbanner'>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="custom pagination table">
                        <TableBody>
                            <TableRow>
                                <TableCell align="left"><b>Default</b></TableCell>
                                <TableCell ><b>E-Mail</b></TableCell>
                                <TableCell align="right"><b>Action</b></TableCell>
                            </TableRow>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row) => (
                                <TableRow key={row.email}>
                                    <TableCell align="left">
                                        <RadioGroup aria-label="select-email" name="select-email"
                                            value={selectedEmail === row.email ? row.email : ""} onChange={(event) => handleRadioChange(event, row.email)} >
                                            <FormControlLabel value={row.email} control={<Radio />} /></RadioGroup>
                                    </TableCell>
                                    <TableCell component="th" scope="row"> {row.email}</TableCell>
                                    <TableCell align="right"><ModeEditIcon /><DeleteIcon /></TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]} colSpan={3}
                                    count={rows.length} rowsPerPage={rowsPerPage} page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
        </Grid>
    );
}