import React, { useState } from 'react';
import { Grid, Typography, Checkbox, useMediaQuery, createTheme, FormControlLabel, TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from "export-to-csv";
import CustomButton from '../../../../Components/Button';

import './reports.css';


const columns = [
    {
        accessorKey: 'id',
        header: 'ID',
        size: 40,
    },
    {
        accessorKey: 'firstName',
        header: 'First Name',
        size: 150,
    },
    {
        accessorKey: 'lastName',
        header: 'Last Name',
        size: 150,
    },
    {
        accessorKey: 'company',
        header: 'Company',
        size: 150,
    },
    {
        accessorKey: 'city',
        header: 'City',
        size: 150,
    },
    {
        accessorKey: 'country',
        header: 'Country',
        size: 150,
    },
];

const data = [
    { id: 1, firstName: 'kb', lastName: 'Snow', company: 'testing1', city: 'Jon', country: 'India' },
    { id: 2, firstName: 'Amravti', lastName: 'Lannister', company: 'testing2', city: 'Cersei', country: 'Australia' },
    { id: 3, firstName: 'Pune', lastName: 'Lannister', company: 'testing3', city: 'Jaime', country: 'South Africa' },
    { id: 4, firstName: 'Mk1', lastName: 'Stark', company: 'testing4', city: 'Arya', country: 'Japan' },
    { id: 5, firstName: 'Mk15', lastName: 'Targaryen', company: 'testing5', city: 'Daenerys', country: 'Russia' },
    { id: 6, firstName: 'Mk16', lastName: 'Melisandre',company: 'testing5', city: null, country: 'Russia'},
    { id: 7, firstName: 'Mk17', lastName: 'Clifford',company: 'testing5', city: 'Ferrara', country: 'Russia' },
    { id: 8, firstName: 'Mk18', lastName: 'Frances',company: 'testing5', city: 'Rossini', country: 'Russia' },
];

const Options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
};
const UsageReports = () => {
    const csvData = data.map((item) => ({
        'ID': item.id,
        'First Name': item.firstName,
        'Last Name': item.lastName,
        'Company': item.company,
        'City': item.city,
        'Country': item.country,
    }));

    const handleExportCsv = () => {
        const csvConfig = mkConfig({ useKeysAsHeaders: true });
        const csvContent = generateCsv(csvConfig)(data);
        const blob = new Blob([csvContent], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'exported_data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };
    const handleExportRows = (rows) => {
        const csvConfig = mkConfig({ useKeysAsHeaders: true });

        const selectedRowsData = rows.map((row) => row.original);
        const csvContent = generateCsv(csvConfig)(selectedRowsData);

        const blob = new Blob([csvContent], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'exported_rows.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5, 
      });

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1920,
          },
        },
    });
    
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));
    const isAboveTablet = useMediaQuery(theme.breakpoints.between("md","lg"));
    
    let setGridPosition = {};
    const setGridPos ={
      paddingLeft: "5%", 
      paddingTop: "5%", 
      paddingRight: "5%",
      paddingBottom: "20%",
      width: "100%",
    }
    
    const setGridPosMobile ={
      paddingTop: "55px", 
      paddingRight: "5%",
      paddingLeft: "5%",
      paddingBottom: "30%",
      width:'100%',
    }
    
    const setGridPosTablet ={
      paddingTop: "10%", 
      paddingRight: "5%",
      paddingLeft: "5%",
      paddingBottom: "30%",
      width:'100%',
    }
    
    const setGridPosAboveTablet ={
      paddingTop: "10%", 
      paddingRight: "10%",
      paddingLeft: "10%",
      paddingBottom: "30%",
      width:'100%',
    }
    
    if(isMobile){
      setGridPosition = setGridPosMobile ;
    }
    else if(isTablet){
      setGridPosition = setGridPosTablet;
    }
    else if(isAboveTablet){
      setGridPosition = setGridPosAboveTablet; 
    }
    else{
      setGridPosition = setGridPos;
    }
    return (
        <Grid sx={setGridPosition}>
            <Grid>
                <Grid container>
                    <Box className={isMobile ? 'UsageReportContainerMobile' : 'UsageReportContainer'}>
                        <Typography variant="h5" gutterBottom>Usage Report</Typography>
                        <Box sx={{ border: "1px solid grey" }}></Box>
                        <Box sx={{ mt: 2 }}></Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Business Entity"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Location Class"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Location"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="User"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Date Filter"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField fullWidth margin="normal" variant="outlined" size="small" label="Report Type"
                                    select
                                    SelectProps={{
                                        labelId: 'demo-simple-select-label',
                                        id: 'demo-simple-select',
                                    }}>
                                    <MenuItem value={10}>test@exert.com</MenuItem>
                                    <MenuItem value={20}>moto@mail.com</MenuItem>
                                    <MenuItem value={30}>service@mail.com</MenuItem>
                                    <MenuItem value={40}>visuals@mail.com</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <FormControlLabel control={<Checkbox color="primary" />} sx={{ marginTop: '15px', whiteSpace:'nowrap' }} label="Include Zero Usage Results" />
                            </Grid>
                        </Grid>
                        <Grid sx={{ float: 'right' }}>
                        <CustomButton sx={{ margin:'2px' }} color="primary" label="Generate Report" />
                        <CustomButton sx={{ margin:'2px'  }} color="primary" label="Generate Usage Raw Data" />
                        </Grid>
                    </Box>
                </Grid>
                <Box sx={{ mt: 2 }}></Box>
            </Grid>

            <Grid xs={12} sm={12} md={12} lg={12} className={isMobile ? 'UsageReportContainerTableMobile':'UsageReportContainerTable'}>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                     state={{ pagination }} //pass the pagination state to the table
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [5, 10,15],
                        showFirstButton: false,
                        showLastButton: false,
                      }}
                        enableRowSelection
                    positionToolbarAlertBanner="bottom"
                    renderTopToolbarCustomActions={({ table }) => (
                        
                        <Grid
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', maxWidth:'70%!important' ,flexWrap: 'wrap'}}>

                            <Button className={ isMobile ? 'ExportButtonMobile' : isTablet ? 'ExportButtonTablet' :'ExportButton' } color="primary" onClick={handleExportCsv} startIcon={<FileDownloadIcon />} variant="contained">Export All Data</Button>


                            <Button
                                className={ isMobile ? 'ExportButtonMobile' : isTablet ? 'ExportButtonTablet' : 'ExportButton' }
                                disabled={table.getRowModel().rows.length === 0}
                                onClick={() => handleExportRows(table.getRowModel().rows)}
                                startIcon={<FileDownloadIcon />}
                                variant="contained">Export Page Rows</Button>


                            <Button
                                className={ isMobile ? 'ExportButtonMobile' : isTablet ? 'ExportButtonTablet' : 'ExportButton' }
                                disabled={
                                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                }
                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                startIcon={<FileDownloadIcon />}
                                variant="contained">Export Selected Rows</Button>

                        </Grid>
                    )}
                />
            </Grid>
        </Grid>

    );
};

export default UsageReports;

