import React, {useState} from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, TextField, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { Visibility, VisibilityOff } from '@mui/icons-material';
const useStyles = {
    textField: {
        margin: 'normal',
        marginTop: '0px',
    },
    dialogTitle: {
        background: 'rgb(228, 228, 228)',
        color: '#000'
    },
    languageText:{
        fontSize:'14px',
        textAlign:'center'
    }
};

const ActivateAccount = ({ open, onClose, title, onConfirm }) => {

  const [Username,setUsername] = React.useState('');
  const [Password,setPassword] = React.useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeUsername = (event) =>{
    setUsername(event.target.value);
  }  

  const handleChangePassword = (event) =>{
    setPassword(event.target.value);
  } 

  const handleConfirm = () => {
    console.log('test');
    // const formData = {
    //   mobileNumber: ''
    // };
    // onConfirm(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className="custom-dialog-title" sx={useStyles.dialogTitle}>
        <b>{title}</b>
        <IconButton><CloseIcon onClick={onClose} /></IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
        <Grid container spacing={2} sx={{pt:'20px'}}>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12}>
                <TextField
                    sx={useStyles.textField}
                    label="UserName"
                    value={Username}
                    onChange={handleChangeUsername}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <PersonIcon />
                        </InputAdornment>
                    ),
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}  lg={12} xl={12}>
                <TextField
                    sx={useStyles.textField}
                    label="Password" required placeholder="Enter your password" margin="normal" type={showPassword ? 'text' : 'password'} variant="outlined" size="small" fullWidth
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    ),
                }}/>
            </Grid>
        </Grid>
        </form>
      </DialogContent>
      <DialogActions className='custom-dialog-footer'>
          <Button className='btn' onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button className='btn' onClick={handleConfirm} color="primary" variant="contained">
            Proceed
          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivateAccount;
