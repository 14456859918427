import React, { useState } from 'react';
import { Grid, Box, Typography, TextField, FormLabel, useMediaQuery, createTheme, FormControl, Modal } from "@mui/material";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import '../Settings/Settings.css';
import MenuItem from '@mui/material/MenuItem';
import CustomButton from '../../../../Components/Button';

const PersonalSettings = () => {

    const [isSMSOpenModal, setSMSOpenModal] = useState(false);
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1024,
                lg: 1280,
                xl: 1920,
            },
        },
    });

    const handleSMSOpenModal = () => {
        setSMSOpenModal(true);
    };

    const handleLoginCloseModal = () => {
        setSMSOpenModal(false);
    };

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "& .MuiInputBase-input": {
            borderRadius: 4,
            position: "relative",
            backgroundColor:
                theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
            border: "1px solid",
            borderColor:
                theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
            fontSize: 16,
            width: "400px",
            padding: "10px 12px",
            transition: theme.transitions.create([
                "border-color",
                "background-color",
                "box-shadow",
            ]),
            fontFamily: [].join(","),
            "&:focus": {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Grid
            sx={{ paddingLeft: "20%", paddingTop: "8%", paddingRight: "20%" }}>
            <Grid container>
                <Box className='userdetails'>
                    <Typography variant="h5" gutterBottom>Manage SMS Messages</Typography>
                    <Box sx={{ border: "1px solid grey" }}></Box>
                    <Box sx={{ mt: 2 }}></Box>

                    <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} display="flex">
                            <FormLabel sx={{ flex: "1", marginTop: '2%' }}>Prepaid Messages:</FormLabel>
                            <FormControl variant="standard">
                                <BootstrapInput defaultValue="0" id="bootstrap-input" size="small" />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                        <CustomButton color="primary" sx={{ marginTop: "30px", marginRight: "5px" }} onClick={handleSMSOpenModal} label="Buy SMS Pack" />
                    </Grid>
                    <Modal open={isSMSOpenModal} onClose={handleLoginCloseModal}>
                        <Grid className='smsModal'>
                            <Grid sx={{ backgroundColor: 'rgb(228, 228, 228)', padding: '10px 10px', borderRadius: '5px' }}>
                                <IconButton
                                    sx={{ position: 'absolute', top: '10px', right: '10px', color: 'grey' }}
                                    onClick={handleLoginCloseModal}><CloseIcon />
                                </IconButton>
                                <Typography variant="h5" gutterBottom>Order SMS Messages</Typography>
                            </Grid>
                            <Grid container spacing={2} sx={{ padding: '15px' }}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField fullWidth margin="normal" variant="outlined" size="small" label="SMS Bundle"
                                        select
                                        SelectProps={{
                                            labelId: 'demo-simple-select-label',
                                            id: 'demo-simple-select',
                                        }}>
                                        <MenuItem value={10}>100</MenuItem>
                                        <MenuItem value={20}>250</MenuItem>
                                        <MenuItem value={30}>500</MenuItem>
                                        <MenuItem value={40}>550</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h5" gutterBottom>Total :$ 1 INR</Typography>

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                                    <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Pay With Paypal" />
                                    <CustomButton color="primary" sx={{ marginRight: "5px" }} label="Reset" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Modal>
                </Box>
            </Grid>
        </Grid>

    );
};

export default PersonalSettings;
