import React from 'react';
import {Grid,Box,Typography, Checkbox, createTheme, useMediaQuery } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import '../Settings/Settings.css';
import { styled } from '@mui/material/styles';
import HeightIcon from '@mui/icons-material/Height';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VideocamIcon from '@mui/icons-material/Videocam';


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Collapse from '@mui/material/Collapse';

import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';


const Apikey = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center', 
  }));

  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const [seconds, setSeconds] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setSeconds(event.target.value);
  };

  const [open, setOpen] = React.useState(true);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
    if(open3){
    handleClick3();
    }
  };

  const handleClick2 = () => {
    setOpen2(!open2);
  };

  const handleClick3 = () => {
    setOpen3(!open3);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        tb: 768,
        md: 1024,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm","tb"));
  const isAboveTablet = useMediaQuery(theme.breakpoints.between("tb","md"));
  
  let buttonPadding = {};
  let setGridPosition = {};
  
  const setGridPos ={
    paddingLeft: "5%", 
    paddingTop: "5%", 
    paddingRight: "5%",
    paddingBottom: "20%",
    width: "100%",
  }
  
  const setGridPosMobile ={
    paddingTop: "55px", 
    paddingRight: "5%",
    paddingLeft: "5%",
    paddingBottom: "30%",
    width:'100%',
  }
  
  const setGridPosTablet ={
    paddingTop: "10%", 
    paddingRight: "5%",
    paddingLeft: "5%",
    paddingBottom: "30%",
    width:'100%',
  }
  
  const setGridPosAboveTablet ={
    paddingTop: "10%", 
    paddingRight: "10%",
    paddingLeft: "10%",
    paddingBottom: "30%",
    width:'100%',
  }
  
  if(isMobile){
    setGridPosition = setGridPosMobile ;
  }
  else if(isTablet){
    setGridPosition = setGridPosTablet;
  }
  else if(isAboveTablet){
    setGridPosition = setGridPosAboveTablet; 
  }
  else{
    setGridPosition = setGridPos;
  }

  return (
    
    <Grid
      sx={setGridPosition}>
      <Grid container>
        <Box className='Apidetails'>
          <Typography sx={{ textAlign:'center'}} variant="h5" gutterBottom>My Reel Settings</Typography>
          <Box sx={{ border: "1px solid grey" }}></Box>
          <Box sx={{ mt: 2 }}></Box>
          <Grid container spacing={3} sx={{ height:'650px',overflowX:'auto',marginTop:'0px' }} justifyContent="space-around" alignItems="flex-start">
     
          <Grid item xs={12} sm={12} md={12} lg={4}  sx={{  }}>
          <List
            sx={{ width: '100%' }}
            component="nav"

          >
            <Stack spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">
            <Box sx={{boxShadow: '2px 2px 12px 2px lightgrey',width: '100%',overflow:'auto',marginRight:'10px' }}>
            <Item sx={{  fontWeight:'bold', fontSize:"18px", WebkitJustifyContent:'initial' }} onClick={handleClick}><ListItemIcon>
              {open ? <KeyboardArrowDown fontSize='large' /> : <ChevronRight fontSize='large' />}
              </ListItemIcon> <div style={{ textAlign:'center!important' }} >Subtitled Videos</div> </Item>
            <Collapse sx={{ maxHeight:'auto' }} in={open} timeout="auto" unmountOnExit>
              <Item sx={{  marginLeft: '5%', fontWeight:'bold', fontSize:"18px",  WebkitJustifyContent:'initial', marginTop:'5px'  }} onClick={handleClick3}><ListItemIcon>
              {open3 ? <KeyboardArrowDown fontSize='large' /> : <ChevronRight fontSize='large' />}
              </ListItemIcon ><div style={{ textAlign:'center!important' }}  >Looped Videos</div> </Item>
            </Collapse>
            
            <Collapse sx={{ marginLeft: '10%'}} in={open3} timeout="auto" unmountOnExit>
            <Box  sx={{ width: '100%',height:'350px',overflow:'auto', paddingBottom:'5px',marginRight:'10px', marginTop:'5px'  }  }>
              

                <Stack sx={{ paddingBottom:'5px', marginTop:'5px'  }} spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Brake Fluid Replacement<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Change Oil and Filter<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>On Car Brake Lathe<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                </Stack>
              </Box>
            </Collapse>
            </Box>
            <Item sx={{  fontWeight:'bold', fontSize:"18px", WebkitJustifyContent:'initial', marginRight:'10px'  }} onClick={handleClick2}><ListItemIcon>
              {open2 ? <KeyboardArrowDown fontSize='large' /> : <ChevronRight fontSize='large' />}
              </ListItemIcon ><div style={{ textAlign:'center!important' }} >Narrated Videos </div></Item>
            <Collapse sx={{ marginLeft: '5%' }} in={open2} timeout="auto" unmountOnExit>
            <Box  sx={{ width: '100%',height:'350px',overflow:'auto' }  }>
            <Stack sx={{ paddingBottom:'5px',marginRight:'10px'}} spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">
            <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Brake Fluid Replacement<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Change Oil and Filter<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>On Car Brake Lathe<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"></IconButton></Item>
                  <Item sx={{ WebkitJustifyContent:'Left', whiteSpace:'nowrap' }}> <Checkbox sx={{ marginLeft:'5px' }} edge="start" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': "demo checkbox" }}/><IconButton aria-label="Height"><PlayArrowIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"></IconButton></Item>
                </Stack>
              </Box>
            </Collapse>
            </Stack>
          </List>

          </Grid>
    


          <Grid item xs={12} sm={12} md={12} lg={4} sx={{ }}>
          <Typography sx={{ textAlign:'center'}} variant="h5" gutterBottom>My Customised Loop </Typography>
              <Box sx={{ border: "0.5px solid grey" }}></Box>
              <Box  sx={{ width: '100%',height:'600px',overflow:'auto' }  }>
              
              <Box sx={{ mt: 2 }}></Box>
                <Stack spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Brake Fluid Replacement<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Change Oil and Filter<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>On Car Brake Lathe<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Plug-in Hybrid Electric Vehicle<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Tire Aging<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Hybrid Vehicle Servicing<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Brake Master Cylinder Inspection<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                  <Item><IconButton aria-label="Height"><HeightIcon /></IconButton>Flat Tire<IconButton aria-label="Delete"><DeleteIcon /></IconButton></Item>
                </Stack>
              </Box>
            </Grid>
         
            <Grid item xs={12} sm={12} md={12} lg={4} sx={{  height:'650px'}} >
            {/* <FormControlLabel control={<Checkbox size='small' />} sx={{ marginTop: '5px' }} label="Play All Subtitled Videos" />
            <FormControlLabel control={<Checkbox size='small' />} sx={{ marginTop: '5px' }} label="Play All Narrated Videos" />
            <FormControlLabel control={<Checkbox size='small' />} sx={{ marginTop: '5px' }} label="Play Videos Fullscreen" /> */}
            
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {["Play All Subtitled Videos", "Play All Narrated Videos", "Play Videos Fullscreen"].map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem
                    key={value}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={handleToggle(value)}>
                      <ListItemIcon sx={{ justifyContent:'left'}}>
                        <Checkbox sx={{ paddingLeft:'14px' }}
                          edge="start"
                          checked={checked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${value}`} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
              <ListItem disablePadding>
                <ListItemButton role={undefined}>
                  <ListItemIcon sx={{ justifyContent:'left'}} >
                      <VideocamIcon fontSize='large' />
                  </ListItemIcon>
                  <ListItemText primary={"Upload Your Videos"} />
                </ListItemButton>    
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton role={undefined}> 
                  <ListItemIcon sx={{ justifyContent:'left'}}>
                    <CameraAltIcon fontSize='large'  /> 
                  </ListItemIcon>
                  <ListItemText primary={"Upload Your Image"} />
                </ListItemButton>  
              </ListItem>
                
              
                <ListItem >
                    <ListItemText primary={"Show Images for"} />
                    <FormControl disablePadding sx={{ minWidth: 70 }} size="small">

                      <Select
                        id="demo-simple-select"
                        defaultValue={20}
                        value={seconds}
                        onChange={handleChange}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                      </Select>
                    </FormControl>
                    <ListItemText primary={"Seconds"} />
                    
                </ListItem>
            </List>
            </Grid>
            </Grid>
         
        </Box>
        
      </Grid>
      
    </Grid>
    
  );
};

export default Apikey;